import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useQueryDocumentBlob from 'hooks/useQueryDocumentBlob';
import { getDocumentUrl } from 'services/documents';

const getImageUrl = ({ previewUrl, url }) => {
  if (previewUrl) return getDocumentUrl({ url: previewUrl });
  if (url) return getDocumentUrl({ url });
  return null;
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
`;

const Image = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;

const ContentImage = props => {
  const { src, alt } = props;

  return (
    <Wrapper>
      <Image src={src} alt={alt} />
    </Wrapper>
  );
};

/**
 * Crea un objectUrl a partir de un blob para visualizar la imagen.
 * Posteriormente se revierte el objectUrl para liberar la memoria.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 * @see https://alphahydrae.com/2021/02/how-to-display-an-image-protected-by-header-based-authentication/
 */
const ContentImageBlob = props => {
  const { blob, alt } = props;
  const [objectUrl, setObjectUrl] = useState();

  useEffect(() => {
    const url = URL.createObjectURL(blob);
    setObjectUrl(url);
    return () => URL.revokeObjectURL(url);
  }, [blob]);

  return <ContentImage src={objectUrl} alt={alt} />;
};

const ContentImageBlobQuery = props => {
  const { url, alt } = props;
  const { isIdle, isLoading, isError, data: blob } = useQueryDocumentBlob({ url });

  if (isIdle || isLoading) return null;
  if (isError) return null;

  return <ContentImageBlob blob={blob} alt={alt} />;
};

const DocumentThumbnailImage = props => {
  const { originalname, preview } = props;
  const url = getImageUrl(props);

  if (preview) {
    return <ContentImage src={preview} alt={originalname} />;
  }
  return <ContentImageBlobQuery url={url} alt={originalname} />;
};

DocumentThumbnailImage.propTypes = {
  _id: PropTypes.string,
  url: PropTypes.string,
  previewUrl: PropTypes.string,
  preview: PropTypes.string,
  originalname: PropTypes.string,
  size: PropTypes.number,
};

DocumentThumbnailImage.defaultProps = {};

export default DocumentThumbnailImage;
