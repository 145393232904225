import React from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import getDefaultValuesFromFields from './utils/getDefaultValuesFromFields';
import FormBase from './FormBase';

const Form = props => {
  const {
    title,
    fields,
    defaultValues,
    shouldUnregister,
    maxWidth,
    submitText,
    cancelText,
    fieldsStyles,
    onSubmit,
    onCancel,
  } = props;

  const defaultValuesMerged = getDefaultValuesFromFields({ fields, defaultValues });
  const methods = useForm({ defaultValues: defaultValuesMerged, shouldUnregister });

  return (
    <FormBase
      title={title}
      fields={fields}
      methods={methods}
      maxWidth={maxWidth}
      fieldsStyles={fieldsStyles}
      submitText={submitText}
      cancelText={cancelText}
      onSubmit={onSubmit}
      onCancel={onCancel}
    />
  );
};

Form.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })),
  defaultValues: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  maxWidth: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

Form.defaultProps = {
  shouldUnregister: true,
};

export default Form;
