import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import theme from 'utils/theme';
import { getReadableColor } from 'utils/colors';
import { replaceURLs } from 'utils/strings';
import { ButtonRaw } from '../Button';
import PersonaMessageReplyBox from './PersonaMessageReplyBox';
import PersonaMessageBoxDocument from './PersonaMessageBoxDocument';

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => (props.isMine ? 'row-reverse' : 'row')};
  padding: 5px 0;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 5px;
  padding: 6px;
  min-width: 30%;
  max-width: 350px;
  color: ${props => props.color};
  background: ${props => props.background};
  border-radius: 8px;
  word-break: break-word;
`;

const Username = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.colors.black};
`;

const Message = styled.div`
  margin-bottom: 5px;
  font-size: 16px;
  white-space: pre-wrap;
  a {
    color: inherit;
    font-weight: bold;
    &:hover  {
      text-decoration: underline;
    }
  }
`;

const MessageReply = styled.div`
  margin: 5px 0;
  color: ${props => props.color};
`;

const MessageDate = styled.div`
  font-size: 10px;
  font-style: italic;
  opacity: 0.5;
`;

const Button = styled(ButtonRaw)`
  font-size: 14px;
  margin-bottom: 8px;
  margin-top: 16px;
  text-decoration: underline;
  color: ${props => props.theme.colors.trout};

  &:hover {
    color: ${props => props.theme.colors.darkGray};
  }
`;

const PersonaMessageBox = props => {
  const {
    isMine,
    data,
    message,
    messageReply,
    personaId,
    dateTime,
    dateTimeFormat,
    dateTimeFromNow,
    doc,
    raw,
    baseUrl,
    mainColor,
    secondaryColor,
    usernameColor,
    onMessageDoubleClick,
    onFileClick,
  } = props;
  const [clickTime, setClickTime] = useState(0);

  const background = isMine ? mainColor : secondaryColor;
  const color = getReadableColor({ color: background });
  const parsedMessage = replaceURLs(message);
  const username = personaId === 'anonymous' ? 'Anónimo' : personaId;

  const handleDoubleClick = e => {
    if (!onMessageDoubleClick) return null;
    onMessageDoubleClick(data);
  };

  const onClick = e => {
    e.stopPropagation();

    if (new Date() - clickTime < 500) {
      handleDoubleClick();
    } else {
      setClickTime(new Date());
    }
  };

  const handleRawClick = () => {
    const newWindow = window.open();
    newWindow.document.write(raw);
  };

  return (
    <Wrapper isMine={isMine} onClick={onClick}>
      <Content color={color} background={background}>
        <Username color={usernameColor}>
          {isMine ? username : 'Representante de la empresa'}
        </Username>
        {messageReply && (
          <MessageReply color={color}>
            <PersonaMessageReplyBox data={messageReply} baseUrl={baseUrl} />
          </MessageReply>
        )}
        {message && <Message dangerouslySetInnerHTML={{ __html: parsedMessage }} />}
        {doc && <PersonaMessageBoxDocument doc={doc} onFileClick={onFileClick} />}
        {raw && <Button onClick={handleRawClick}>Ver original</Button>}
        <MessageDate>
          {dateTimeFromNow ? moment(dateTime).fromNow() : moment(dateTime).format(dateTimeFormat)}
        </MessageDate>
      </Content>
    </Wrapper>
  );
};

PersonaMessageBox.propTypes = {
  isMine: PropTypes.bool,
  message: PropTypes.string,
  messageReply: PropTypes.object,
  persona: PropTypes.shape({
    id: PropTypes.string,
    photo: PropTypes.string,
    profile: PropTypes.shape({ firstName: PropTypes.string, lastName: PropTypes.string }),
  }),
  dateTime: PropTypes.string,
  dateTimeFormat: PropTypes.string,
  dateTimeFromNow: PropTypes.bool,
  doc: PropTypes.shape({
    _id: PropTypes.string,
    contentType: PropTypes.string,
    originalname: PropTypes.string,
    size: PropTypes.number,
  }),
  mode: PropTypes.string,
  originUrl: PropTypes.string,
  onMessageDoubleClick: PropTypes.func,
};

PersonaMessageBox.defaultProps = {
  mode: 'bubbles',
  mainColor: theme.colors.warning,
  secondaryColor: '#f0f0f0',
  usernameColor: theme.colors.black,
  dateTimeFormat: 'HH:mm',
  dateTimeFromNow: false,
};

export default PersonaMessageBox;
