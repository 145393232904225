import React from 'react';
import PropTypes from 'prop-types';

const Dialog = ({
  title,
  message = '¿Está seguro de que desea realizar esta acción?',
  important,
  content,
  headerColor,
}) => {
  const styles = {
    wrapper: {
      boxSizing: 'border-box',
      display: 'flex',
      justifyContent: 'center',
      flex: 1,
      overflow: 'auto',
    },
    contentWrapper: {
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
    },
    content: {
      padding: '36px',
      flex: 1,
      display: 'flex',
      flexDirection: 'column',
      whiteSpace: 'pre-line',
    },
    message: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '1.125rem',
    },
    title: {
      textAlign: 'center',
      fontWeight: 600,
      paddingBottom: 24,
      fontSize: '1.5rem',
      color: headerColor,
    },
    customContent: {
      textAlign: 'center',
      flex: 1,
      display: 'flex',
      paddingTop: 18,
    },
    important: {
      fontStyle: 'italic',
    },
  };

  const parseMessage = () => {
    return <div style={styles.message}>{message}</div>;
  };

  const renderTitle = () => {
    if (title && title.includes(':important') && important) {
      return (
        <div style={styles.title}>
          {`${title.substr(0, title.indexOf(':important'))} ${important} ${title.substr(
            title.indexOf(':important') + 10,
          )}`}
        </div>
      );
    }

    return <div style={styles.title}>{title}</div>;
  };

  const renderContent = () => {
    return <div style={styles.customContent}>{content(this.props)}</div>;
  };

  return (
    <div style={styles.wrapper}>
      <div style={styles.contentWrapper}>
        <div style={styles.content}>
          {title && renderTitle()}
          {message && parseMessage()}
          {content && renderContent()}
        </div>
      </div>
    </div>
  );
};

Dialog.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.func,
};

export default Dialog;
