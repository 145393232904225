import React, { useState, useRef, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import styled, { css } from 'styled-components';
import Tippy from '@tippyjs/react';
import { ButtonRaw } from '../../Button';
import useOnClickOutside from '../../../hooks/useOnClickOutside';
import theme from '../../../utils/theme';

const getSwatchShadow = () => css`
  box-shadow: rgb(60 68 72 / 75%) 0px 7px 30px -10px;
`;

const TippyStyled = styled(Tippy)`
  .tippy-content {
    padding: 0;
  }
`;

const Swatch = styled(ButtonRaw)`
  width: 40px;
  height: 40px;
  background: ${props =>
    props.gradient && `linear-gradient(to right, ${props.gradient.from}, ${props.gradient.to})`};
  border-radius: 50%;
  ${props => getSwatchShadow()};
`;

const Options = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 36px);
  grid-gap: 16px;
  padding: 16px;
  max-width: 400px;
`;

const Option = styled(ButtonRaw)`
  width: 100%;
  height: 36px;
  background: ${props =>
    props.gradient && `linear-gradient(to right, ${props.gradient.from}, ${props.gradient.to})`};
  border-radius: 50%;
  ${props => getSwatchShadow()};
`;

const GradientOptions = props => {
  const { innerRef, gradients, onItemClick } = props;
  const options = Object.keys(gradients);

  return (
    <Options ref={innerRef}>
      {options.map(key => (
        <Option key={key} gradient={gradients[key]} onClick={() => onItemClick(key)} />
      ))}
    </Options>
  );
};

const SwatchWithRef = forwardRef((props, ref) => {
  const { gradient, name, onClick } = props;
  return <Swatch ref={ref} gradient={gradient} name={name} onClick={onClick} />;
});

const GradientSwatch = props => {
  const { placement, gradients, value, onChange } = props;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  useOnClickOutside(contentRef, () => setIsOpen(false));

  const handleToggleOpen = () => setIsOpen(prev => !prev);

  const handleSelectItem = value => {
    setIsOpen(false);
    onChange(value);
  };

  return (
    <TippyStyled
      theme="light"
      maxWidth="100%"
      interactive={true}
      appendTo={document.body}
      placement={placement}
      visible={isOpen}
      content={
        isOpen && (
          <GradientOptions
            innerRef={contentRef}
            gradients={gradients}
            onItemClick={handleSelectItem}
          />
        )
      }
    >
      <SwatchWithRef gradient={gradients[value]} onClick={handleToggleOpen} />
    </TippyStyled>
  );
};

const Gradient = props => {
  const { control, rules, defaultValue, ...componentProps } = props;
  const { name } = componentProps;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { value, onChange, onBlur } }) => {
        return <GradientSwatch {...componentProps} value={value} onChange={onChange} />;
      }}
    />
  );
};

Gradient.propTypes = {
  gradients: PropTypes.objectOf(PropTypes.shape({ from: PropTypes.string, to: PropTypes.string })),
  placement: PropTypes.oneOf(['top', 'bottom', 'left', 'right']),
};

Gradient.defaultProps = {
  gradients: theme.gradients,
  placement: 'right',
};

export default Gradient;
