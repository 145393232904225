import has from 'lodash/has';
import get from 'lodash/get';
import set from 'lodash/set';
import moment from 'moment';

// eslint-disable-next-line no-new-func
const getMomentFromString = value => new Function('moment', `return ${value}`)(moment);

const isFieldWithDefaultValue = ({ field, defaultValues = {} }) => {
  const { type, name } = field;

  if (type === 'multi') {
    const defaultValue = get(defaultValues, name);
    return defaultValue?.length > 0;
  }

  return has(defaultValues, name);
};

const getInitialValueIfNotDefined = field => {
  const { type, required } = field;
  if (type === 'checkbox') return false;
  if (type === 'editor') return [{ type: 'paragraph', children: [{ text: '' }] }];
  if (type === 'multi' && required) {
    const { fields } = field;
    const defaultValues = getDefaultValuesFromFields({ fields });
    return [defaultValues];
  }
  return '';
};

/**
 * @TODO
 * Extraer el contenido del switch a una función `getInitialValue`
 * El objetivo es poder usarla de forma aislada... Recibirá el field y devolverá su valor
 * Ejemplo:
 * const valueToSet = getInitialValue(field)
 * set(values, name, valueToSet)
 */
const getDefaultValuesFromFields = ({ fields, defaultValues = {} }) => {
  const values = { ...defaultValues };

  fields.forEach(field => {
    const { type, name, initialValue } = field;

    const hasDefaultValue = isFieldWithDefaultValue({ field, defaultValues });
    if (hasDefaultValue) return;

    const hasInitialValue = field.hasOwnProperty('initialValue');
    if (!hasInitialValue) return set(values, name, getInitialValueIfNotDefined(field));

    switch (type) {
      case 'filter': {
        const hasOptions = field.values?.length > 0;
        if (!hasOptions) return set(values, name, '');

        const matchOption = field.values.find(v => v.value === field.initialValue);
        const valueToSet = matchOption ? matchOption.value : field.values[0].value;
        set(values, name, valueToSet);
        break;
      }
      case 'date': {
        const valueMoment = initialValue?.includes('moment') && getMomentFromString(initialValue);
        const valueToSet = valueMoment ? valueMoment.format('YYYY-MM-DD') : initialValue;
        set(values, name, valueToSet);
        break;
      }
      case 'datetime': {
        const valueMoment = initialValue?.includes('moment') && getMomentFromString(initialValue);
        const valueToSet = valueMoment ? valueMoment.format('YYYY-MM-DD[T]HH:mm') : initialValue;
        set(values, name, valueToSet);
        break;
      }
      case 'time': {
        const valueMoment = initialValue?.includes('moment') && getMomentFromString(initialValue);
        const valueToSet = valueMoment ? valueMoment.format('HH:mm') : initialValue;
        set(values, name, valueToSet);
        break;
      }
      case 'dateRange': {
        const beginDate = initialValue?.beginDate;
        const beginDateMoment = beginDate?.includes('moment') && getMomentFromString(beginDate);
        const endDate = initialValue?.endDate;
        const endDateMoment = endDate?.includes('moment') && getMomentFromString(endDate);
        const valueToSet = {
          beginDate: beginDateMoment?.format('YYYY-MM-DD'),
          endDate: endDateMoment?.format('YYYY-MM-DD'),
        };
        set(values, name, valueToSet);
        break;
      }
      default: {
        set(values, name, initialValue);
      }
    }
  });

  return values;
};

export default getDefaultValuesFromFields;
