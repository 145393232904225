import styled from 'styled-components';

/**
 * 1. Debe ser igual al padding que tenga el componente Page
 */
const FabLayer = styled.div`
  position: sticky;
  bottom: 24px; /* 1 */
  right: 24px; /* 1 */
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export default FabLayer;
