import React from 'react';
import PropTypes from 'prop-types';
import useQueryForm from 'hooks/useQueryForm';
import Form from './Form';
import FormAutocomplete from './FormAutocomplete';

const FORM_TYPES = {
  normal: Form,
  autocomplete: FormAutocomplete,
};

const FormQuery = props => {
  const { type, request, transform, ...formProps } = props;
  const FormComponent = FORM_TYPES[type];

  const { isIdle, isLoading, isError, data: form } = useQueryForm({ request, transform });

  if (isIdle || isLoading) return null;

  if (isError) return null;

  return <FormComponent {...formProps} fields={form.fields} fieldsStyles={form.fieldsStyles} />;
};

FormQuery.propTypes = {
  type: PropTypes.oneOf(['normal', 'autocomplete']),
  request: PropTypes.shape({ url: PropTypes.string }).isRequired,
  transform: PropTypes.func,
};

FormQuery.defaultProps = {
  type: 'normal',
};

export default FormQuery;
