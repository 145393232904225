import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ChatMessage from 'components/ChatMessage';

const List = styled.div`
  display: flex;
  flex-direction: column;
`;

const Comment = props => {
  const { data, reporter, onAnswer, onFileClick } = props;
  const isMine = data.personaId === reporter;

  return <ChatMessage data={data} isMine={isMine} onAnswer={onAnswer} onFileClick={onFileClick} />;
};

const IncidenceDetailComments = props => {
  const { reporter, data, onAnswer, onFileClick } = props;

  if (data?.length === 0) return null;

  return (
    <List>
      {data &&
        data.map(item => (
          <Comment
            key={item._id}
            data={item}
            onAnswer={onAnswer}
            onFileClick={onFileClick}
            reporter={reporter}
          />
        ))}
    </List>
  );
};

IncidenceDetailComments.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string,
      message: PropTypes.string,
      dateTime: PropTypes.string,
      persona: PropTypes.shape({ id: PropTypes.string }),
    }),
  ),
  onAnswer: PropTypes.func,
};

export default IncidenceDetailComments;
