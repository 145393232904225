import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IllustrationLoader from 'components/IllustrationLoader';

const ILLUSTRATIONS = [
  'undraw_empty_xct9.svg',
  'undraw_blank_canvas_3rbb.svg',
  'undraw_noted_pc9f.svg',
];

const getRandomIllustration = () => {
  const length = ILLUSTRATIONS.length;
  const randomIndex = Math.floor(Math.random() * length);
  return ILLUSTRATIONS[randomIndex];
};

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Illustration = styled.div`
  flex: 1;
  max-height: 40vh;
  width: auto;
`;

const Title = styled.h2`
  margin: 32px 0 0 0;
  font-size: 24px;
  line-height: 1.2;
  color: ${props => props.theme.colors.primary};
`;

const Message = styled.p`
  margin: 32px 0 0 0;
  font-size: 20px;
  line-height: 1.2;
  color: #616161;
`;

const NotFound = props => {
  const { illustration, title, message } = props;

  const illustrationName = illustration ?? getRandomIllustration();

  return (
    <Wrapper>
      <Illustration>
        <IllustrationLoader name={illustrationName} />
      </Illustration>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  );
};

NotFound.propTypes = {
  illustration: PropTypes.string,
  message: PropTypes.string,
};

NotFound.defaultProps = {
  title: 'Página no encontrada',
  message: 'Lo sentimos, la página que busca no está disponible o no existe.',
};

export default NotFound;
