import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CreatedAt = styled.div`
  margin-right: 8px;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const UpdatedAt = styled.div`
  margin-left: auto;
  margin-right: 16px;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const CreatedBy = styled.div`
  display: flex;
  align-items: center;
`;

const CreatedByName = styled.div`
  font-size: 14px;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const ProjectTaskHeader = props => {
  const { createdAt, reporter, updatedAt } = props;
  const createdAtFromNow = createdAt && moment(createdAt).fromNow();
  const updatedAtFromNow = updatedAt && moment(updatedAt).fromNow();

  return (
    <Wrapper>
      <CreatedAt>Creada {createdAtFromNow} por</CreatedAt>
      <CreatedBy>
        <CreatedByName>{reporter || 'Anónimo'}</CreatedByName>
      </CreatedBy>
      <UpdatedAt>Actualizada {updatedAtFromNow}</UpdatedAt>
    </Wrapper>
  );
};

ProjectTaskHeader.propTypes = {
  createdAt: PropTypes.string,
  createdBy: PropTypes.shape({
    id: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  updatedAt: PropTypes.string,
};

ProjectTaskHeader.defaultProps = {};

export default ProjectTaskHeader;
