const isFile = file => file instanceof File;

const isFileList = files => files instanceof FileList;

const isFileArray = files => {
  if (!Array.isArray(files)) return false;
  return files.every(file => isFile(file));
};

const downloadBlob = ({ data, filename = 'documento' }) => {
  console.log(data, filename);
  const objectUrl = URL.createObjectURL(data);
  const a = document.createElement('a');

  a.setAttribute('href', objectUrl);
  a.setAttribute('download', filename);

  if (document.createEvent) {
    const event = document.createEvent('MouseEvents');
    event.initEvent('click', true, true);
    a.dispatchEvent(event);
  } else {
    a.click();
  }
};

const parseFileToThumbnail = ({ file }) => {
  const { type, name, preview, size } = file;
  return {
    contentType: type,
    originalname: name,
    preview,
    size,
  };
};

const getChecksumSha256 = async blob => {
  const uint8Array = new Uint8Array(await blob.arrayBuffer());
  const hashBuffer = await crypto.subtle.digest('SHA-256', uint8Array);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map(h => h.toString(16).padStart(2, '0')).join('');
};

export { isFile, isFileList, isFileArray, downloadBlob, parseFileToThumbnail, getChecksumSha256 };
