import moment from 'moment';
import { originUrl } from 'config';
import { client } from 'clients/api';
import { parseRequestData } from 'utils/requests';

const COMPANY_RAW_LOGO_WIDTH = 320;
const COMPANY_RAW_LOGO_HEIGHT = 132;
const COMPANY_LOGO_ASPECT_RATIO = COMPANY_RAW_LOGO_WIDTH / COMPANY_RAW_LOGO_HEIGHT;

/**
 * Devuelve la empresa
 * @returns {Promise}
 */
const fetchCompany = () => {
  const request = { url: '/companies/mine' };
  return client({ request }).then(parseCompanyResponse);
};

/**
 * Devuelve la url del logo de la empresa
 * @returns {Promise}
 */
const fetchCompanyLogo = id => {
  const request = { url: `/companies/logo/${id}` };
  return client({ request });
};

/**
 * Devuelve la empresa como administrador
 * @returns {Promise}
 */
const fetchCompanyAsAdmin = () => {
  const request = { url: '/companies/company' };
  return client({ request }).then(parseCompanyAsAdminResponse);
};

/**
 * Devuelve el certificado de la empresa
 * @returns {Promise}
 */
const fetchCertificate = () => {
  const request = {
    url: '/docs/filter',
    method: 'POST',
    body: {
      'metadata.userId': 'company',
      'metadata.certificate': true,
    },
  };
  return client({ request }).then(parseCertificateResponse);
};

const getCompanyLogoUrl = logo => {
  return logo && `${originUrl}${logo}`;
};

const parseCompanyResponse = company => {
  const { logo } = company;
  const logoUrl = getCompanyLogoUrl(logo);
  return { ...company, logoUrl };
};

const parseCompanyAsAdminResponse = company => {
  const { createdAt, logo } = company;
  const createdAtHumanized = createdAt && moment(createdAt).format('dddd, DD [de] MMMM [del] YYYY');
  const logoUrl = getCompanyLogoUrl(logo);
  return { ...company, createdAtHumanized, logoUrl };
};

const parseCertificateResponse = response => {
  return response && response[0] && parseCertificate(response[0]);
};

const parseCertificate = certificate => {
  const { metadata, uploadDate } = certificate;
  const { uploadedBy, expiresAt } = metadata;

  const state = getCertificateState(certificate);
  const uploadDateHumanized =
    uploadDate && moment(uploadDate).format('dddd, DD [de] MMMM [del] YYYY [a las] HH:mm');
  const expiresAtHumanized = expiresAt && moment(expiresAt).format('DD/MM/YYYY');

  return { ...certificate, state, uploadedBy, uploadDateHumanized, expiresAtHumanized };
};

const getCertificateState = certificate => {
  const expiresAt = certificate?.metadata?.expiresAt;
  const hasExpired = expiresAt && moment().isAfter(expiresAt);

  if (hasExpired) return 'expired';
  return 'fullfilled';
};

const formForCertificate = { url: '/front/forms/companyCertificate' };

/**
 * Sube el certificado de la empresa
 * @param   {Object} arg
 * @param   {File}   arg.file
 * @param   {string} arg.password
 * @returns {Promise}
 */
const uploadCertificate = ({ file, password }) => {
  const request = { url: '/docs/uploadCompanyCertificate', method: 'POST' };
  const values = { file, password };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Actualiza el logo de la compañía
 * @param   {string} args.companyId
 * @param   {string} args.file
 * @returns {Promise}
 */
const uploadLogo = ({ companyId, file }) => {
  const request = { url: '/companies/logo', method: 'POST' };
  const values = { companyId, file };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

const updateCompany = data => {
  const request = {
    url: '/companies/events',
    method: 'POST',
    event: 'company.update',
  };
  return client({ request, data });
};

const formForCompany = { url: '/front/forms/company' };

export {
  COMPANY_RAW_LOGO_WIDTH,
  COMPANY_RAW_LOGO_HEIGHT,
  COMPANY_LOGO_ASPECT_RATIO,
  fetchCompany,
  fetchCompanyLogo,
  fetchCompanyAsAdmin,
  fetchCertificate,
  formForCertificate,
  uploadCertificate,
  uploadLogo,
  updateCompany,
  formForCompany,
};
