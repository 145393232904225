import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { H2 } from 'components/Headings';
import FormControl from './FormControl';
import FormButtons from './FormButtons';

const Header = styled.header`
  margin-bottom: 40px;
`;

const Title = styled(H2)``;

const FormStyled = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: ${props => props.maxWidth};
`;

const Fields = styled.div`
  flex: none;
  display: grid;
  margin-bottom: 3rem;
  ${props => props.styles}
`;

const FormBase = props => {
  const {
    title,
    fields,
    methods,
    maxWidth,
    submitText,
    cancelText,
    onSubmit,
    onCancel,
    fieldsStyles,
  } = props;
  const { register, control, handleSubmit, formState } = methods;
  const { errors } = formState;

  return (
    <>
      {title && (
        <Header>
          <Title>{title}</Title>
        </Header>
      )}
      <FormStyled maxWidth={maxWidth} onSubmit={handleSubmit(data => onSubmit({ data, fields }))}>
        <Fields styles={fieldsStyles}>
          {fields.map(field => (
            <FormControl
              key={field.name || field.label}
              field={field}
              register={register}
              control={control}
              errors={errors}
            />
          ))}
        </Fields>
        <FormButtons submitText={submitText} cancelText={cancelText} onCancel={onCancel} />
      </FormStyled>
    </>
  );
};

FormBase.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })),
  methods: PropTypes.shape({
    register: PropTypes.func.isRequired,
    control: PropTypes.object.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    formState: PropTypes.shape({ errors: PropTypes.object }),
  }).isRequired,
  maxWidth: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

FormBase.defaultProps = {
  maxWidth: '100%',
};

export default FormBase;
