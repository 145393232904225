import { useMutation, useQueryClient } from 'react-query';
import { createComplaintComment } from 'services/complaints';

const useMutationCreateComplaintComment = () => {
  const queryClient = useQueryClient();
  const mutationInfo = useMutation(createComplaintComment, {
    onSuccess: () => {
      queryClient.invalidateQueries('complaint');
    },
  });
  return mutationInfo;
};

export default useMutationCreateComplaintComment;
