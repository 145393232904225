import { useQuery } from 'react-query';
import { fetchForm } from 'services/forms';

const KEY = 'form';

const defaultTransform = form => form;

// @TODO: En react-query v3 se puede usar la propiedad `select` para parsear los datos
const useQueryForm = ({ request, transform = defaultTransform }) => {
  const queryKey = [KEY, { request }];
  const queryInfo = useQuery({
    queryKey,
    queryFn: () => fetchForm({ request }),
    enabled: Boolean(request),
  });
  const parsedData = queryInfo.data && transform(queryInfo.data);

  return { ...queryInfo, queryKey, data: parsedData };
};

export default useQueryForm;
