import React from 'react';
import PropTypes from 'prop-types';
import Portal from '../Portal';
import ConditionalWrap from '../ConditionalWrap';
import DialogContainer from './DialogContainer';

const Dialog = props => {
  const { container } = props;

  return (
    <ConditionalWrap
      condition={container}
      wrap={children => <Portal container={container}>{children}</Portal>}
    >
      <DialogContainer {...props} />
    </ConditionalWrap>
  );
};

Dialog.propTypes = {
  children: PropTypes.func,
};

Dialog.defaultProps = {
  container: document.getElementById('root'),
};

export default Dialog;
