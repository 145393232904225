/**
 * Formatea un número en base al idioma
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {number} arg.value
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatNumber = ({ locale = 'default', value, options }) => {
  return new Intl.NumberFormat(locale, options).format(value);
};

/**
 * Formatea un número como moneda en base al idioma
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {number} arg.value
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatCurrency = ({ locale = 'default', value, options }) => {
  const defaultOptions = { style: 'currency', currency: 'EUR' };
  const mergedOptions = { ...defaultOptions, ...options };
  return new Intl.NumberFormat(locale, mergedOptions).format(value);
};

/**
 * Convierte un tamaño en bytes a un formato legible por humanos
 * @see https://stackoverflow.com/questions/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript
 * @param   {Object} arg
 * @param   {number} arg.bytes
 * @param   {number} arg.decimalPoint
 * @param   {number} arg.kilobyte
 * @returns {string}
 */
const formatBytesToSize = ({ bytes, decimalPoint = 2, kilobyte = 1000 }) => {
  if (bytes === 0) return '0 Bytes';

  const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const unit = Math.floor(Math.log(bytes) / Math.log(kilobyte));

  return `${parseFloat((bytes / Math.pow(kilobyte, unit)).toFixed(decimalPoint))} ${units[unit]}`;
};

/**
 * Devuelve un número entre el rango indicado por el mínimo y el máximo
 * @param   {Object} arg
 * @param   {number} arg.min
 * @param   {number} arg.max
 * @param   {number} arg.value
 * @returns {number}
 */
const minmax = ({ min, max, value }) => {
  return Math.min(Math.max(value, min), max);
};

export { formatNumber, formatCurrency, formatBytesToSize, minmax };
