import { useRef, useState, useEffect } from 'react';
import { waitForDomElement } from 'utils/dom';

const useWaitForDomElement = ({ selector, targetNode, options }) => {
  const selectorRef = useRef(selector);
  const targetNodeRef = useRef(targetNode);
  const optionsRef = useRef(options);
  const [element, setElement] = useState();

  useEffect(() => {
    waitForDomElement({
      selector: selectorRef.current,
      targetNode: targetNodeRef.current,
      options: optionsRef.current,
    }).then(setElement);
  }, [selector]);

  return { element };
};

export default useWaitForDomElement;
