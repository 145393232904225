import { useQuery } from 'react-query';
import { fetchCompanyLogo } from 'services/company';

const KEY = 'companyLogo';

const useQueryCompanyLogo = id => {
  const queryKey = [KEY, { id }];
  const queryInfo = useQuery({
    queryKey,
    queryFn: () => fetchCompanyLogo(id),
    enabled: Boolean(id),
  });

  return queryInfo;
};

export default useQueryCompanyLogo;
