/**
 * Comprueba si una string es interpretable como un boolean
 * @param {string} value
 * @returns {boolean}
 */
const isBooleanLikeString = value => ['true', 'false'].includes(value);

/**
 * Comprueba si una string es interpretable como un número
 * @param {string} value
 * @returns {boolean}
 */
const isNumberLikeString = value => !isNaN(parseFloat(value)) && isFinite(value);

/**
 * Comprueba si una string es interpretable como un ObjectId
 * @see https://quickref.me/check-if-a-string-is-a-mongo-db-object-id
 * @param {string} value
 * @returns {boolean}
 */
const isMongoId = value => value.length === 24 && /^[A-F0-9]+$/i.test(value);

/**
 * Parsea una string a boolean
 * @param {string} value
 * @returns {boolean}
 */
const parseFromStringToBoolean = value => value === 'true';

/**
 * Parsea una string a number
 * @param {string} value
 * @returns {number}
 */
const parseFromStringToNumber = value => Number(value);

/**
 * Devuelve una cadena con los carácteres especiales escapados para usarla en una expresión regular
 * @see https://github.com/sindresorhus/escape-string-regexp
 * @param   {string} value
 * @returns {string}
 */
const escapeStringRegexp = value => {
  return value?.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&').replace(/-/g, '\\x2d');
};

const getHrefFromText = text => {
  if (!text) return;

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return text.replace(urlRegex, url => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'https://' + hyperlink;
    }
    return hyperlink;
  });
};

const replaceURLs = text => {
  if (!text) return;

  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
  return text.replace(urlRegex, url => {
    let hyperlink = url;
    if (!hyperlink.match('^https?://')) {
      hyperlink = 'https://' + hyperlink;
    }
    return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>';
  });
};

export {
  isBooleanLikeString,
  isNumberLikeString,
  isMongoId,
  parseFromStringToBoolean,
  parseFromStringToNumber,
  escapeStringRegexp,
  replaceURLs,
  getHrefFromText,
};
