import { useQuery } from 'react-query';
import { fetchDocumentBlob } from 'services/documents';

const KEY = 'documentBlob';

const defaultStaleTime = Infinity;

const useQueryDocumentBlob = ({ url, staleTime = defaultStaleTime }) => {
  const queryKey = [KEY, { url }];
  const queryInfo = useQuery({
    queryKey,
    queryFn: () => fetchDocumentBlob({ url }),
    enabled: Boolean(url),
    staleTime,
  });
  return queryInfo;
};

export default useQueryDocumentBlob;
