import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import theme from 'utils/theme';

const SpinnerStyled = styled(Spinner)`
  display: flex;
  align-items: center;

  & > div {
    width: 8px;
    height: 8px;
  }
`;

const AsyncBreadcrumb = props => {
  const { isLoading, spinnerColor, children } = props;

  if (isLoading) return <SpinnerStyled name="three-bounce" color={spinnerColor} />;

  return children ? children : null;
};

AsyncBreadcrumb.propTypes = {
  isLoading: PropTypes.bool,
  spinnerColor: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

AsyncBreadcrumb.defaultProps = {
  spinnerColor: theme.colors.cadetBlue,
};

export default AsyncBreadcrumb;
