import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';

const InputDate = styled(Input)``;

InputDate.propTypes = {
  max: PropTypes.string,
};

InputDate.defaultProps = {
  max: '9999-12-31',
};

export default InputDate;
