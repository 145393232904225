import PropTypes from 'prop-types';
import styled from 'styled-components';

const Button = styled.button.attrs(props => ({
  type: props.type,
}))`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  padding: ${props => props.padding};
  font-size: ${props => props.fontSize};
  background: ${props => props.background};
  color: ${props => props.color};
  border: ${props => props.border};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
  &:hover {
    position: relative;
    &::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      background: ${props => props.overlayBackground};
      opacity: 0.4;
    }
  }
  ${props => props.style}
`;

Button.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  padding: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  fontSize: PropTypes.string,
  background: PropTypes.string,
  color: PropTypes.string,
  border: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  style: PropTypes.object,
};

Button.defaultProps = {
  type: 'button',
  title: null,
  padding: 0,
  fontSize: '1rem',
  background: 'transparent',
  color: 'inherit',
  border: 'none',
  overlayBackground: 'white',
};

export default Button;
