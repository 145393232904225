import React from 'react';
import Form from 'components/Form';
import { goBack } from 'utils/router';

const SearchForm = props => {
  const { fields, onSubmit } = props;

  return <Form fields={fields} onSubmit={onSubmit} onCancel={goBack} />;
};

SearchForm.defaultProps = {
  fields: [
    {
      type: 'text',
      name: 'id',
      label: 'Identificador',
      required: true,
      hint: 'Al crear la denuncia, se te proporcionó un identificador',
      placeholder: 'Introduzca el identificador de la denuncia',
      autoComplete: 'new-password',
    },
    {
      type: 'password',
      name: 'password',
      label: 'Contraseña',
      required: true,
      hint: 'Al crear la denuncia, se te proporcionó una contraseña',
      placeholder: 'Introduzca la contraseña de la denuncia',
      autoComplete: 'new-password',
    },
  ],
};

export default SearchForm;
