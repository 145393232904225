import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const Wrapper = styled.span`
  display: flex;
  align-items: center;
  gap: 4px;
  color: ${props => props.theme.colors.cadetBlue};
`;

const Breadcrumb = props => {
  const { children } = props;

  return <Wrapper>{children}</Wrapper>;
};

Breadcrumb.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
};

export default Breadcrumb;
