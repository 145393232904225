/**
 * Devuelve un objeto de reglas para la validación con react-hook-form
 * En caso de no tener reglas devolverá `undefined`
 * @see https://react-hook-form.com/api/useform/register
 * @see https://react-hook-form.com/api/usecontroller/controller
 *
 * @param {Object} args
 * @param {Object} args.field
 * @returns {Object|undefined}
 */
const getFieldValidationRules = ({ field }) => {
  const { type, required, maxLength, minLength, max, min } = field;

  const isFieldArray = type === 'multi';
  if (isFieldArray) return;

  const validationRules = {};

  if (required) {
    validationRules.required = 'Este campo es requerido';
  }

  if (maxLength) {
    validationRules.maxLength = {
      value: maxLength,
      message: `La longitud máxima es ${maxLength}`,
    };
  }

  if (minLength) {
    validationRules.minLength = {
      value: minLength,
      message: `La longitud mínima es ${minLength}`,
    };
  }

  if (max) {
    validationRules.max = {
      value: max,
      message: `El valor máximo es ${max}`,
    };
  }

  if (min) {
    validationRules.min = {
      value: min,
      message: `El valor mínimo es ${min}`,
    };
  }

  const hasRules = Object.keys(validationRules).length > 0;
  if (!hasRules) return;

  return validationRules;
};

export { getFieldValidationRules };
