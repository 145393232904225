import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useScrollPosition from 'hooks/useScrollPosition';

const Wrapper = styled.div`
  display: flex;
  height: 100vh;
  overflow: hidden;
`;

const Main = styled.main`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: calc(100% - 65px);
`;

const Header = styled.header`
  flex: none;
  position: sticky;
  top: 0;
  padding: 24px;
  z-index: 1;
  box-shadow: ${props => (props.showShadow ? '0 0.5rem 1rem rgba(0, 0, 0, 0.035)' : 'none')};
  transition: box-shadow 0.5s ease;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
`;

const Base = props => {
  const { header: HeaderComponent, breadcrumbs: BreadcrumbsComponent, children } = props;

  const { y } = useScrollPosition();
  const showShadow = y > 0;

  return (
    <Wrapper>
      <Main>
        <Header showShadow={showShadow}>
          <HeaderComponent />
          <BreadcrumbsComponent />
        </Header>
        <Content id="content">{children}</Content>
      </Main>
    </Wrapper>
  );
};

Base.propTypes = {
  header: PropTypes.func.isRequired,
  breadcrumbs: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
};

Base.defaultProps = {};

export default Base;
