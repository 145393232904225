import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useNavigate, useSearchParams } from 'react-router-dom';
import chroma from 'chroma-js';
import { Page } from 'components/Page';
import theme from 'utils/theme';
import { ButtonRaw } from 'components/Button';
import Card from 'components/Card';
import IllustrationLoader from 'components/IllustrationLoader';

const Title = styled.h1`
  text-align: center;
  font-size: ${props => props.theme.fontSizes['6xl']};
  @media (max-width: 650px) {
    font-size: ${props => props.theme.fontSizes['3xl']};
  }
`;

const Content = styled.div`
  display: flex;
  gap: 40px;
`;

const Buttons = styled.div`
  flex: 1;
  display: flex;
  gap: 56px;
  align-items: center;
  justify-content: center;
  @media (max-width: 1100px) {
    flex-direction: column;
    gap: 32px;
  }
`;

const Button = styled(ButtonRaw)`
  ${Card} {
    border: 4px solid ${props => (props.isSelected ? props.theme.colors.primary : 'transparent')};
    padding: 32px;
    box-shadow: 0px 0px 10px 3px ${props => chroma(props.color).alpha(0.2)};
    align-items: center;
    justify-content: center;
    width: 500px;
    height: 450px;
    gap: 32px;
  }
  svg {
    color: ${props => props.color};
  }

  &:hover {
    ${Card} {
      background: ${props => props.color}50;
    }
  }

  @media (max-width: 650px) {
    width: 100%;
    ${Card} {
      width: 100%;
      height: 350px;
    }
  }
`;

const Label = styled.div`
  font-weight: ${props => props.theme.fontWeights.bold};
  font-size: ${props => props.theme.fontSizes['4xl']};
`;

const Description = styled.div`
  font-weight: ${props => props.theme.fontWeights.normal};
  font-size: ${props => props.theme.fontSizes['xl']};
  color: ${props => props.theme.colors.trout};
`;

const Illustration = styled.div`
  flex: none;
  height: 225px;
  @media (max-width: 650px) {
    height: 150px;
  }
`;

const OptionButton = props => {
  const { illustration, label, description, color, id, selected, onSelect } = props;
  const isSelected = selected === id;

  return (
    <Button onClick={() => onSelect(id)} isSelected={isSelected} color={color}>
      <Card>
        <Illustration>
          <IllustrationLoader name={illustration} />
        </Illustration>
        <Label>{label}</Label>
        <Description>{description}</Description>
      </Card>
    </Button>
  );
};

const Home = props => {
  const { options } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');

  if (!id) return null;

  const handleSelect = id => {
    navigate(`${id}?${searchParams.toString()}`);
  };

  return (
    <Page>
      <Title>Canal de denuncias</Title>
      <Content>
        <Buttons>
          {options.map(type => (
            <OptionButton key={type.id} {...type} onSelect={handleSelect} />
          ))}
        </Buttons>
      </Content>
    </Page>
  );
};

Home.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      label: PropTypes.label,
      icon: PropTypes.string,
      color: PropTypes.string,
    }),
  ),
};

Home.defaultProps = {
  options: [
    {
      id: 'add',
      label: 'Crear',
      illustration: 'undraw_completing_re_i7ap.svg',
      description: 'Crea una denuncia nueva',
      color: theme.colors.success,
    },
    {
      id: 'search',
      label: 'Consultar',
      illustration: 'undraw_folder_re_apfp.svg',
      description: 'Accede a una denuncia existente',
      color: theme.colors.leaves,
    },
  ],
};

export default Home;
