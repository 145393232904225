import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IllustrationLoader from 'components/IllustrationLoader';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

const Illustration = styled.div`
  flex: 1;
  max-height: 40vh;
  width: auto;
`;

const Title = styled.h2`
  margin: 32px 0 0 0;
  font-size: 24px;
  line-height: 1.2;
  color: ${props => props.theme.colors.warning};
`;

const Message = styled.p`
  margin: 32px 0 0 0;
  font-size: 20px;
  line-height: 1.2;
  color: #616161;
`;

const PaymentRequired = props => {
  const { illustration, title, message } = props;

  const illustrationName = illustration;

  return (
    <Wrapper>
      <Illustration>
        <IllustrationLoader name={illustrationName} />
      </Illustration>
      <Title>{title}</Title>
      <Message>{message}</Message>
    </Wrapper>
  );
};

PaymentRequired.propTypes = {
  illustration: PropTypes.string,
  title: PropTypes.string,
  message: PropTypes.string,
};

PaymentRequired.defaultProps = {
  illustration: 'undraw_safe_bnk7.svg',
  title: 'No incluido en tu suscripción',
  message: 'Lo sentimos, tu suscripción no te da acceso a esta funcionalidad.',
};

export default PaymentRequired;
