import PropTypes from 'prop-types';
import styled from 'styled-components';

const FormLabel = styled.label`
  display: block;
  margin-bottom: 2px;
  font-size: ${props => props.fontSize};
`;

FormLabel.propTypes = {
  fontSize: PropTypes.string,
};

FormLabel.defaultProps = {
  fontSize: '1.25rem',
};

export default FormLabel;
