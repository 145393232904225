import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@rmwc/button';
import IconLoader from 'components/IconLoader';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledButton = styled(Button)`
  flex: 1;
  margin-bottom: 8px;
  .mdc-button__label {
    display: flex;
    align-items: center;
  }
`;

const StyledButtonIcon = styled.div`
  display: flex;
  margin-right: 4px;
  font-size: 18px;
`;

const ChatButton = props => {
  const { danger, label, icon, onClick } = props;

  return (
    <Wrapper>
      <StyledButton type="button" unelevated danger={danger} onClick={onClick}>
        <StyledButtonIcon>
          <IconLoader name={icon} />
        </StyledButtonIcon>
        <span>{label}</span>
      </StyledButton>
    </Wrapper>
  );
};

ChatButton.propTypes = {
  danger: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ChatButton.defaultProps = {
  danger: false,
  icon: 'add-2-icon.svg',
};

export default ChatButton;
