import { PureComponent } from 'react';
import ReactDOM from 'react-dom';

export default class Portal extends PureComponent {
  render() {
    const { container, children } = this.props;

    return container ? ReactDOM.createPortal(children, container) : null;
  }
}
