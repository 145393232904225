import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const getInteractiveStyle = ({ isInteractive }) => {
  if (!isInteractive) return;

  return css`
    &::after {
      content: '';
      border-radius: 16px;
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
    }

    &:hover {
      transform: scale(1.05, 1.05);
    }

    &:hover::after {
      opacity: 1;
    }
  `;
};

const Card = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  border-radius: 16px;
  box-shadow: ${props => props.boxShadowColor} 0px 7px 30px -10px;
  -webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  background: #fff;

  ${props => getInteractiveStyle(props)};
`;

Card.propTypes = {
  boxShadowColor: PropTypes.string,
};

Card.defaultProps = {
  boxShadowColor: 'rgba(150, 170, 180, 0.5)',
};

export default Card;
