import orderBy from 'lodash/orderBy';
import { getDocumentUrl } from 'services/documents';

const parseMessages = messages => {
  return messages.map(message => parseMessage(message));
};

const parseMessage = message => {
  const { doc, messageReply } = message;

  return {
    ...message,
    message: message?.note,
    doc: doc && parseMessageDocument(doc),
    messageReply: messageReply && parseMessage(messageReply),
  };
};

const parseMessageDocument = document => {
  return {
    ...document,
    url: getDocumentUrl({ url: document.url }),
  };
};

const sortMessages = messages => {
  return orderBy(messages, ['dateTime'], ['asc']);
};

export { parseMessages, parseMessage, parseMessageDocument, sortMessages };
