import moment from 'moment';
import groupBy from 'lodash/groupBy';

const ISO_DATE_PATTERN = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})/;
const ISO_DATE_TIME_PATTERN = /^(?<year>\d{4})-(?<month>\d{2})-(?<day>\d{2})T(?<hour>\d{2}):(?<minutes>\d{2})/;

/**
 * Formatea una fecha en base al idioma
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {Date}   arg.date
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatDate = ({
  locale = 'default',
  date,
  options = { day: '2-digit', month: '2-digit', year: 'numeric' },
}) => {
  return new Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Formatea una fecha en base al idioma
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {Date}   arg.date
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatDateTime = ({
  locale = 'default',
  date,
  options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
}) => {
  return new Intl.DateTimeFormat(locale, options).format(date);
};

/**
 * Formatea una fecha en base al idioma.
 * La fecha debe ser un string con el formato YYYY-MM-DD
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {string} arg.date
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatDateString = ({ locale, date, options }) => {
  const parsedDate = parseFromStringToDate(date);
  return formatDate({ locale, date: parsedDate, options });
};

/**
 * Formatea una fecha en base al idioma.
 * La fecha debe ser un string con el formato YYYY-MM-DDTHH:mm
 * @param   {Object} arg
 * @param   {string} [arg.locale]
 * @param   {string} arg.date
 * @param   {Object} [arg.options]
 * @returns {string}
 */
const formatDateTimeString = ({ locale, date, options }) => {
  const parsedDateTime = parseFromStringToDateTime(date);
  return formatDateTime({ locale, date: parsedDateTime, options });
};

/**
 * Parsea una fecha en formato ISO a Date
 * @param {string} date
 * @returns {Date}
 */
const parseFromStringToDate = date => {
  const { year, month, day } = date.match(ISO_DATE_PATTERN).groups;
  return new Date(+year, +month - 1, +day);
};

/**
 * Parsea una fecha en formato ISO a Date
 * @param {string} date
 * @returns {Date}
 */
const parseFromStringToDateTime = date => {
  const { year, month, day, hour, minutes } = date.match(ISO_DATE_TIME_PATTERN).groups;
  return new Date(+year, +month - 1, +day, +hour, +minutes);
};

/**
 * Devuelve un array de días de la semana en base al idioma
 * @param {Object} arg
 * @param {string} arg.format
 * @returns {Array.<string>}
 */
const getArrayOfWeekdays = ({ format = 'short' } = {}) => {
  return Array.from({ length: 7 }).map((item, index) => {
    const date = new Date(Date.UTC(1970, 5, index + 1));
    return formatDate({ date, options: { weekday: format } });
  });
};

/**
 * Devuelve un array de fechas desde beginDate hasta endDate
 * @param  {*}      beginDate
 * @param  {*}      endDate
 * @param  {String} format    - Formato de la fecha
 * @return {Array}
 */
const getArrayOfDates = ({ beginDate, endDate, format = 'YYYY-MM-DD' }) => {
  beginDate = moment(beginDate);
  endDate = moment(endDate);

  const dates = [];
  let currentDate = moment(beginDate);

  while (currentDate <= endDate) {
    dates.push(currentDate.format(format));
    currentDate = currentDate.clone().add(1, 'day');
  }
  return dates;
};

/**
 * Comprueba si una fecha esta contenida en un rango
 * @param   {Object}                arg
 * @param   {string|Moment}         arg.date               - Fecha
 * @param   {Array.<string|Moment>} arg.range              - Rango [beginDate, endDate]
 * @param   {string}                [arg.granularity]      - Granularidad para comparar, por defecto milisegundos
 * @param   {string}                [arg.inclusivity='[]'] - Indicadores para incluir o excluir los extremos de los rangos. Ej.: (), [), (], []
 * @returns {boolean}
 */
const isDateBetweenDateRange = ({ date, range, granularity, inclusivity = '[]' }) => {
  const [beginDate, endDate] = range;
  return moment(date).isBetween(beginDate, endDate, granularity, inclusivity);
};

/**
 * Comprueba si un rango está contenido en otro rango
 * @param   {Object}                arg
 * @param   {Array.<string|Moment>} arg.source        - Rango fuente [beginDate, endDate]
 * @param   {Array.<string|Moment>} arg.target        - Rango destino [beginDate, endDate]
 * @param   {string}                [arg.granularity] - Granularidad para comparar, por defecto milisegundos
 * @returns {boolean}
 */
const isDateRangeInDateRange = ({ source, target, granularity }) => {
  const [sourceBegin, sourceEnd] = source;
  const [targetBegin, targetEnd] = target;
  return (
    moment(targetBegin).isSameOrBefore(sourceBegin, granularity) &&
    moment(targetEnd).isSameOrAfter(sourceEnd, granularity)
  );
};

/**
 * Comprueba si dos rangos de fechas se superponen en algún punto
 * @requires isDateBetweenDateRange
 * @requires isDateRangeInDateRange
 * @param   {Object}                arg
 * @param   {Array.<string|Moment>} arg.rangeA             - Rango [beginDate, endDate]
 * @param   {Array.<string|Moment>} arg.rangeB             - Rango [beginDate, endDate]
 * @param   {string}                [arg.granularity]      - Granularidad para comparar, por defecto milisegundos
 * @param   {string}                [arg.inclusivity='[]'] - Indicadores para incluir o excluir los extremos de los rangos. Ej.: (), [), (], []
 * @returns {boolean}
 */
const areDateRangesOverlapping = ({ rangeA, rangeB, granularity, inclusivity = '[]' }) => {
  const [beginA, endA] = rangeA;

  if (isDateBetweenDateRange({ date: beginA, range: rangeB, granularity, inclusivity })) {
    return true;
  }
  if (isDateBetweenDateRange({ date: endA, range: rangeB, granularity, inclusivity })) {
    return true;
  }
  if (isDateRangeInDateRange({ source: rangeA, target: rangeB, granularity })) {
    return true;
  }
  if (isDateRangeInDateRange({ source: rangeB, target: rangeA, granularity })) {
    return true;
  }
  return false;
};

/**
 * Devuelve la zona horaria actual
 * @see https://developer.mozilla.org/es/docs/Web/JavaScript/Reference/Global_Objects/DateTimeFormat
 * @see https://stackoverflow.com/questions/1091372/getting-the-clients-timezone-offset-in-javascript
 * @returns {string}
 */
const getTimeZone = () => Intl.DateTimeFormat().resolvedOptions().timeZone;

/**
 * Devuelve el año al que pertenece un día
 * @param   {string|Moment} date
 * @returns {number}
 */
const getIsoYearFromDate = date => moment(date).isoWeekYear();

/**
 * Devuelve un id con el Año ISO y la Semana ISO separados por un guión
 * @param {Object} arg
 * @param {string|Moment} arg.date
 * @param {string} arg.separator
 * @returns {string}
 */
const getIsoWeekIdFromDate = ({ date, separator = 'W' }) => {
  return `${moment(date).format('GGGG')}${separator}${moment(date).format('WW')}`;
};

/**
 * Devuelve un rango de fechas en base a la semana ISO
 * @param {Object} arg
 * @param {string} arg.isoWeek
 * @param {string} arg.format
 * @returns {Object}
 */
const getDateRangeFromIsoWeek = ({ isoWeek, format = 'YYYY-MM-DD' }) => {
  const startDate = moment(isoWeek)
    .startOf('isoWeek')
    .format(format);
  const endDate = moment(isoWeek)
    .endOf('isoWeek')
    .format(format);

  return { startDate, endDate };
};

/**
 * Devuelve el inicio de la primera semana ISO dada una fecha de inicio (ya sea mes, año...)
 * Para identificar la primera semana existen varias definiciones, una de ellas es: "la que contiene el primer jueves"
 * @see https://en.wikipedia.org/wiki/ISO_week_date
 * @see https://en.wikipedia.org/wiki/ISO_week_date#First_week
 * @see https://github.com/moment/moment/issues/3696
 * @param   {string|Moment} startDate
 * @returns {Moment}
 */
const getStartOfIsoWeekBelongsToStart = startDate => {
  const isWeekBelongToStart = moment(startDate).isoWeekday() <= 4;
  return moment(startDate)
    .startOf('isoWeek')
    .add(isWeekBelongToStart ? 0 : 1, 'weeks');
};

/**
 * Devuelve el fin de la última semana ISO dada una fecha de fin (ya sea mes, año...)
 * Para identificar la primera semana existen varias definiciones, una de ellas es: "la que contiene el primer jueves"
 * @see https://en.wikipedia.org/wiki/ISO_week_date
 * @see https://en.wikipedia.org/wiki/ISO_week_date#First_week
 * @see https://github.com/moment/moment/issues/3696
 * @param   {string|Moment} endDate
 * @returns {Moment}
 */
const getEndOfIsoWeekBelongsToEnd = endDate => {
  const isWeekBelongToEnd = moment(endDate).isoWeekday() >= 4;
  return moment(endDate)
    .endOf('isoWeek')
    .subtract(isWeekBelongToEnd ? 0 : 1, 'weeks');
};

/**
 * Devuelve el inicio de la semana ISO
 * @param   {string|Moment} date
 * @returns {Moment}
 */
const getStartOfIsoWeek = date => moment(date).startOf('isoWeek');

/**
 * Devuelve el fin de la semana ISO
 * @param   {string|Moment} date
 * @returns {Moment}
 */
const getEndOfIsoWeek = date => moment(date).endOf('isoWeek');

/**
 * Nota: Aparentemente no existe una definición ISO para un mes
 * Devuelve el inicio del mes a partir de una fecha (será la primera semana que contenga un jueves)
 * Esta implementación se situa en el jueves de la semana actual para obtener el mes al que pertenece,
 * luego obtiene la fecha de inicio de dicho mes y por último el inicio de la primera semana.
 * @see https://en.wikipedia.org/wiki/ISO_week_date#Weeks_per_month
 * @requires getStartOfIsoWeekBelongsToStart
 * @param    {string|Moment} date
 * @returns  {Moment}
 */
const getStartOfIsoMonth = date => {
  const thursdayOfWeek = moment(date).isoWeekday(4);
  const startOfMonth = moment(thursdayOfWeek).startOf('month');
  const startOfIsoMonth = getStartOfIsoWeekBelongsToStart(startOfMonth);
  return startOfIsoMonth;
};

/**
 * Nota: Aparentemente no existe una definición ISO para un mes
 * Devuelve el fin del mes a partir de una fecha (será la última semana que contenga un jueves)
 * Esta implementación se situa en el jueves de la semana actual para obtener el mes al que pertenece,
 * luego obtiene la fecha de fin de dicho mes y por último el fin de la última semana.
 * @see https://en.wikipedia.org/wiki/ISO_week_date#Weeks_per_month
 * @requires getEndOfIsoWeekBelongsToEnd
 * @param    {string|Moment} date
 * @returns  {Moment}
 */
const getEndOfIsoMonth = date => {
  const thursdayOfWeek = moment(date).isoWeekday(4);
  const endOfMonth = moment(thursdayOfWeek).endOf('month');
  const endOfIsoMonth = getEndOfIsoWeekBelongsToEnd(endOfMonth);
  return endOfIsoMonth;
};

/**
 * Devuelve el inicio del año a partir de una fecha (será la primera semana que contenga un jueves)
 * Ejemplos: 2019: 31-12-2018, 2020: 30-12-2019, 2021: 04-01-2021
 * @requires getStartOfIsoWeekBelongsToStart
 * @param    {string|Moment} date
 * @returns  {Moment}
 */
const getStartOfIsoYear = date => {
  const startOfYear = moment(date).startOf('year');
  const startOfIsoYear = getStartOfIsoWeekBelongsToStart(startOfYear);
  return startOfIsoYear;
};

/**
 * Devuelve el fin del año a partir de una fecha (será la última semana que contenga un jueves)
 * Ejemplos: 2019: 29-12-2019, 2020: 03-12-2021, 2021:02-12-2022
 * @requires getEndOfIsoWeekBelongsToEnd
 * @param    {string|Moment} date
 * @returns  {Moment}
 */
const getEndOfIsoYear = date => {
  const endOfYear = moment(date).endOf('year');
  const endOfIsoYear = getEndOfIsoWeekBelongsToEnd(endOfYear);
  return endOfIsoYear;
};

/**
 * Devuelve la diferencia entre el inicio y fin de un rango
 * @param   {*}      arg
 * @param   {Object} arg.range      - Rango de fechas con beginDate y endDate
 * @param   {string} arg.unitOfTime - Unidad de tiempo para calcular la diferencia
 * @returns {number}
 */
const getDateRangeDiff = ({ range, unitOfTime = 'days' }) => {
  const { beginDate, endDate } = range;
  return endDate ? moment(endDate).diff(moment(beginDate), unitOfTime) + 1 : 0;
};

/**
 * Devuelve un array de fechas dado un rango
 * @param   {Object}         arg
 * @param   {string|Moment}  arg.beginDate - Fecha inicio
 * @param   {string|Moment}  arg.endDate   - Fecha fin
 * @param   {string}         [arg.format]  - Formato para las fechas
 * @returns {Array.<string>}
 */
const getDatesFromDateRange = ({ beginDate, endDate, format }) => {
  const datesDiff = moment(endDate).diff(moment(beginDate), 'days') + 1;
  const dates = Array.from({ length: datesDiff }, (x, index) =>
    moment(beginDate)
      .add(index, 'day')
      .format(format),
  );
  return dates;
};

/**
 * Devuelve un objeto con el año y número de la semana como clave y un array de fechas como valor
 * @requires getIsoWeekIdFromDate
 * @param   {Array.<string|Moment>} dates
 * @returns {Object}
 */
const getDatesGroupedByIsoWeek = dates => {
  return groupBy(dates, date => getIsoWeekIdFromDate({ date, separator: '-' }));
};

/**
 * Devuelve una colección de rangos de semanas completas dado un rango de fechas
 * @requires getDatesFromDateRange
 * @requires getDatesGroupedByIsoWeek
 * @param   {Object}         arg
 * @param   {string|Moment}  arg.beginDate - Fecha inicio
 * @param   {string|Moment}  arg.endDate   - Fecha fin
 * @param   {string}         [arg.format]  - Formato para las fechas
 * @returns {Object}
 */
const getFullWeekRangesFromDateRange = ({ beginDate, endDate, format }) => {
  const dates = getDatesFromDateRange({ beginDate, endDate, format });
  const datesGroupedByIsoWeek = getDatesGroupedByIsoWeek(dates);
  const fullWeekRanges = Object.values(datesGroupedByIsoWeek)
    .filter(dates => dates.length === 7)
    .map(dates => ({
      beginDate: moment(dates[0]).format(format),
      endDate: moment(dates[6])
        .endOf('day')
        .format(format),
    }));
  return fullWeekRanges;
};

/**
 * Devuelve un rango de fechas humanizado
 * @param   {Object} arg
 * @param   {Object} arg.range                 - Rango de fechas con beginDate y endDate
 * @param   {string} [arg.format='DD/MM/YYYY'] - Formato para las fechas
 * @param   {string} [arg.separator='al']      - Separador entre fechas
 * @returns {string}
 */
const getDateRangeHumanized = ({ range, format = 'DD/MM/YYYY', separator = 'al' }) => {
  const { beginDate, endDate } = range;
  return endDate
    ? `${moment(beginDate).format(format)} ${separator} ${moment(endDate).format(format)}`
    : moment(beginDate).format(format);
};

export {
  formatDate,
  formatDateString,
  formatDateTimeString,
  parseFromStringToDate,
  parseFromStringToDateTime,
  getArrayOfWeekdays,
  getArrayOfDates,
  isDateBetweenDateRange,
  isDateRangeInDateRange,
  areDateRangesOverlapping,
  getTimeZone,
  getIsoYearFromDate,
  getIsoWeekIdFromDate,
  getDateRangeFromIsoWeek,
  getStartOfIsoWeek,
  getEndOfIsoWeek,
  getStartOfIsoMonth,
  getEndOfIsoMonth,
  getStartOfIsoYear,
  getEndOfIsoYear,
  getDateRangeDiff,
  getDatesFromDateRange,
  getDatesGroupedByIsoWeek,
  getFullWeekRangesFromDateRange,
  getDateRangeHumanized,
};
