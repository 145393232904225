import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { getReadableColor } from 'utils/colors';
import { formatBytesToSize } from 'utils/numbers';
import Icon from 'components/Icon';
import IconLoader from 'components/IconLoader';

const DOCUMENT_TYPES = {
  image: 'image',
  zip: 'zip',
  rar: 'rar',
  pdf: 'pdf',
  word: 'word',
  excel: 'excel',
  txt: 'txt',
  unknown: 'unknown',
};

const DOCUMENT_COLORS = {
  [DOCUMENT_TYPES.zip]: '#FFC107',
  [DOCUMENT_TYPES.rar]: '#FFC107',
  [DOCUMENT_TYPES.pdf]: '#f44336',
  [DOCUMENT_TYPES.word]: '#2196f3',
  [DOCUMENT_TYPES.excel]: '#00c853',
  [DOCUMENT_TYPES.txt]: '#9e9e9e',
  [DOCUMENT_TYPES.unknown]: '#673ab7',
};

const DOCUMENT_ICONS = {
  [DOCUMENT_TYPES.zip]: 'file-office-zip-icon.svg',
  [DOCUMENT_TYPES.rar]: 'file-office-rar-icon.svg',
  [DOCUMENT_TYPES.pdf]: 'file-office-pdf-icon.svg',
  [DOCUMENT_TYPES.word]: 'file-office-doc-icon.svg',
  [DOCUMENT_TYPES.excel]: 'file-office-xls-icon.svg',
  [DOCUMENT_TYPES.txt]: 'file-office-txt-icon.svg',
  [DOCUMENT_TYPES.unknown]: 'file-new-2-icon.svg',
};

const getType = doc => {
  const { contentType } = doc;

  if (!contentType) return null;

  if (contentType.includes('image')) return DOCUMENT_TYPES.image;
  if (contentType.includes('zip')) return DOCUMENT_TYPES.zip;
  if (contentType.includes('rar')) return DOCUMENT_TYPES.rar;
  if (contentType.includes('pdf')) return DOCUMENT_TYPES.pdf;
  if (contentType.includes('word')) return DOCUMENT_TYPES.word;
  if (contentType.includes('excel')) return DOCUMENT_TYPES.excel;
  if (contentType.includes('text/plain')) return DOCUMENT_TYPES.txt;

  return DOCUMENT_TYPES.unknown;
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
`;

const WrapperForImage = styled(Wrapper)`
  width: 100px;
  height: 100px;
`;

const WrapperForDocument = styled(Wrapper)`
  width: 200px;
  height: 100px;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: ${props => props.color || '#000'};
  background: ${props => props.background || '#fff'};
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const FileName = styled.div`
  margin-top: 10px;
  padding: 0 10px;
  text-align: center;
  font-size: 12px;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  word-break: break-word;
  overflow: hidden;
`;

const FileSize = styled.div`
  margin-top: 5px;
  padding: 2px 4px;
  font-size: 10px;
  border: 1px dashed;
  border-radius: 6px;
`;

const PreviewImage = props => {
  const { doc, onFileClick } = props;
  const { url, originalname } = doc;

  return (
    <Content onClick={() => onFileClick({ data: doc })}>
      <Image src={url} alt={originalname} />
    </Content>
  );
};

const PreviewDocument = props => {
  const { doc, type, onFileClick } = props;
  const { originalname, size } = doc;

  const icon = DOCUMENT_ICONS[type];
  const background = DOCUMENT_COLORS[type];
  const color = getReadableColor({ color: background });
  const fileSize = formatBytesToSize({ bytes: size });

  return (
    <Content color={color} background={background} onClick={() => onFileClick({ data: doc })}>
      <Icon size={32}>
        <IconLoader name={icon} />
      </Icon>
      <FileName>{originalname}</FileName>
      <FileSize>{fileSize}</FileSize>
    </Content>
  );
};

const PersonaMessageBoxDocument = props => {
  const { doc, onFileClick } = props;
  const type = getType(doc);

  if (!type) return null;

  if (type === DOCUMENT_TYPES.image) {
    return (
      <WrapperForImage width="100px" height="100px">
        <PreviewImage doc={doc} onFileClick={onFileClick} />
      </WrapperForImage>
    );
  }

  return (
    <WrapperForDocument>
      <PreviewDocument doc={doc} type={type} onFileClick={onFileClick} />
    </WrapperForDocument>
  );
};

PersonaMessageBoxDocument.propTypes = {
  doc: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    contentType: PropTypes.string,
    url: PropTypes.string,
    originalname: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
};

export default PersonaMessageBoxDocument;
