import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import useBreadcrumbs from 'use-react-router-breadcrumbs';
import styled from 'styled-components';
import routes from 'routes';
import IconLoader from 'components/IconLoader';
import Breadcrumb from './Breadcrumb';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

const IconStyled = styled.div`
  display: flex;
  font-size: 1.5rem;
  color: ${props => props.theme.colors.trout};
`;

const Slash = () => {
  return (
    <IconStyled>
      <IconLoader name="arrow-right-icon.svg" />
    </IconStyled>
  );
};

const Breadcrumbs = props => {
  const { options } = props;
  const breadcrumbs = useBreadcrumbs(routes, options);

  return (
    <Wrapper>
      {breadcrumbs.map(({ match, breadcrumb }, index, { length }) => {
        const isFirstItem = index === 0;
        const isLastItem = index === length - 1;

        return (
          <Fragment key={match.pathname}>
            {!isFirstItem ? <Slash /> : null}
            {!isLastItem ? (
              <Breadcrumb>{breadcrumb}</Breadcrumb>
            ) : (
              <Breadcrumb>{breadcrumb}</Breadcrumb>
            )}
          </Fragment>
        );
      })}
    </Wrapper>
  );
};

Breadcrumbs.propTypes = {
  options: PropTypes.shape({
    disableDefaults: PropTypes.bool,
    excludePaths: PropTypes.arrayOf(PropTypes.string),
  }),
};

Breadcrumbs.defaultProps = {
  options: {
    disableDefaults: true,
    excludePaths: ['/', '/settings'],
  },
};

export default Breadcrumbs;
