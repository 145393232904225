import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import getDefaultValuesFromFields from './utils/getDefaultValuesFromFields';
import FormFieldWatcher from './FormFieldWatcher';
import FormBase from './FormBase';

const FormAutocomplete = props => {
  const {
    title,
    fields,
    defaultValues,
    shouldUnregister,
    fieldsStyles,
    submitText,
    cancelText,
    onSubmit,
    onCancel,
  } = props;
  const [formFields, setFormFields] = useState(fields);

  const defaultValuesMerged = getDefaultValuesFromFields({ fields, defaultValues });
  const methods = useForm({ defaultValues: defaultValuesMerged, shouldUnregister });

  const fieldsWithWatch = fields.filter(field => field['watchField']);

  return (
    <>
      {fieldsWithWatch.map(field => (
        <FormFieldWatcher
          key={field.name}
          field={field}
          control={methods.control}
          setValue={methods.setValue}
          setFormFields={setFormFields}
        />
      ))}

      <FormBase
        title={title}
        fields={formFields}
        methods={methods}
        fieldsStyles={fieldsStyles}
        submitText={submitText}
        cancelText={cancelText}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </>
  );
};

FormAutocomplete.propTypes = {
  title: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({ type: PropTypes.string, name: PropTypes.string })),
  defaultValues: PropTypes.object,
  shouldUnregister: PropTypes.bool,
  maxWidth: PropTypes.string,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func,
};

FormAutocomplete.defaultProps = {
  shouldUnregister: true,
};

export default FormAutocomplete;
