import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from 'components/Page';
import Title from 'components/Title';
import useMutationCreateComplaint from 'hooks/useMutationCreateComplaint';
import { getErrorDialog, getSuccessDialog } from 'utils/dialog';
import Loading from 'components/Loading';
import Dialog from 'components/Dialog';
import { goBack } from '../../utils/router';
import AddForm from './AddForm';

const Content = styled.div`
  margin: 0 auto;
  width: 60%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  @media (max-width: 650px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const MessageWrapper = styled.div`
  text-align: center;
  padding: 24px 48px;
`;

const Highlight = styled.span`
  font-weight: bold;
  font-size: ${props => props.theme.fontSizes['2xl']};
`;

const Message = ({ id, password }) => {
  return (
    <MessageWrapper>
      IDENTIFICADOR: <Highlight>{id}</Highlight>
      <br />
      CONTRASEÑA: <Highlight>{password}</Highlight> <br />
      <br />
      Para poder consultar el estado de tu denuncia es necesario que facilites estos dos datos en el
      formulario de consulta.
      <br />
      <br />
      ¡No olvides apuntarlos!
    </MessageWrapper>
  );
};

const Add = () => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(null);
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { mutate: create } = useMutationCreateComplaint();

  const doMutation = ({ mutationFn, data, onSuccess }) => {
    setLoading(true);
    mutationFn(data, {
      onSuccess,
      onError: error => {
        setDialog(getErrorDialog({ message: error.message, onAccept: () => setDialog(null) }));
      },
      onSettled: () => setLoading(false),
    });
  };

  const handleSubmit = ({ data }) => {
    const mutationData = { ...data, company: id };
    doMutation({ mutationFn: create, data: mutationData, onSuccess: handleCreateSuccess });
  };

  const handleCreateSuccess = data => {
    const { id, password } = data;
    const dialog = getSuccessDialog({
      title: 'Hemos recibido tu denuncia',
      message: <Message id={id} password={password} />,
      onAccept: () => {
        goBack();
      },
    });
    setDialog(dialog);
  };

  return (
    <Page>
      {loading && <Loading />}
      {dialog && <Dialog {...dialog} />}
      <Title>Crear</Title>
      <Content>
        <AddForm onSubmit={handleSubmit} />
      </Content>
    </Page>
  );
};

export default Add;
