import React from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import LogoImage from 'assets/images/logo-horizontal.svg';
import { originUrl } from 'config';
import useQueryCompanyLogo from '../../hooks/useQueryCompanyLogo';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 40px;
  gap: 32px;
`;

const Logos = styled.div`
  display: flex;
  justify-content: space-between;
  height: 32px;
`;

const LogoImg = styled.img`
  width: auto;
  height: 100%;
`;

const Title = styled.div`
  flex: 1;
`;

const HeaderBar = () => {
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { data } = useQueryCompanyLogo(id);

  return (
    <Wrapper>
      <Logos>
        <LogoImg src={LogoImage} />
        {data && <LogoImg src={`${originUrl}${data}`} />}
      </Logos>
      <Title id="titlePortal" />
    </Wrapper>
  );
};

HeaderBar.propTypes = {};

export default HeaderBar;
