const getErrorDialog = ({ onAccept, acceptText = 'Aceptar', acceptColor = '#f44336', ...rest }) => {
  return {
    type: 'error',
    accept: { text: acceptText, color: acceptColor, onClick: onAccept },
    ...rest,
  };
};

const getWarningDialog = ({
  onAccept,
  acceptText = 'Aceptar',
  acceptColor = '#fdd037',
  ...rest
}) => {
  return {
    type: 'warning',
    accept: { text: acceptText, color: acceptColor, onClick: onAccept },
    ...rest,
  };
};

const getConfirmDialog = ({
  onAccept,
  onCancel,
  acceptText = 'Aceptar',
  acceptColor = '#fdd037',
  cancelText = 'Cancelar',
  cancelColor,
  ...rest
}) => {
  return {
    type: 'warning',
    accept: { text: acceptText, color: acceptColor, onClick: onAccept },
    cancel: { text: cancelText, color: cancelColor, onClick: onCancel },
    ...rest,
  };
};

const getSuccessDialog = ({
  onAccept,
  acceptText = 'Aceptar',
  acceptColor = '#1ef59f',
  ...rest
}) => {
  return {
    type: 'success',
    accept: { text: acceptText, color: acceptColor, onClick: onAccept },
    ...rest,
  };
};

const getInfoDialog = ({ onAccept, acceptText = 'Aceptar', acceptColor = '#00b0ff', ...rest }) => {
  return {
    type: 'info',
    accept: { text: acceptText, color: acceptColor, onClick: onAccept },
    ...rest,
  };
};

export { getErrorDialog, getWarningDialog, getConfirmDialog, getSuccessDialog, getInfoDialog };
