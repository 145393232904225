import React from 'react';
import PropTypes from 'prop-types';
import { getReadableColor } from 'utils/colors';
import { formatBytesToSize } from 'utils/numbers';
import Icon from 'components/Icon';
import IconLoader from 'components/IconLoader';

const getTypeByContentType = contentType => {
  if (contentType.includes('zip')) return 'zip';
  if (contentType.includes('rar')) return 'rar';
  if (contentType.includes('pdf')) return 'pdf';
  if (contentType.includes('excel') || contentType.includes('spreadsheetml')) return 'excel';
  if (contentType.includes('word')) return 'word';
  if (contentType.includes('text/plain')) return 'txt';

  return 'other';
};

const getColorByType = type => {
  const colors = {
    zip: '#FFC107',
    rar: '#FFC107',
    pdf: '#f44336',
    word: '#2196f3',
    excel: '#00c853',
    txt: '#f0f0f0',
    default: '#673ab7',
  };

  return colors[type] || colors['default'];
};

const getIconByType = type => {
  const icons = {
    zip: 'file-office-zip-icon.svg',
    rar: 'file-office-rar-icon.svg',
    pdf: 'file-office-pdf-icon.svg',
    word: 'file-office-doc-icon.svg',
    excel: 'file-office-xls-icon.svg',
    txt: 'file-office-txt-icon.svg',
    default: 'file-new-2-icon.svg',
  };

  return icons[type] || icons['default'];
};

const styles = {
  wrapper: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 4,
    gap: '4px',
  },
  name: {
    fontSize: 12,
    fontWeight: 500,
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    wordBreak: 'break-word',
    textAlign: 'center',
    padding: '0 5px',
  },
  size: {
    fontSize: 10,
    padding: '2px 4px',
    borderRadius: 6,
    border: '1px dashed',
  },
};

const DocumentThumbnailFile = props => {
  const { contentType, length, chunkSize, metadata, showBytes, size, iconSize, direction } = props;
  const originalname = metadata?.originalname || props.originalname;
  const type = getTypeByContentType(contentType);
  const icon = getIconByType(type);
  const background = getColorByType(type);
  const color = getReadableColor({ color: background });
  const bytes = length || chunkSize || size;

  return (
    <div style={{ ...styles.wrapper, flexDirection: direction, background, color }}>
      <Icon size={iconSize}>
        <IconLoader name={icon} />
      </Icon>
      <div style={styles.name}>{originalname}</div>
      {showBytes && <div style={styles.size}>{formatBytesToSize({ bytes })}</div>}
    </div>
  );
};

DocumentThumbnailFile.propTypes = {
  contentType: PropTypes.string,
  originalname: PropTypes.string,
  length: PropTypes.number,
  showBytes: PropTypes.bool,
};

DocumentThumbnailFile.defaultProps = {
  showBytes: true,
  iconSize: 32,
};

export default DocumentThumbnailFile;
