import React, { Suspense } from 'react';
import { useLocation, useRoutes } from 'react-router';
import { Snackbar } from '@rmwc/snackbar';
import LayoutBase from 'layouts/Base';
import HeaderBar from 'components/HeaderBar';
import Loading from './components/Loading';
import theme from './utils/theme';
import Portal from './components/Portal';
import { Breadcrumbs } from './components/Router';
import HttpStatusCodeHandler from './components/HttpStatusCodeHandler';
import routes from './routes';

const MainAppRoutes = props => {
  const { location } = props;
  const router = useRoutes(routes, location);

  return router;
};

const MainApp = () => {
  const location = useLocation();

  const { prevLocation } = location.state || {};

  return (
    <LayoutBase header={HeaderBar} breadcrumbs={Breadcrumbs}>
      <>
        <HttpStatusCodeHandler>
          <MainAppRoutes location={prevLocation || location} />
        </HttpStatusCodeHandler>
        <Snackbar />
        <Portal />
      </>
    </LayoutBase>
  );
};

const App = () => {
  return (
    <>
      <Suspense fallback={<Loading color={theme.colors.secondary} />}>
        <MainApp />
      </Suspense>
    </>
  );
};

export default App;
