import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button } from '@rmwc/button';

const Buttons = styled.div`
  flex: none;
  display: flex;
`;

const ButtonStyled = styled(Button)`
  padding: 8px 24px;
  font-size: 1.125rem;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.bold};
  border-radius: 8px;
  &:not(:last-child) {
    margin-right: 16px;
  }
`;

const ButtonCancel = styled(ButtonStyled).attrs(props => ({
  type: 'button',
  outlined: true,
}))`
  &:not(:disabled) {
    color: ${props => props.theme.colors.black};
  }
`;

const ButtonSubmit = styled(ButtonStyled).attrs(props => ({
  type: props.submitType,
}))`
  background: linear-gradient(to right, #ff6b00 10.45%, #ffc24d 86.49%);

  &:not(:disabled) {
    color: ${props => props.theme.colors.white};
  }
`;

const FormButtons = props => {
  const { isSubmitDisabled, submitText, cancelText, onCancel, onSubmit } = props;

  return (
    <Buttons>
      {onCancel && <ButtonCancel onClick={onCancel}>{cancelText}</ButtonCancel>}
      {submitText && (
        <ButtonSubmit disabled={isSubmitDisabled} onClick={onSubmit}>
          {submitText}
        </ButtonSubmit>
      )}
    </Buttons>
  );
};

FormButtons.propTypes = {
  isSubmitDisabled: PropTypes.bool,
  submitText: PropTypes.string,
  cancelText: PropTypes.string,
  onCancel: PropTypes.func,
};

FormButtons.defaultProps = {
  submitText: 'Aceptar',
  cancelText: 'Cancelar',
};

export default FormButtons;
