import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';
import theme from 'utils/theme';
import IconLoader from 'components/IconLoader';

const getBackground = props => {
  return chroma(props.color)
    .luminance(props.luminance)
    .hex();
};

const Wrapper = styled.div`
  display: flex;
  margin-bottom: 16px;
  padding: 8px;
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.color};
  background: ${getBackground};
  border-radius: 4px;
`;

const ErrorIcon = styled.div`
  display: flex;
  align-self: baseline;
  margin-right: 8px;
  font-size: 1.5rem;
`;

/**
 * @TODO Usar components/Alert
 */
const FormErrorBox = props => {
  const { icon, color, luminance, style, children } = props;
  return (
    <Wrapper style={style} color={color} luminance={luminance}>
      <ErrorIcon>
        <IconLoader name={icon} />
      </ErrorIcon>
      <span>{children}</span>
    </Wrapper>
  );
};

FormErrorBox.propTypes = {
  children: PropTypes.any,
  color: PropTypes.string,
  luminance: PropTypes.number,
};

FormErrorBox.defaultProps = {
  icon: 'interface-alert-circle-icon.svg',
  color: theme.colors.error,
  luminance: 0.8,
};

export default FormErrorBox;
