import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  font-size: 16;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme.colors.secondary};
  padding: 5;
  margin-bottom: 16px;
`;

const WarningMessageBox = ({ data }) => {
  const { message } = data;

  return <Wrapper>{message}</Wrapper>;
};

export default WarningMessageBox;
