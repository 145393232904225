const waitForDomElementDefaultTargetNode = document.body;
const waitForDomElementDefaultOptions = { childList: true, subtree: true };

/**
 * Espera a que un elemento del DOM exista y lo devuelve
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver
 * @see https://stackoverflow.com/a/61511955
 *
 * @param {string} selector
 * @param {Object} options
 * @returns {Promise}
 */
const waitForDomElement = ({
  selector,
  targetNode = waitForDomElementDefaultTargetNode,
  options = waitForDomElementDefaultOptions,
}) => {
  return new Promise(resolve => {
    const element = document.querySelector(selector);
    if (element) return resolve(element);

    const observer = new MutationObserver((mutations, observer) => {
      const element = document.querySelector(selector);
      if (element) {
        resolve(element);
        observer.disconnect();
      }
    });

    observer.observe(targetNode, options);
  });
};

export { waitForDomElement };
