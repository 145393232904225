import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { FabLayer, FabStack } from 'components/Fab';
import IncidenceDetailHeader from './IncidenceDetailHeader';
import IncidenceDetailComments from './IncidenceDetailComments';
import IncidenceDetailCommentsInput from './IncidenceDetailCommentsInput';
import IncidenceDetailCard from './IncidenceDetailCard';
import IncidenceDetailCommentsButton from './IncidenceDetailCommentsButton';

const Content = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  @media (max-width: 650px) {
    flex-direction: column-reverse;
    padding-bottom: 64px;
  }
`;

const FabLayerStyled = styled(FabLayer)`
  position: absolute;
  right: 0;
  @media (max-width: 650px) {
    bottom: 0;
  }
`;

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-right: 32px;
  @media (max-width: 650px) {
    margin-right: 0px;
  }
`;

/**
 * 1. Para que parte del contendio no quede oculto por el Fab
 */
const Info = styled.div`
  flex: none;
  margin-bottom: 96px; /* 1 */
  width: 380px;
  @media (max-width: 650px) {
    width: 100%;
    margin-bottom: 24px;
  }
`;

const Header = styled.div`
  margin-bottom: 24px;
`;

const Name = styled.h2`
  margin: 0 0 16px 0;
  font-size: 24px;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Description = styled.p`
  margin: 0 0 24px 0;
  font-size: 18px;
  line-height: 1.2;
`;

const Comments = styled.div`
  flex: 1;
  border-top: 1px solid #dfdfdf;
  padding: 24px 16px;
  overflow: auto;
`;

const CommentsBar = styled.div`
  border-top: 1px solid #dfdfdf;
  padding: 16px;
  min-height: 65px;
`;

const CommentsBarComponent = props => {
  const { questionsForm, onStartClick, onCancelClick } = props;

  if (questionsForm?.status === 'started') {
    return (
      <IncidenceDetailCommentsButton
        danger
        label="Cancelar"
        icon="close-icon.svg"
        onClick={onCancelClick}
      />
    );
  }

  if (questionsForm?.status === 'unstarted') {
    return (
      <IncidenceDetailCommentsButton label="Empezar" icon="add-2-icon.svg" onClick={onStartClick} />
    );
  }

  return <IncidenceDetailCommentsInput {...props} />;
};

const IncidenceDetail = props => {
  const {
    data,
    fabActions,
    onFabActionClick,
    onAttachFile,
    onSendComment,
    onFileClick,
    onRemoveFile,
    onStartClick,
    onCancelClick,
    onAnswer,
  } = props;
  const { nameHumanized, description, reporter, notes, createdAt, updatedAt, questionsForm } = data;

  return (
    <>
      <Content>
        <Body>
          <Header>
            <IncidenceDetailHeader
              createdAt={createdAt}
              reporter={reporter}
              updatedAt={updatedAt}
            />
          </Header>
          <Name>{nameHumanized}</Name>
          <Description>{description}</Description>
          <Comments>
            <IncidenceDetailComments
              reporter={reporter}
              data={notes}
              onAnswer={onAnswer}
              onFileClick={onFileClick}
            />
          </Comments>
          <CommentsBar>
            <CommentsBarComponent
              questionsForm={questionsForm}
              onClick={onStartClick}
              onAttachFile={onAttachFile}
              onSendComment={onSendComment}
              onStartClick={onStartClick}
              onCancelClick={onCancelClick}
            />
          </CommentsBar>
        </Body>
        <Info>
          <IncidenceDetailCard data={data} onFileClick={onFileClick} onRemoveFile={onRemoveFile} />
        </Info>
        <FabLayerStyled>
          <FabStack actions={fabActions} onActionClick={onFabActionClick} />
        </FabLayerStyled>
      </Content>
    </>
  );
};

IncidenceDetail.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    id: PropTypes.string,
    incidenceType: PropTypes.shape({ name: PropTypes.string }),
    persona: PropTypes.shape({
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      photo: PropTypes.string,
    }),
    state: PropTypes.string,
  }),
  fabActions: PropTypes.arrayOf(
    PropTypes.shape({ type: PropTypes.string, icon: PropTypes.string, label: PropTypes.string }),
  ),
  onFabActionClick: PropTypes.func,
  onAttachFile: PropTypes.func,
  onSendComment: PropTypes.func,
  onFileClick: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

IncidenceDetail.defaultProps = {};

export default IncidenceDetail;
