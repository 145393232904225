import styled from 'styled-components';
import {
  getBorderColor,
  getBorderColorOnFocus,
  getBoxShadowColorOnFocus,
} from '../utils/fieldsStyle';

const Textarea = styled.textarea`
  appearance: none;
  outline: none;
  display: flex;
  width: 100%;
  height: 6rem;
  font-size: 1.125rem !important;
  font: inherit;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 0.5rem;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => getBorderColor(props)};
  border-radius: 0.25rem;
  transition: all 0.2s ease 0s;
  resize: none;

  &:focus {
    border-color: ${props => getBorderColorOnFocus(props)};
    box-shadow: ${props => getBoxShadowColorOnFocus(props)} 0px 0px 0px 3px;
  }

  &::placeholder {
    opacity: 0.6;
  }
`;

export default Textarea;
