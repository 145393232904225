import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ContentEmpty from '../ContentEmpty';
import DocumentItem from './DocumentItem';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(${props => props.itemSize}, 1fr));
  gap: 8px;
  width: 100%;
  overflow: auto;
`;

const DocumentsList = props => {
  const {
    data,
    itemSize,
    showBytes,
    onItemClick,
    onItemRemove,
    emptyIllustration,
    emptyMessage,
  } = props;

  if (data?.length === 0) {
    return <ContentEmpty illustration={emptyIllustration} message={emptyMessage} />;
  }

  return (
    <Wrapper itemSize={itemSize}>
      {data &&
        data.map(item => (
          <DocumentItem
            key={item._id}
            data={item}
            size={itemSize}
            showBytes={showBytes}
            onClick={onItemClick ? () => onItemClick({ data: item }) : null}
            onRemove={onItemRemove}
          />
        ))}
    </Wrapper>
  );
};

DocumentsList.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string.isRequired })),
  itemSize: PropTypes.string,
  showBytes: PropTypes.bool,
  onItemClick: PropTypes.func,
  onItemRemove: PropTypes.func,
};

DocumentsList.defaultProps = {
  itemSize: '150px',
  emptyIllustration: 'collecting_fjjl.svg',
  emptyMessage: 'No hay documentos',
};

export default DocumentsList;
