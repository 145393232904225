import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ButtonRaw } from 'components/Button';
import IconLoader from 'components/IconLoader';
import DocumentThumbnail from 'components/DocumentThumbnail';

/**
 * 1. Para que se aprecie el box-shadow de los documentos
 */
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px; /* 1 */
`;

const Document = styled.div`
  display: flex;
  position: relative;
  height: ${props => props.size};
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 2px 2px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
`;

const DocumentOverlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: transparent;
  transition: background 0.3s ease;
  pointer-events: none;

  ${Document}:hover & {
    background: rgba(255, 255, 255, 0.5);
  }
`;

const RemoveButton = styled(ButtonRaw)`
  position: absolute;
  bottom: 4px;
  right: 4px;
  font-size: 20px;
  color: #f44336;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;

  ${Document}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const DocumentItem = props => {
  const { data, size, showBytes, onClick, onRemove } = props;

  const handleRemove = () => {
    onRemove && onRemove(data);
  };

  return (
    <Wrapper>
      <Document size={size}>
        <DocumentThumbnail
          style={{ width: '100%' }}
          data={data}
          showBytes={showBytes}
          onClick={onClick}
        />
        <DocumentOverlay />
        {onRemove && (
          <RemoveButton onClick={handleRemove}>
            <IconLoader name="bin-icon.svg" />
          </RemoveButton>
        )}
      </Document>
    </Wrapper>
  );
};

DocumentItem.propTypes = {
  data: PropTypes.shape({ _id: PropTypes.string }),
  size: PropTypes.string,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
};

export default DocumentItem;
