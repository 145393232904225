import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { ButtonRaw } from 'components/Button';
import IconLoader from 'components/IconLoader';
import useOnClickOutside from 'hooks/useOnClickOutside';

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-left: auto;
  width: 56px;
`;

const Button = styled(ButtonRaw)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 56px;
  font-size: 28px;
  background: ${props => props.theme.colors.success};
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
  border-radius: 50%;
  &:hover {
    background: ${props => chroma(props.theme.colors.success).darken(0.2)};
  }
  z-index: 1;
`;

const Stack = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const GAP = 72;
const getStackButtonGap = props => {
  const { isOpen, index } = props;
  if (!isOpen) return 0;
  return `-${GAP * (index + 1)}px`;
};

const DELAY = 0.05;
const getStackButtonDelay = props => {
  const { index } = props;
  return `${DELAY * (index + 1)}s`;
};

const StackButton = styled(ButtonRaw)`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 56px;
  height: 56px;
  font-size: 28px;
  background: ${props => props.color || props.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
  border-radius: 50%;
  transform: translateY(${props => getStackButtonGap(props)})
    scale(${props => (props.isOpen ? 1 : 0)});
  transition: all 0.2s;
  transition-delay: ${props => getStackButtonDelay(props)};
  &:hover {
    background: ${props => chroma(props.color || props.theme.colors.white).darken(0.2)};
  }
`;

const StackButtonLabel = styled.div`
  display: ${props => (props.isOpen ? 'flex' : 'none')};
  align-items: 'center';
  justify-content: 'center';
  position: absolute;
  left: 0;
  padding: 8px 16px;
  font-size: ${props => props.theme.fontSizes.base};
  white-space: nowrap;
  background: ${props => props.color || props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.14), 0 4px 8px rgba(0, 0, 0, 0.28);
  transform: translateX(calc(-100% - 16px));
  transition: all 0.2s;
  &:hover {
    background: ${props => chroma(props.color || props.theme.colors.white).darken(0.2)};
  }
`;

const FabStack = props => {
  const { openIcon, closedIcon, actions, onActionClick } = props;
  const [isOpen, setIsOpen] = useState(false);
  const contentRef = useRef();
  useOnClickOutside(contentRef, () => setIsOpen(false));

  const hasActions = actions?.length > 0;
  const stackActions = hasActions && [...actions].reverse();
  const icon = isOpen ? openIcon : closedIcon;

  const handleButtonClick = () => setIsOpen(prev => !prev);

  const handleActionClick = action => {
    setIsOpen(false);
    onActionClick(action);
  };

  if (!hasActions) return null;

  return (
    <Content ref={contentRef}>
      <Button onClick={handleButtonClick} className="tour-fab">
        <IconLoader name={icon} />
      </Button>
      <Stack>
        {stackActions.map((action, index) => {
          const { color, type, label, icon } = action;
          return (
            <StackButton
              key={type}
              index={index}
              color={color}
              isOpen={isOpen}
              onClick={() => handleActionClick(action)}
            >
              <IconLoader name={icon} />
              <StackButtonLabel isOpen={isOpen} color={color}>
                {label}
              </StackButtonLabel>
            </StackButton>
          );
        })}
      </Stack>
    </Content>
  );
};

FabStack.propTypes = {
  openIcon: PropTypes.string,
  closedIcon: PropTypes.string,
};

FabStack.defaultProps = {
  openIcon: 'close-icon.svg',
  closedIcon: 'add-2-icon.svg',
};

export default FabStack;
