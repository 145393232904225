import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { STATUS_CODES } from 'utils/http';
import { httpStatusErrorObservable } from 'services/http';
import { NotAuthorized, PaymentRequired, NotFound } from 'pages/HttpStatuses';

/**
 * Controla los httpStatusCode que se guarden en el location state
 * @requires services/http/httpStatusErrorObservable
 * @see https://itnext.io/centralizing-api-error-handling-in-react-apps-810b2be1d39d
 * @see https://developer.mozilla.org/en-US/docs/Web/API/History/state
 */
const HttpStatusCodeHandler = props => {
  const { children } = props;

  const location = useLocation();
  const navigate = useNavigate();

  const { httpStatusCode } = location?.state || {};

  // Guarda el error de HTTP (httpStatusCode) en el location state
  useEffect(() => {
    const setHttpStatusErrorOnLocation = ({ httpStatusCode, httpStatusData }) => {
      const currentState = location.state;
      const nextState = { httpStatusCode, httpStatusData };
      navigate(location.pathname, {
        replace: true,
        state: { ...currentState, ...nextState },
      });
    };

    httpStatusErrorObservable.subscribe(setHttpStatusErrorOnLocation);
    return () => httpStatusErrorObservable.unsubscribe(setHttpStatusErrorOnLocation);
  }, [location.pathname, location.state, navigate]);

  if (httpStatusCode === STATUS_CODES.FORBIDDEN) {
    return <NotAuthorized />;
  }

  if (httpStatusCode === STATUS_CODES.PAYMENT_REQUIRED) {
    return <PaymentRequired />;
  }

  if (httpStatusCode === STATUS_CODES.NOT_FOUND) {
    return <NotFound />;
  }

  return children;
};

HttpStatusCodeHandler.propTypes = {
  children: PropTypes.arrayOf(PropTypes.element).isRequired,
};

export default HttpStatusCodeHandler;
