import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconLoader from 'components/IconLoader';

const Logo = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${props => props.size}px;
  height: ${props => props.size}px;
  border: 1px solid ${props => props.theme.colors.lavender};
  border-radius: 50%;
  overflow: hidden;
`;

const Image = styled.img`
  flex: none;
  object-fit: contain;
  width: 100%;
  height: 100%;
`;

const Placeholder = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: ${props => props.fontSize}px;
  color: ${props => props.theme.colors.trout};
  background-color: ${props => props.theme.colors.lavender};
`;

const LogoBreadcrumb = props => {
  const { label, url, size, icon, iconSize } = props;

  return (
    <>
      <Logo size={size}>
        {url ? (
          <Image src={url} />
        ) : (
          <Placeholder fontSize={iconSize}>
            <IconLoader name={icon} />
          </Placeholder>
        )}
      </Logo>
      <span>{label}</span>
    </>
  );
};

LogoBreadcrumb.propTypes = {
  label: PropTypes.string,
  url: PropTypes.string,
  size: PropTypes.number,
  icon: PropTypes.string,
  iconSize: PropTypes.number,
};

LogoBreadcrumb.defaultProps = {
  size: 24,
  icon: 'file-landscape-image.svg',
  iconSize: 16,
};

export default LogoBreadcrumb;
