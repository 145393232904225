import styled from 'styled-components';

const H1 = styled.h1`
  margin: 0;
  font-size: ${props => props.theme.fontSizes['3xl']};
  line-height: 1.2;
`;

const H2 = styled.h2`
  margin: 0;
  font-size: ${props => props.theme.fontSizes['2xl']};
  line-height: 1.2;
`;

const H3 = styled.h3`
  margin: 0;
  font-size: ${props => props.theme.fontSizes['lg']};
  line-height: 1.2;
`;

export { H1, H2, H3 };
