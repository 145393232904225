import React from 'react';
import PropTypes from 'prop-types';
import ChatButton from 'components/ChatButton';

const IncidenceDetailCommentsButton = props => {
  const { danger, icon, label, onClick } = props;

  return <ChatButton danger={danger} label={label} icon={icon} onClick={onClick} />;
};

IncidenceDetailCommentsButton.propTypes = {
  danger: PropTypes.bool,
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

IncidenceDetailCommentsButton.defaultProps = {
  danger: false,
};

export default IncidenceDetailCommentsButton;
