import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import PortalOverlay from '../PortalOverlay';

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 24px;
  background: ${props => props.theme.colors.white};
  overflow: auto;
`;

const FormLayer = props => {
  const { children } = props;

  return (
    <PortalOverlay container={document.getElementById('content')}>
      <Content>{children}</Content>
    </PortalOverlay>
  );
};

FormLayer.propTypes = {
  children: PropTypes.element.isRequired,
};

export default FormLayer;
