import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from 'components/Page';
import Title from 'components/Title';
import useMutationSearchComplaint from 'hooks/useMutationSearchComplaint';
import { getErrorDialog } from 'utils/dialog';
import Loading from 'components/Loading';
import Dialog from 'components/Dialog';
import { useUserDispatch } from '../../context/UserContext';
import SearchForm from './SearchForm';

const Content = styled.div`
  margin: 0 auto;
  width: 40%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  @media (max-width: 650px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const Search = () => {
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(null);
  const navigate = useNavigate();
  const dispatch = useUserDispatch();
  const [searchParams] = useSearchParams();
  const id = searchParams.get('id');
  const { mutate: search } = useMutationSearchComplaint();

  const doMutation = ({ mutationFn, data, onSuccess }) => {
    setLoading(true);
    mutationFn(data, {
      onSuccess,
      onError: error => {
        setDialog(getErrorDialog({ message: error.message, onAccept: () => setDialog(null) }));
      },
      onSettled: () => setLoading(false),
    });
  };

  const handleSubmit = ({ data }) => {
    const mutationData = { ...data, company: id };
    doMutation({
      mutationFn: search,
      data: mutationData,
      onSuccess: response => handleSuccessSearch({ ...response, ...mutationData }),
    });
  };

  const handleSuccessSearch = data => {
    dispatch({ type: 'set.complaint', item: { ...data } });
    navigate(`complaint?${searchParams.toString()}`);
  };

  return (
    <Page>
      {loading && <Loading />}
      {dialog && <Dialog {...dialog} />}
      <Title>Consultar</Title>
      <Content>
        <SearchForm onSubmit={handleSubmit} />
      </Content>
    </Page>
  );
};

export default Search;
