import React, { useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { DateRangePicker, isInclusivelyAfterDay } from 'react-dates';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { getBorderColor } from 'components/Form/utils/fieldsStyle';

const parseDateToString = date => moment(date).format('YYYY-MM-DD');
const parseStringToMoment = value => moment(value);

const getDefaultIsOutsideRangeFn = props => {
  const { allowPastDates, minDate, maxDate } = props;
  return day => {
    if (minDate && day.isBefore(minDate, 'day')) return true;
    if (maxDate && day.isAfter(maxDate, 'day')) return true;
    if (allowPastDates) return false;
    return !isInclusivelyAfterDay(day, moment());
  };
};

/**
 * Wrapper para dale estilo a DateRangePicker
 * @see https://github.com/airbnb/react-dates/issues/1030
 */
const DateRangeStyled = styled.div`
  .DateRangePickerInput {
    border-color: ${props => getBorderColor(props)};
    border-radius: 0.25rem;
  }

  .DateInput:first-child,
  .DateInput:first-child .DateInput_input {
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
  .DateInput:last-child,
  .DateInput:last-child .DateInput_input {
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .DateInput_input {
    padding: 9px 16px 7px 16px;
    font-size: 1.125rem;
    line-height: 1.2;

    &__focused {
      border-color: ${props => props.theme.colors.secondary};
    }
  }
`;

const DateRange = props => {
  const { control, rules, id, name, className, hasError, style, ...restProps } = props;
  const { allowPastDates, minDate, maxDate, isOutsideRange, ...componentProps } = restProps;
  const [focusedInput, setFocusedInput] = useState(null);

  const startDateId = `${id}:startDate`;
  const endDateId = `${id}:endDate`;

  const isOutsideRangeFn = isOutsideRange ? isOutsideRange : getDefaultIsOutsideRangeFn(props);

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { value, onChange, onBlur } }) => (
        <DateRangeStyled>
          <DateRangePicker
            {...componentProps}
            displayFormat="DD/MM/YYYY"
            startDate={value.beginDate && parseStringToMoment(value.beginDate)}
            startDateId={startDateId}
            startDatePlaceholderText="Inicio"
            endDate={value.endDate && parseStringToMoment(value.endDate)}
            endDateId={endDateId}
            endDatePlaceholderText="Fin"
            minDate={minDate && parseStringToMoment(minDate)}
            maxDate={maxDate && parseStringToMoment(maxDate)}
            isOutsideRange={isOutsideRangeFn}
            focusedInput={focusedInput}
            onFocusChange={setFocusedInput}
            onDatesChange={({ startDate, endDate }) =>
              onChange({
                beginDate: startDate && parseDateToString(startDate),
                endDate: endDate && parseDateToString(endDate),
              })
            }
          />
        </DateRangeStyled>
      )}
    />
  );
};

DateRange.propTypes = {
  hideKeyboardShortcutsPanel: PropTypes.bool,
  allowPastDates: PropTypes.bool,
  minimumNights: PropTypes.number,
};

DateRange.defaultProps = {
  hideKeyboardShortcutsPanel: true,
  allowPastDates: true,
  minimumNights: 0,
};

export default DateRange;
