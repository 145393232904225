import React from 'react';
import Form from 'components/Form';
import { goBack } from 'utils/router';

const AddForm = props => {
  const { fields, onSubmit } = props;

  return <Form fields={fields} onSubmit={onSubmit} onCancel={goBack} />;
};

AddForm.defaultProps = {
  fields: [
    {
      type: 'text',
      name: 'reporter',
      label: '¿Quién eres?',
      hint: 'No rellenes este campo si quieres que la denuncia sea anónima',
    },
    {
      type: 'textarea',
      name: 'description',
      label: 'Descripción',
      required: true,
      placeholder: 'Introduzca una descripción',
    },
    {
      type: 'dropzone',
      name: 'files',
      label: 'Documentos',
    },
  ],
};

export default AddForm;
