import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';
import IconLoader from 'components/IconLoader';

const IconBreadcrumb = props => {
  const { label, icon, size } = props;

  return (
    <>
      <Icon size={size}>
        <IconLoader name={icon} />
      </Icon>
      <span>{label}</span>
    </>
  );
};

IconBreadcrumb.propTypes = {
  label: PropTypes.string.isRequired,
  icon: PropTypes.string,
  size: PropTypes.number,
};

IconBreadcrumb.defaultProps = {
  size: 18,
};

export default IconBreadcrumb;
