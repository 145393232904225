import { useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getDocumentLink } from 'services/documents';

const useOpenDocument = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const openDocument = useCallback(
    ({ _id }) => {
      const to = getDocumentLink({ _id });
      const state = { prevLocation: JSON.parse(JSON.stringify(location)) };
      navigate(to, { state });
    },
    [location, navigate],
  );

  return { openDocument };
};

export default useOpenDocument;
