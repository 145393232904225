import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import chroma from 'chroma-js';
import { ButtonRaw } from '../Button';
import IconLoader from '../IconLoader';
import theme from '../../utils/theme';

const Button = styled(ButtonRaw)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  width: 56px;
  height: 56px;
  font-size: 28px;
  background: ${props => props.color};
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 4px, rgba(0, 0, 0, 0.28) 0px 4px 8px;
  border-radius: 50%;
  &:hover {
    background: ${props => chroma(props.color).darken(0.2)};
  }
  z-index: 1;
`;

const Fab = props => {
  const { icon, color, onClick } = props;

  return (
    <Button onClick={onClick} color={color}>
      <IconLoader name={icon} />
    </Button>
  );
};

Fab.propTypes = {
  icon: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

Fab.defaultProps = {
  icon: 'add-2-icon.svg',
  color: theme.colors.success,
};

export default Fab;
