import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { parseFileToThumbnail } from 'utils/files';
import {
  getBorderColor,
  getBorderColorOnFocus,
  getBoxShadowColorOnFocus,
} from 'components/Form/utils/fieldsStyle';
import DocumentThumbnail from 'components/DocumentThumbnail';

// @see: https://react-dropzone.js.org/
// @see: https://github.com/react-hook-form/react-hook-form/discussions/2146

const isImageFile = file => file.type?.includes('image');

const getBorderColorWithDragEvents = props => {
  const { isDragAccept, isDragReject } = props;
  return getBorderColor({ isSuccess: isDragAccept, hasError: isDragReject });
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  min-height: 40px;
  color: #bdbdbd;
  background-color: #fafafa;
  border-width: 2px;
  border-style: dashed;
  border-color: ${props => getBorderColorWithDragEvents(props)};
  border-radius: 0.25rem;
  outline: none;
  padding: 4px 0 0 4px;

  &:focus {
    border-color: ${props => getBorderColorOnFocus(props)};
    box-shadow: ${props => getBoxShadowColorOnFocus(props)} 0px 0px 0px 3px;
  }
`;

const Placeholder = styled.p`
  text-align: center;
  flex: 1;
  color: grey;
  font-size: 18px;
`;

const FileBlock = styled.div`
  width: 150px;
  height: 150px;
  display: flex;
  margin-right: 4px;
  margin-bottom: 4px;
`;

const FilesPreview = props => {
  const { files } = props;
  const hasFiles = files?.length > 0;

  if (!hasFiles) {
    return <Placeholder>{'Arrastre ficheros aquí, o pulse para seleccionarlos'}</Placeholder>;
  }

  return files.map((file, index) => (
    <FileBlock key={index}>
      <DocumentThumbnail data={parseFileToThumbnail({ file })} onClick={null} />
    </FileBlock>
  ));
};

/**
 * @TODO Mover la lógica del preview al componente FilesPreview
 * Crea un objectUrl para visualizar la imagen.
 * Posteriormente se revierte el objectUrl para liberar la memoria.
 * @see https://developer.mozilla.org/en-US/docs/Web/API/URL/createObjectURL
 * @see https://alphahydrae.com/2021/02/how-to-display-an-image-protected-by-header-based-authentication/
 */
const Dropzone = props => {
  const { accept, onChange } = props;

  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept,
    onDrop: acceptedFiles => {
      for (let file of acceptedFiles) {
        if (isImageFile(file)) {
          file.preview = URL.createObjectURL(file);
        }
      }
      onChange(acceptedFiles);
    },
  });

  useEffect(() => {
    return () => acceptedFiles.forEach(file => URL.revokeObjectURL(file.preview));
  }, [acceptedFiles]);

  return (
    <Container {...getRootProps()}>
      <input {...getInputProps()} />
      <FilesPreview files={acceptedFiles} />
    </Container>
  );
};

const File = props => {
  const { control, rules, id, name, className, hasError, style, ...componentProps } = props;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange } }) => <Dropzone {...componentProps} onChange={onChange} />}
    />
  );
};

File.propTypes = {
  accept: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
};

export default File;
