import styled from 'styled-components';

// https://codepen.io/avstorm/pen/jOEpBLW
// https://codepen.io/mburnette/pen/LxNxNg

const Switch = styled.input.attrs(props => ({
  type: 'checkbox',
}))`
  appearance: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  position: relative;
  margin: 0;
  width: 64px;
  height: 40px;
  background: #a4a4a4;
  border-radius: 24px;
  transition: all 0.2s ease;
  cursor: pointer;

  &::after {
    content: '';
    position: absolute;
    top: 4px;
    left: 4px;
    width: 32px;
    height: 32px;
    background: ${props => props.theme.colors.white};
    border-radius: 50%;
    box-shadow: 0 1px 2px rgba(44, 44, 44, 0.2);
    transition: all 0.2s cubic-bezier(0.5, 0.1, 0.75, 1.35);
  }
  &:checked {
    background: ${props => props.theme.colors.success};

    &::after {
      transform: translatex(24px);
    }
  }
`;

export default Switch;
