import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import styled from 'styled-components';
import { getFieldValidationRules } from './utils/validation';
import FormLabel from './FormLabel';
import FormHint from './FormHint';
import FormField from './FormField';
import FormErrorMessage from './FormErrorMessage';

const Wrapper = styled.div`
  margin-bottom: 1.5rem;
  ${props => props.styles}
`;

const FormControl = props => {
  const { field, defaultValue, register, control, errors } = props;
  const { label, hint, ...fieldProps } = field;

  const id = field.name;
  const error = get(errors, field.name);
  const hasError = Boolean(error);

  const validationRules = getFieldValidationRules({ field });

  if (field.type === 'label') {
    return (
      <Wrapper styles={field.styles}>
        <FormLabel fontSize={field.styles?.fontSize}>{label}</FormLabel>
        {hint && <FormHint>{hint}</FormHint>}
      </Wrapper>
    );
  }

  if (field.type === 'hidden') {
    return (
      <FormField
        {...fieldProps}
        id={id}
        defaultValue={defaultValue}
        register={register}
        control={control}
      />
    );
  }

  return (
    <Wrapper styles={field.styles}>
      {label && (
        <FormLabel htmlFor={id}>
          {label} {fieldProps?.required && <span>*</span>}
        </FormLabel>
      )}
      {hint && <FormHint>{hint}</FormHint>}
      <FormField
        {...fieldProps}
        id={id}
        defaultValue={defaultValue}
        register={register}
        control={control}
        validationRules={validationRules}
        errors={errors}
        hasError={hasError}
      />
      {hasError && <FormErrorMessage>{error.message}</FormErrorMessage>}
    </Wrapper>
  );
};

FormControl.propTypes = {
  field: PropTypes.shape({ type: PropTypes.string, name: PropTypes.string }),
  defaultValue: PropTypes.any,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

export default FormControl;
