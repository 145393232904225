import React from 'react';

const Icon = ({ children, size, ...rest }) => {
  const childrenWithProps = React.cloneElement(children, { width: size, height: size, ...rest });

  return childrenWithProps;
};

Icon.defaultProps = {
  size: '1em',
};

export default Icon;
