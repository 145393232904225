import styled from 'styled-components';

const FormFieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  padding: 16px;
  background: #f9f9f9;
  border: 1px solid #f0f0f0;
  border-radius: 8px;
`;

export default FormFieldset;
