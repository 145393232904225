import styled from 'styled-components';

const Page = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 24px;
`;

export default Page;
