import moment from 'moment';
import { client } from 'clients/api';
import { parseRequestData } from 'utils/requests';
import theme from '../utils/theme';
import { parseMessages } from './messages';

/**
 * Crea una denuncia
 * @param   {Object}  args
 * @param   {string}  args.reporter
 * @param   {string}  args.description
 * @param   {string}  args.company
 * @returns {Promise}
 */
const createComplaint = ({ reporter, description, company, files }) => {
  const request = { url: '/issues/events', method: 'POST' };
  const values = { reporter, description, files, company, event: 'complaint.add' };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Busca una denuncia
 * @param   {Object}  args
 * @param   {string}  args.company
 * @param   {string}  args.password
 * @param   {string}  args.id
 * @returns {Promise}
 */
const searchComplaint = ({ company, password, id }) => {
  const request = { url: '/issues/events', method: 'POST', event: 'complaint.get', company };
  const data = { password, id };
  return client({ request, data }).then(parseComplaint);
};

const parseComplaint = complaint => {
  const { createdAt, notes } = complaint;

  const dateFormat = 'DD/MM/YYYY HH:mm';
  const createdAtParsed = createdAt && moment(createdAt).format(dateFormat);
  const parsedNotes = parseMessages(notes);

  return { ...complaint, createdAtParsed, notes: parsedNotes };
};

/**
 * Crea un comentario en una denuncia
 * @param   {string}  arg.id
 * @param   {string}  arg.password
 * @param   {string}  arg.company
 * @param   {string}  arg.comment
 * @returns {Promise}
 */
const createComplaintComment = ({ id, password, company, comment }) => {
  const request = { url: '/issues/events', method: 'POST', event: 'complaint.note.add', company };
  const data = { id, password, note: comment };
  return client({ request, data });
};

/**
 * Crea un comentario con fichero en una denuncia
 * @param   {string}      arg.id
 * @param   {string}      arg.password
 * @param   {string}      arg.company
 * @param   {FileList}    arg.files
 * @returns {Promise}
 */
const createComplaintCommentFile = ({ id, password, company, files }) => {
  const request = { url: '/issues/events', method: 'POST' };
  const values = { id, password, company, files, event: 'complaint.note.add' };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Elimina una denuncia
 * @param   {Object}  args
 * @param   {string}  arg._id
 * @param   {string}  arg.id
 * @param   {string}  arg.password
 * @param   {string}  arg.company
 * @returns {Promise}
 */
const removeComplaint = ({ _id, id, password, company }) => {
  const request = { url: '/issues/events', method: 'POST', event: 'complaint.remove', company };
  const data = { _id, id, password };
  return client({ request, data });
};

/**
 * Elimina un documento de una denuncia
 * @param   {Object} args
 * @param   {string} _id
 * @param   {string} arg.id
 * @param   {string} arg.password
 * @param   {string} arg.company
 * @param   {string} fileId
 * @returns {Promise}
 */
const removeComplaintFile = ({ _id, id, password, company, fileId }) => {
  const request = { url: '/issues/events', method: 'POST', event: 'complaint.doc.remove', company };
  const data = { _id, id, password, docId: fileId };
  return client({ request, data });
};

/**
 * Sube un documento a una denuncia
 * @param   {Object}  args
 * @param   {string}  _id
 * @param   {string}  arg.id
 * @param   {string}  arg.password
 * @param   {string}  arg.company
 * @param   {files}   FileList
 * @returns {Promise}
 */
const uploadComplaintFile = ({ _id, id, password, company, files }) => {
  const request = { url: '/issues/events', method: 'POST' };
  const values = { _id, id, password, company, files, event: 'complaint.docs.add' };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

const REQUEST_STATES = {
  ACCEPTED: 'accepted',
  OPEN: 'open',
  REJECTED: 'rejected',
  INPROCESS: 'inprocess',
};

const REQUEST_STATE_LABELS = {
  [REQUEST_STATES.ACCEPTED]: 'Aceptada',
  [REQUEST_STATES.OPEN]: 'Abierta',
  [REQUEST_STATES.REJECTED]: 'Rechazada',
  [REQUEST_STATES.INPROCESS]: 'En revisión',
};

const REQUEST_STATE_COLORS = {
  [REQUEST_STATES.ACCEPTED]: theme.colors.success,
  [REQUEST_STATES.OPEN]: theme.colors.cadetBlue,
  [REQUEST_STATES.REJECTED]: theme.colors.error,
  [REQUEST_STATES.INPROCESS]: theme.colors.leaves,
};

export {
  createComplaint,
  searchComplaint,
  createComplaintComment,
  createComplaintCommentFile,
  removeComplaint,
  removeComplaintFile,
  uploadComplaintFile,
  REQUEST_STATE_LABELS,
  REQUEST_STATE_COLORS,
};
