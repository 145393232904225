import React from 'react';
import PropTypes from 'prop-types';
import ChatInput from 'components/ChatInput';

const IncidenceDetailCommentsInput = props => {
  const { placeholder, onAttachFile, onSendComment } = props;

  return (
    <ChatInput
      placeholder={placeholder}
      onAttachFile={onAttachFile}
      onSendMessage={onSendComment}
    />
  );
};

IncidenceDetailCommentsInput.propTypes = {
  onAttachFile: PropTypes.func,
  onSendComment: PropTypes.func,
};

IncidenceDetailCommentsInput.defaultProps = {
  placeholder: 'Escribe un comentario...',
};

export default IncidenceDetailCommentsInput;
