import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IllustrationLoader from 'components/IllustrationLoader';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: ${props => props.gap}px;
  text-align: center;
  width: 100%;
  height: 100%;
  padding: 32px 16px;
`;

const Illustration = styled.div`
  flex: none;
  width: auto;
  height: ${props => props.height}px;
`;

const Message = styled.p`
  margin: 0;
  line-height: 1.2;
  color: ${props => props.theme.colors.cadetBlue};
`;

const ContentEmpty = props => {
  const { className, illustration, illustrationHeight, message, gap } = props;

  return (
    <Wrapper className={className} gap={gap}>
      <Illustration height={illustrationHeight}>
        <IllustrationLoader name={illustration} />
      </Illustration>
      {message && <Message>{message}</Message>}
    </Wrapper>
  );
};

ContentEmpty.propTypes = {
  className: PropTypes.string,
  illustration: PropTypes.string.isRequired,
  illustrationHeight: PropTypes.number,
  message: PropTypes.string,
  gap: PropTypes.number,
};

ContentEmpty.defaultProps = {
  illustrationHeight: 100,
  gap: 48,
};

export default ContentEmpty;
