import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { getReadableColor } from 'utils/colors';

const getColors = props => {
  const background = chroma(props.color)
    .luminance(props.luminance)
    .hex();
  const color = getReadableColor({ color: background });

  return css`
    background: ${background};
    color: ${color};
  `;
};

const Badge = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 16px;
  font-size: 0.875rem;
  font-weight: ${props => props.theme.fontWeights.bold};
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  ${props => getColors(props)};
  text-transform: uppercase;
`;

Badge.propTypes = {
  color: PropTypes.string.isRequired,
  luminance: PropTypes.number,
};

Badge.defaultProps = {
  luminance: 0.8,
};

export default Badge;
