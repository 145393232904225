import styled from 'styled-components';

const FormErrorMessage = styled.div`
  margin-top: 0.5rem;
  font-size: 0.875rem;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.light};
  color: ${props => props.theme.colors.error};
`;

export default FormErrorMessage;
