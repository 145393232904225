import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Page } from 'components/Page';
import Title from 'components/Title';
import Loading from 'components/Loading';
import { useUserState } from '../../context/UserContext';
import useQueryComplaint from '../../hooks/useQueryComplaint';
import IncidenceDetail from '../../components/IncidenceDetail';
import { getConfirmDialog, getErrorDialog } from '../../utils/dialog';
import theme from '../../utils/theme';
import useMutationRemoveComplaint from '../../hooks/useMutationRemoveComplaint';
import useMutationRemoveComplaintFile from '../../hooks/useMutationRemoveComplaintFile';
import useMutationUploadComplaintFile from '../../hooks/useMutationUploadComplaintFile';
import useMutationCreateComplaintComment from '../../hooks/useMutationCreateComplaintComment';
import useMutationCreateComplaintCommentFile from '../../hooks/useMutationCreateComplaintCommentFile';
import { goBack } from '../../utils/router';
import Dialog from '../../components/Dialog';
import { fetchDocumentBlob } from '../../services/documents';
import { downloadBlob } from '../../utils/files';

const OPEN_ACTIONS = ['uploadFile', 'close', 'remove'];
const CLOSED_ACTIONS = [];
const ACCEPTED_ACTIONS = [];
const REJECTED_ACTIONS = [];

const filterFabActions = ({ data, fabActions: actions }) => {
  const { status } = data;
  const state = status?.[0].state;
  if (state === 'closed') return actions.filter(a => CLOSED_ACTIONS.includes(a.type));
  if (state === 'accepted') return actions.filter(a => ACCEPTED_ACTIONS.includes(a.type));
  if (state === 'rejected') return actions.filter(a => REJECTED_ACTIONS.includes(a.type));
  if (state === 'open') return actions.filter(a => OPEN_ACTIONS.includes(a.type));
  return actions;
};

const Content = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 650px) {
    width: 100%;
    align-items: flex-start;
  }
`;

const Complaint = props => {
  const { entityName, entityArticle, fabActions } = props;

  const state = useUserState();
  const { id, password, company } = state;
  const { data, isLoading, isIdle } = useQueryComplaint({ id, password, company });

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [dialog, setDialog] = useState(null);
  const fileInputRef = useRef();

  const { mutate: remove } = useMutationRemoveComplaint();
  const { mutate: uploadFile } = useMutationUploadComplaintFile();
  const { mutate: removeFile } = useMutationRemoveComplaintFile();
  const { mutate: createComment } = useMutationCreateComplaintComment();
  const { mutate: createCommentFile } = useMutationCreateComplaintCommentFile();

  const doMutation = ({ mutationFn, data, onSuccess }) => {
    setLoading(true);
    mutationFn(data, {
      onSuccess,
      onError: error => {
        setDialog(getErrorDialog({ message: error.message, onAccept: () => setDialog(null) }));
      },
      onSettled: () => setLoading(false),
    });
  };

  const handleRemove = data => {
    const { id } = data;
    const dialog = getConfirmDialog({
      title: `Eliminar ${entityName} #${id}`,
      message: `¿Está seguro que desea eliminar ${entityArticle} ${entityName}? Esta operación no se podrá deshacer.`,
      onAccept: () => {
        setDialog(null);
        doRemove(data);
      },
      onCancel: () => setDialog(null),
    });
    setDialog(dialog);
  };

  const doRemove = data => {
    const { _id } = data;
    const mutationData = { _id, id, password, company };
    doMutation({ mutationFn: remove, data: mutationData, onSuccess: goBack });
  };

  const handleUploadFile = () => {
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const doUploadFile = event => {
    const { files } = event.target;
    if (!files) return;

    const mutationData = { id, password, company, files };
    doMutation({ mutationFn: uploadFile, data: mutationData });
  };

  const handleFabActionClick = ({ action, data }) => {
    switch (action.type) {
      case 'remove':
        return handleRemove(data);
      case 'update':
        return navigate(`update`);
      case 'uploadFile':
        return handleUploadFile();
      default:
        break;
    }
  };

  const handleCreateCommentFile = event => {
    const { files } = event.target;
    if (files) {
      const mutationData = { id, password, company, files };
      doMutation({ mutationFn: createCommentFile, data: mutationData });
    }
  };

  const handleCreateComment = comment => {
    const mutationData = { id, password, company, comment };
    doMutation({ mutationFn: createComment, data: mutationData });
  };

  const handleRemoveFile = data => {
    const { originalname: name } = data;
    const dialog = getConfirmDialog({
      title: `Eliminar documento ${name}`,
      message: '¿Está seguro que desea eliminar el documento? Esta operación no se podrá deshacer.',
      acceptText: 'Eliminar',
      onAccept: () => {
        setDialog(null);
        doRemoveFile(data);
      },
      onCancel: () => setDialog(null),
    });
    setDialog(dialog);
  };

  const doRemoveFile = data => {
    const mutationData = { id, password, company, fileId: data._id };
    doMutation({ mutationFn: removeFile, data: mutationData });
  };

  const handleFileClick = ({ data } = {}) => {
    const { url, originalname } = data;
    if (!url) return null;

    fetchDocumentBlob({ url }).then(blob => downloadBlob({ data: blob, filename: originalname }));
  };

  if (isLoading || isIdle) return <Loading />;

  return (
    <Page>
      {loading && <Loading />}
      {dialog && <Dialog {...dialog} />}

      <input ref={fileInputRef} type="file" hidden multiple onChange={doUploadFile} />

      <Title>Denuncia #{id}</Title>
      <Content>
        {data && (
          <IncidenceDetail
            data={data}
            fabActions={filterFabActions({ data, fabActions })}
            onFabActionClick={action => handleFabActionClick({ action, data })}
            onAttachFile={handleCreateCommentFile}
            onSendComment={handleCreateComment}
            onFileClick={handleFileClick}
            onRemoveFile={handleRemoveFile}
          />
        )}
      </Content>
    </Page>
  );
};

Complaint.defaultProps = {
  entityName: 'denuncia',
  entityArticle: 'esta',
  fabActions: [
    { type: 'remove', icon: 'bin-icon.svg', label: 'Eliminar', color: theme.colors.error },
    { type: 'update', icon: 'pencil-2-icon.svg', label: 'Editar', color: theme.colors.warning },
    { type: 'uploadFile', icon: 'file-new-2-icon.svg', label: 'Subir documento' },
  ],
};

export default Complaint;
