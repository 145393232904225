import { useQuery } from 'react-query';
import { searchComplaint } from 'services/complaints';

const KEY = 'complaint';

const useQueryComplaint = ({ id, password, company }) => {
  const queryKey = [KEY, { id, password, company }];
  const queryInfo = useQuery({
    queryKey,
    queryFn: () => searchComplaint({ id, password, company }),
    enabled: Boolean(id) && Boolean(password) && Boolean(company),
  });

  return queryInfo;
};

export default useQueryComplaint;
