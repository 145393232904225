import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import useOpenDocument from 'hooks/useOpenDocument';
import DocumentThumbnailFile from './DocumentThumbnailFile';
import DocumentThumbnailImage from './DocumentThumbnailImage';

const Wrapper = styled.div`
  display: flex;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  flex: 1;
`;

const ThumbnailPreview = props => {
  const { data, showBytes, forceThumbnail, direction, iconSize } = props;
  const { contentType } = data;

  if (!contentType) return null;

  if (contentType.includes('image') && !forceThumbnail) {
    return <DocumentThumbnailImage {...data} />;
  }

  return (
    <DocumentThumbnailFile
      showBytes={showBytes}
      direction={direction}
      iconSize={iconSize}
      {...data}
    />
  );
};

const DocumentThumbnail = props => {
  const { data, showBytes, forceThumbnail, direction, iconSize, style, onClick } = props;
  const { _id } = data;

  const { openDocument } = useOpenDocument();

  const handleCick = () => {
    if (onClick) return onClick();
    if (_id) {
      openDocument({ _id });
    }
  };

  return (
    <Wrapper style={style} onClick={handleCick}>
      <ThumbnailPreview
        data={data}
        showBytes={showBytes}
        forceThumbnail={forceThumbnail}
        direction={direction}
        iconSize={iconSize}
      />
    </Wrapper>
  );
};

DocumentThumbnail.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    contentType: PropTypes.string,
    originalname: PropTypes.string,
    size: PropTypes.number,
  }).isRequired,
  showBytes: PropTypes.bool,
  forceThumbnail: PropTypes.bool,
  direction: PropTypes.string,
  iconSize: PropTypes.number,
  onClick: PropTypes.func,
};

DocumentThumbnail.defaultProps = {
  direction: 'column',
};

export default DocumentThumbnail;
