/**
 * Convierte un color hexadecimal en rgb
 * @param   {string} hex - Color hexadecimal
 * @returns {Object.<number>}
 */
const parseHexToRgb = hex => {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
    : null;
};

/**
 * Devuelve un número que representa la luminancia del color
 * @see https://polished.js.org
 * @param   {string} color - Color hexadecimal
 * @returns {number}
 */
const getColorLuminance = color => {
  const rgbColor = parseHexToRgb(color);
  const [r, g, b] = Object.keys(rgbColor).map(key => {
    const channel = rgbColor[key] / 255;
    return channel <= 0.03928 ? channel / 12.92 : ((channel + 0.055) / 1.055) ** 2.4;
  });
  return 0.2126 * r + 0.7152 * g + 0.0722 * b;
};

/**
 * Devuelve blanco o negro para el mejor contraste dependiendo de la luminosidad del color data.
 * Sigue la especificación del W3C para legibilidad
 * @see https://www.w3.org/TR/WCAG20-TECHS/G18.html
 * @see https://polished.js.org
 * @param   {Object} arg
 * @param   {string} arg.color
 * @param   {string} [arg.black='#000'] - Color para el negro
 * @param   {string} [arg.white='#fff'] - Color para el blanco
 * @returns {string}
 */
const getReadableColor = ({ color, black = '#000', white = '#fff' }) => {
  return getColorLuminance(color) > 0.179 ? black : white;
};

const colorLuminance = (hex, lum = 0) => {
  // validate hex string
  let parsedHex = String(hex).replace(/[^0-9a-f]/gi, '');
  if (parsedHex.length < 6) {
    parsedHex =
      parsedHex[0] + parsedHex[0] + parsedHex[1] + parsedHex[1] + parsedHex[2] + parsedHex[2];
  }

  // convert to decimal and change luminosity
  let rgb = '#',
    c;
  for (let i = 0; i < 3; i++) {
    c = parseInt(parsedHex.substr(i * 2, 2), 16);
    c = Math.round(Math.min(Math.max(0, c + c * lum), 255)).toString(16);
    rgb += ('00' + c).substr(c.length);
  }

  return rgb;
};

export { parseHexToRgb, getColorLuminance, getReadableColor, colorLuminance };
