import React from 'react';
import useWaitForDomElement from 'hooks/useWaitForDomElement';
import Portal from 'components/Portal';
import { H1 } from 'components/Headings';

const Title = props => {
  const { children } = props;
  const { element } = useWaitForDomElement({ selector: '#titlePortal' });

  return (
    <Portal container={element}>
      <H1>{children}</H1>
    </Portal>
  );
};

Title.propTypes = {};

export default Title;
