import moment from 'moment';
import { client } from 'clients/api';
import { isBoolean } from 'utils/booleans';

const fetchForm = ({ request }) => {
  const formRequest = { method: 'POST', ...request };
  return client({ request: formRequest }).then(parseForm);
};

const parseForm = form => {
  const fields = parseFields(form.fields);
  return { ...form, fields };
};

const parseFields = fields => {
  return fields.map(field => {
    const fieldParsed = parseField(field);
    return fieldParsed.type === 'multi' ? parseFieldMulti(fieldParsed) : fieldParsed;
  });
};

const parseFieldMulti = field => {
  const fields = field.fields.map(parseField);
  return { ...field, fields };
};

const parseField = field => {
  const { fieldType: type, needed, multi: isMulti, clearable: isClearable, ...fieldProps } = field;
  const required = needed || fieldProps.required;

  return {
    ...fieldProps,
    type,
    required,
    ...(isBoolean(isMulti) ? { isMulti } : {}),
    ...(isBoolean(isClearable) ? { isClearable } : {}),
  };
};

const parseAttributeField = field => {
  const { type } = field;

  if (type === 'array') {
    return parseAttributeArrayField(field);
  }

  return { ...field };
};

const parseAttributeArrayField = field => {
  const { values } = field;

  return {
    ...field,
    values: values.map(v => ({ ...v, key: v.value, label: v.value })),
  };
};

const getHumanizedValueFromField = ({ field, value }) => {
  if (!value) return value;

  switch (field.type) {
    case 'filter': {
      if (field.isMulti) {
        const matchOptions = field.values?.filter(option => value.includes(option.value));
        return matchOptions?.map(option => option.label).join(', ');
      }
      const matchOption = field.values?.find(option => option.value === value);
      return matchOption?.label;
    }
    case 'date': {
      return moment(value).format('DD/MM/YYYY');
    }
    case 'datetime': {
      return moment(value).format('DD/MM/YYYY HH:mm');
    }
    case 'time': {
      return moment(value).format('HH:mm');
    }
    case 'dateRange': {
      const beginDate = moment(value.beginDate).format('DD/MM/YYYY');
      const endDate = moment(value.endDate).format('DD/MM/YYYY');
      return `${beginDate} - ${endDate}`;
    }
    default:
      return value;
  }
};

const getFieldIcon = ({ field }) => {
  switch (field.type) {
    case 'phone':
      return 'phone-icon.svg';
    case 'email':
      return 'email-envelope-icon.svg';
    case 'date':
      return 'calendar-2-icon.svg';
    case 'url':
      return 'location-globe-icon.svg';
    default:
      return 'text-icon.svg';
  }
};

const formForAttributesData = { url: '/front/forms/attributesData' };
const formForAttributeString = { url: '/front/forms/attributesString' };
const formForAttributeNumber = { url: '/front/forms/attributesNumber' };
const formForAttributeArray = { url: '/front/forms/attributesArray' };
const formForAttributeBoolean = { url: '/front/forms/attributesBoolean' };
const formForAttributeDate = { url: '/front/forms/attributesDate' };
const formForAttributeDatetime = { url: '/front/forms/attributesDatetime' };

export {
  fetchForm,
  formForAttributesData,
  formForAttributeString,
  formForAttributeNumber,
  formForAttributeArray,
  formForAttributeBoolean,
  formForAttributeDate,
  formForAttributeDatetime,
  parseFields,
  parseField,
  parseAttributeField,
  getHumanizedValueFromField,
  getFieldIcon,
};
