import React from 'react';
import PropTypes from 'prop-types';
import Input from './Fields/Input';
import InputDate from './Fields/InputDate';
import InputDatetime from './Fields/InputDatetime';
import Switch from './Fields/Switch';
import { Select } from './Fields/Select';
import DateRange from './Fields/DateRange';
import Gradient from './Fields/Gradient';
import File from './Fields/File';
import FormFieldArray from './FormFieldArray';
import Textarea from './Fields/Textarea';
import Repeat from './Fields/Repeat';

/**
 * Devuelve el campo del formulario según el tipo.
 * La invocación de la función `register` devuelve los siguientes métodos: `name`, `ref`, `onChange`, `onBlur`.
 * @see https://react-hook-form.com/api/useform/register
 */
const FormField = props => {
  const { register, control, validationRules, errors, ...fieldProps } = props;
  const { type, name } = fieldProps;

  switch (type) {
    case 'hidden': {
      return <Input type="hidden" {...register(name, validationRules)} {...fieldProps} />;
    }
    case 'text':
    case 'time':
    case 'email':
    case 'color':
    case 'password':
    case 'phone': {
      return <Input type={type} {...register(name, validationRules)} {...fieldProps} />;
    }
    case 'number': {
      return (
        <Input
          type={type}
          {...register(name, { ...validationRules, valueAsNumber: true })}
          {...fieldProps}
        />
      );
    }
    case 'date': {
      return <InputDate type={type} {...register(name, validationRules)} {...fieldProps} />;
    }
    case 'datetime': {
      const { type, ...restFieldProps } = fieldProps;
      return (
        <InputDatetime
          type="datetime-local"
          {...register(name, validationRules)}
          {...restFieldProps}
        />
      );
    }
    case 'textarea': {
      return <Textarea type={type} {...register(name, validationRules)} {...fieldProps} />;
    }
    case 'switch':
    case 'checkbox': {
      const { defaultValue, ...restFieldProps } = fieldProps;
      return (
        <Switch
          {...register(name, validationRules)}
          defaultChecked={defaultValue}
          {...restFieldProps}
        />
      );
    }
    case 'filter':
    case 'select': {
      return (
        <Select control={control} rules={validationRules} options={props.values} {...fieldProps} />
      );
    }
    case 'dateRange': {
      const { type, defaultValue, ...restFieldProps } = fieldProps;
      return <DateRange control={control} rules={validationRules} {...restFieldProps} />;
    }
    case 'gradient': {
      return <Gradient control={control} rules={validationRules} {...fieldProps} />;
    }
    case 'file':
    case 'dropzone': {
      return <File control={control} rules={validationRules} {...fieldProps} />;
    }
    case 'multi': {
      return (
        <FormFieldArray register={register} control={control} errors={errors} {...fieldProps} />
      );
    }
    case 'repeat': {
      return <Repeat control={control} rules={validationRules} {...fieldProps} />;
    }
    default:
      return null;
  }
};

FormField.propTypes = {
  type: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  validationRules: PropTypes.object,
  errors: PropTypes.object,
};

export default FormField;
