const appName = process.env.REACT_APP_NAME;
const appVersion = process.env.REACT_APP_VERSION;

const productId = process.env.REACT_APP_PRODUCT_ID;
const productAuthor = process.env.REACT_APP_PRODUCT_AUTHOR;
const productName = process.env.REACT_APP_PRODUCT_NAME;
const productColor = process.env.REACT_APP_PRODUCT_COLOR;

const originUrl = process.env.REACT_APP_ORIGIN_URL;
const baseUrl = `${originUrl}${process.env.REACT_APP_BASE_URL}`;

export {
  appName,
  appVersion,
  productId,
  productAuthor,
  productName,
  productColor,
  originUrl,
  baseUrl,
};
