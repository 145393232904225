import { useMutation, useQueryClient } from 'react-query';
import { removeComplaintFile } from 'services/complaints';

const useMutationRemoveComplaintFile = () => {
  const queryClient = useQueryClient();
  const mutationInfo = useMutation(removeComplaintFile, {
    onSuccess: () => {
      queryClient.invalidateQueries('complaint');
    },
  });
  return mutationInfo;
};

export default useMutationRemoveComplaintFile;
