import NotFound from 'pages/NotFound';
import Home from 'pages/Home';
import Add from './pages/Add';
import Search from './pages/Search';
import Complaint from './pages/Complaint';

const routes = [
  {
    path: '/',
    element: <Home />,
    breadcrumb: 'Inicio',
  },
  {
    path: 'add',
    element: <Add />,
    breadcrumb: 'Crear denuncia',
  },
  {
    path: 'search',
    element: <Search />,
    breadcrumb: 'Consultar denuncia',
  },
  {
    path: 'search/complaint',
    element: <Complaint />,
    breadcrumb: 'Denuncia',
  },
  {
    path: '*',
    element: <NotFound />,
  },
];

export default routes;
