import { appName, appVersion } from 'config';
import { fillParams, replaceParamsFromString, replaceParamsFromObject } from './params';
import { getTimeZone } from './dates';

const parseRequestWithParams = ({ request, scope = {} }) => {
  const params = fillParams({ params: request.params, scope: { ...scope } });
  const url = replaceParamsFromString({ source: request.url, params });
  const body = replaceParamsFromObject({ source: request.body, params });

  return { ...request, url, body };
};

/**
 * Parsea las opciones de la petición con los datos dependiendo de la petición o formato
 * @param  {Object}                 headers
 * @param  {Object}                 request
 * @param  {Object}                 data
 * @param  {String}                 format
 * @param  {AbortController.signal} signal
 * @return {Object}
 */
const parseRequestOptions = ({ headers, request, data, format, signal }) => {
  // Opciones comunes
  const options = {
    method: request.method || 'GET',
    headers: {
      AppName: appName,
      AppVersion: appVersion,
      TimeZone: getTimeZone(),
      Event: request.event || data?.event,
      ...headers,
    },
    signal,
  };

  if (data && format === 'formData') {
    // Opciones para peticiones con formato FormData
    options.headers['Event'] = data?.get('event');
    options.body = data;
  } else if (data && request.event) {
    // Opciones para Eventos
    const body = {
      event: request.event,
      company: request.company,
      payload: data,
      createdAt: new Date().toISOString(),
    };
    options.headers['Content-Type'] = 'application/json';
    options.body = body && JSON.stringify(body);
  } else {
    // Opciones para peticiones Simples
    options.headers['Content-Type'] = 'application/json';
    if (options.method !== 'GET') {
      options.body = data && JSON.stringify(data);
    }
  }

  return options;
};

export { parseRequestWithParams, parseRequestOptions };
