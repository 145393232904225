import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Spinner from 'react-spinkit';
import theme from 'utils/theme';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${props => props.zIndex};
  background: rgba(255, 255, 255, 0.7);
`;

const Loading = props => {
  const { name, fadeIn, color, zIndex } = props;

  return (
    <Wrapper zIndex={zIndex}>
      <Spinner name={name} fadeIn={fadeIn} color={color} />
    </Wrapper>
  );
};

Loading.propTypes = {
  name: PropTypes.string,
  fadeIn: PropTypes.string,
  color: PropTypes.string,
  zIndex: PropTypes.number,
};

Loading.defaultProps = {
  name: 'ball-scale-ripple-multiple',
  fadeIn: 'half',
  color: theme.colors.secondary,
  zIndex: 10,
};

export default Loading;
