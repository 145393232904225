import PropTypes from 'prop-types';
import styled from 'styled-components';
import Input from './Input';

const InputDatetime = styled(Input)``;

InputDatetime.propTypes = {
  type: PropTypes.string,
  max: PropTypes.string,
};

InputDatetime.defaultProps = {
  type: 'datetime-local',
  max: '9999-12-31T23:59',
};

export default InputDatetime;
