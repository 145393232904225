import React, { useCallback } from 'react';
import styled from 'styled-components';
import { Controller } from 'react-hook-form';
import Input from './Input';
import { SelectSingle, SelectMulti } from './Select';

const UNITS = {
  DAY: 'day',
  WEEK: 'week',
  MONTH: 'month',
  YEAR: 'year',
};

const UNITS_LABELS = {
  DAY: 'Día(s)',
  WEEK: 'Semana(s)',
  MONTH: 'Mes(es)',
  YEAR: 'Año(s)',
};

const UNITS_OPTIONS = Object.keys(UNITS).map(key => ({
  value: UNITS[key],
  label: UNITS_LABELS[key],
}));

const ON = {
  MONDAY: 'monday',
  TUESDAY: 'tuesday',
  WEDNESDAY: 'wednesday',
  THURSDAY: 'thursday',
  FRIDAY: 'friday',
  SATURDAY: 'saturday',
  SUNDAY: 'sunday',
};

const ON_LABELS = {
  MONDAY: 'Lunes',
  TUESDAY: 'Martes',
  WEDNESDAY: 'Miércoles',
  THURSDAY: 'Jueves',
  FRIDAY: 'Viernes',
  SATURDAY: 'Sábado',
  SUNDAY: 'Domingo',
};

const ON_OPTIONS = Object.keys(ON).map(key => ({
  value: ON[key],
  label: ON_LABELS[key],
}));

const DAYS = 31;
const ON_DAY_OPTIONS = Array.from({ length: DAYS }).map((_, i) => {
  const value = i + 1;
  return { value, label: value };
});

const ACTION_TYPES = {
  SET_AMOUNT: 'SET_AMOUNT',
  SET_UNIT: 'SET_UNIT',
  SET_ON: 'SET_ON',
  SET_ON_DAY: 'SET_ON_DAY',
};

const stateReducer = (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_AMOUNT: {
      const { value } = action.payload;
      return { ...state, amount: value };
    }
    case ACTION_TYPES.SET_UNIT: {
      const { value } = action.payload;
      const onValue = value === UNITS.WEEK ? ['monday'] : null;
      const onDayValue = value === UNITS.MONTH ? 1 : null;
      return { ...state, unit: value, on: onValue, onDay: onDayValue };
    }
    case ACTION_TYPES.SET_ON: {
      const { value } = action.payload;
      return { ...state, on: value };
    }
    case ACTION_TYPES.SET_ON_DAY: {
      const { value } = action.payload;
      return { ...state, onDay: value };
    }
    default: {
      throw new Error(`Unhandled type: ${action.type}`);
    }
  }
};

const Fieldset = styled.fieldset`
  display: flex;
  gap: 2rem;
  margin: 0;
  padding: 0;
  border: none;
`;

const FieldsetSection = styled.div`
  display: flex;
  gap: 1rem;
`;

const FieldsetControl = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const FieldsetInput = styled.div`
  flex: 1;
`;

const FieldsetInputAmount = styled.div`
  max-width: 80px;
`;

const FieldsetInputUnit = styled.div`
  min-width: 160px;
`;

const FieldsetInputOnDay = styled.div`
  min-width: 100px;
`;

const RepeatAmount = props => {
  const { id, value, onChange } = props;
  return <Input type="number" id={id} min={1} value={value} onChange={onChange} />;
};

const RepeatUnit = props => {
  const { value, onChange } = props;
  return (
    <SelectSingle
      classNamePrefix="ReactSelect"
      value={value}
      options={UNITS_OPTIONS}
      onChange={onChange}
    />
  );
};

const RepeatOn = props => {
  const { id, value, onChange } = props;
  return (
    <SelectMulti
      classNamePrefix="ReactSelect"
      placeholder="Selecciona uno o varios días"
      isClearable={false}
      inputId={id}
      value={value}
      options={ON_OPTIONS}
      onChange={onChange}
    />
  );
};

const RepeatOnDay = props => {
  const { id, value, onChange } = props;
  return (
    <SelectSingle
      classNamePrefix="ReactSelect"
      placeholder="Selecciona un día"
      inputId={id}
      value={value}
      options={ON_DAY_OPTIONS}
      onChange={onChange}
    />
  );
};

const RepeatFieldset = props => {
  const { id, value, onChange } = props;

  const onId = `${id}:on`;
  const onDayId = `${id}:onDay`;

  const isOnVisible = value?.unit === UNITS.WEEK;
  const isOnDayVisible = value?.unit === UNITS.MONTH;

  const handleAmountChange = useCallback(
    event => {
      const fieldsetValue = stateReducer(value, {
        type: ACTION_TYPES.SET_AMOUNT,
        payload: { value: event.target.value },
      });
      onChange(fieldsetValue);
    },
    [value, onChange],
  );

  const handleUnitChange = useCallback(
    unit => {
      const fieldsetValue = stateReducer(value, {
        type: ACTION_TYPES.SET_UNIT,
        payload: { value: unit },
      });
      onChange(fieldsetValue);
    },
    [value, onChange],
  );

  const handleOnChange = useCallback(
    on => {
      const fieldsetValue = stateReducer(value, {
        type: ACTION_TYPES.SET_ON,
        payload: { value: on },
      });
      onChange(fieldsetValue);
    },
    [value, onChange],
  );

  const handleOnDayChange = useCallback(
    onDay => {
      const fieldsetValue = stateReducer(value, {
        type: ACTION_TYPES.SET_ON_DAY,
        payload: { value: onDay },
      });
      onChange(fieldsetValue);
    },
    [value, onChange],
  );

  return (
    <Fieldset>
      <FieldsetSection>
        <FieldsetControl>
          <label htmlFor={id}>Cada</label>
          <FieldsetInputAmount>
            <RepeatAmount id={id} value={value?.amount} onChange={handleAmountChange} />
          </FieldsetInputAmount>
        </FieldsetControl>
        <FieldsetControl>
          <FieldsetInputUnit>
            <RepeatUnit value={value?.unit} onChange={handleUnitChange} />
          </FieldsetInputUnit>
        </FieldsetControl>
      </FieldsetSection>
      <FieldsetSection>
        {isOnVisible && (
          <FieldsetControl>
            <label htmlFor={onId}>Los</label>
            <FieldsetInput>
              <RepeatOn id={onId} value={value?.on} onChange={handleOnChange} />
            </FieldsetInput>
          </FieldsetControl>
        )}
        {isOnDayVisible && (
          <FieldsetControl>
            <label htmlFor={onDayId}>El día</label>
            <FieldsetInputOnDay>
              <RepeatOnDay id={onDayId} value={value?.onDay} onChange={handleOnDayChange} />
            </FieldsetInputOnDay>
          </FieldsetControl>
        )}
      </FieldsetSection>
    </Fieldset>
  );
};

const Repeat = props => {
  const { control, rules, ...componentProps } = props;
  const { name } = componentProps;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { value, onChange } }) => {
        return <RepeatFieldset {...componentProps} value={value} onChange={onChange} />;
      }}
    />
  );
};

Repeat.propTypes = {};

export default Repeat;
