import React from 'react';
import PersonaMessageBox from './PersonaMessageBox';
import SystemMessageBox from './SystemMessageBox';
import WarningMessageBox from './WarningMessageBox';

const MessageBox = props => {
  const { data, showDateTimeOnSystemMessages } = props;
  const { type } = data;

  if (type && type === 'warning') {
    return <WarningMessageBox {...props} {...props.data} />;
  }

  if (type && type === 'system') {
    return (
      <SystemMessageBox {...props} {...props.data} showDateTime={showDateTimeOnSystemMessages} />
    );
  }

  return <PersonaMessageBox {...props} {...props.data} />;
};

export default MessageBox;
