import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { client, fileClient } from 'clients/api';
import { originUrl } from 'config';
import { parseRequestData } from 'utils/requests';
import { filterDataBySearchText } from 'utils/search';
import { getDateRangeBounds } from 'utils/queries';
import { isBoolean } from 'utils/booleans';

const STATES_ALLOWED_FOR_SIGN = ['pending', 'error'];
const STATES_ALLOWED_FOR_SEAL = ['pending', 'error'];

const STATES_ALLOWED_FOR_PROCESS_CHUNKS = ['unconfirmed'];

/**
 * Devuelve todos los tipos de documentos (los administradores pueden tener algunos restringidos)
 * @returns {Promise}
 */
const fetchTypes = () => {
  const request = { url: '/docs/types', method: 'GET' };
  return client({ request }).then(sortTypes);
};

/**
 * Devuelve todos los tipos de documentos (los usuarios normales pueden ver todos)
 * @returns {Promise}
 */
const fetchTypesMine = () => {
  const request = { url: '/docs/types/mine', method: 'GET' };
  return client({ request }).then(sortTypes);
};

/**
 * Devuelve todos los tipos de documentos
 * @returns {Promise}
 */
const fetchPublicTypes = () => {
  const request = {
    url: '/docs/types/filter',
    method: 'POST',
    body: {
      filter: {
        $or: [{ internal: false }, { canBeUpload: true }],
      },
    },
  };
  return client({ request }).then(sortTypes);
};

/**
 * Devuelve un tipo de documento
 * @param   {Object} args
 * @param   {string} args._id
 * @returns {Promise}
 */
const fetchType = ({ _id }) => {
  const request = {
    url: '/docs/types/filter',
    method: 'POST',
    body: { filter: { _id } },
  };
  return client({ request }).then(response => (Array.isArray(response) ? response[0] : response));
};

/**
 * Devuelve los documentos dado un array de ids
 * @param   {Object}         args
 * @param   {Array.<string>} args.ids
 * @returns {Promise}
 */
const fetchDocumentsByIds = ({ ids }) => {
  const request = {
    url: '/docs/filter',
    method: 'POST',
    body: {
      _id: { $in: ids },
    },
  };
  return client({ request });
};

/**
 * Devuelve un documento
 * Nota: esta petición no devuelve el blob sino la información del documento: content type, chunk size, url...
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const fetchDocument = ({ _id }) => {
  const request = {
    url: '/documents/files/filter',
    method: 'POST',
    body: { filter: { _id } },
  };
  return client({ request }).then(handleDocumentResponse);
};

/**
 * Devuelve el blob de un documento
 * @param   {Object}  arg
 * @param   {string}  arg.url
 * @returns {Promise}
 */
const fetchDocumentBlob = ({ url: rawUrl }) => {
  const baseUrl = '';
  const url = getDocumentUrl({ url: rawUrl });
  const request = { url };
  return fileClient({ baseUrl, request }).then(response => response.data);
};

/**
 * Devuelve los accesos a un documento
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const fetchDocumentAccesses = ({ _id }) => {
  const request = {
    url: `/docs/file/${_id}/accesses`,
    method: 'GET',
  };
  return client({ request }).then(response => response.map(parseDocumentAccess));
};

const parseDocumentAccess = access => {
  const { dateTime } = access;
  const dateTimeParsed = dateTime && moment(dateTime).format('DD/MM/YYYY HH:mm');
  const persona = parseDocumentAccessPersona(access.persona);
  return { ...access, dateTimeParsed, persona };
};

const parseDocumentAccessPersona = persona => {
  const { profile: profileRaw } = persona;
  const fullName = `${profileRaw?.firstName} ${profileRaw?.lastName}`;
  const profile = { ...profileRaw, fullName };
  return { ...persona, profile };
};

const filterDocumentAccessesBySearchText = ({ data, searchText }) => {
  const keys = ['personaId', 'persona.profile.fullName'];
  return filterDataBySearchText({ data, searchText, keys });
};

const handleDocumentResponse = response => {
  const document = Array.isArray(response) ? response[0] : response;
  return fetchTypesMine().then(types => parseDocument({ document, types }));
};

/**
 * Devuelve los documentos como administrador
 * @param {Object} arg
 * @param {string} [arg.beginDate]
 * @param {string} [arg.endDate]
 * @param {string} [arg.crewId]
 * @param {string} [arg.personaId]
 * @param {string} [arg.type]
 * @param {boolean} [arg.signed]
 * @returns {Promise}
 */
const fetchDocumentsAsAdmin = ({ beginDate, endDate, crewId, personaId, type, signed }) => {
  const { startOfBeginDate, endOfEndDate } = getDateRangeBounds({ beginDate, endDate });
  const startOfBeginDateAsISOString = startOfBeginDate && moment(startOfBeginDate).toISOString();
  const endOfEndDateAsISOString = endOfEndDate && moment(endOfEndDate).toISOString();

  const request = {
    url: '/docs/filter',
    method: 'POST',
    body: {
      'metadata.documentManagement': true,
      ...(beginDate
        ? {
            'metadata.createdAt': {
              $gte: startOfBeginDateAsISOString,
              $lte: endOfEndDateAsISOString,
            },
          }
        : {}),
      ...(crewId ? { 'metadata.crewId': { $in: [crewId, null] } } : {}),
      ...(personaId ? { 'metadata.personaId': { $in: [personaId, null] } } : {}),
      ...(type ? { 'metadata.type': type } : {}),
      ...(isBoolean(signed) ? { signed } : {}),
    },
  };

  return client({ request })
    .then(parseDocuments)
    .then(sortDocuments);
};

const fetchDocumentsFilter = ({ beginDate, endDate, type, highlighted }) => {
  const { startOfBeginDate, endOfEndDate } = getDateRangeBounds({ beginDate, endDate });
  const startOfBeginDateAsISOString = startOfBeginDate && moment(startOfBeginDate).toISOString();
  const endOfEndDateAsISOString = endOfEndDate && moment(endOfEndDate).toISOString();

  const request = {
    url: '/docs/filter',
    method: 'POST',
    body: {
      onlyMyDocuments: true,
      'metadata.documentManagement': true,
      ...(beginDate
        ? {
            'metadata.createdAt': {
              $gte: startOfBeginDateAsISOString,
              $lte: endOfEndDateAsISOString,
            },
          }
        : {}),
      ...(type ? { 'metadata.type': type } : {}),
      ...(highlighted ? { 'metadata.highlighted': true } : {}),
    },
  };
  return client({ request })
    .then(parseDocuments)
    .then(sortDocuments);
};

const fetchDocumentsPendingToSign = ({ userId, beginDate, endDate, type }) => {
  const { startOfBeginDate, endOfEndDate } = getDateRangeBounds({ beginDate, endDate });
  const startOfBeginDateAsISOString = startOfBeginDate && moment(startOfBeginDate).toISOString();
  const endOfEndDateAsISOString = endOfEndDate && moment(endOfEndDate).toISOString();

  const request = {
    url: '/docs/filter',
    method: 'POST',
    body: {
      ...(beginDate
        ? {
            'metadata.createdAt': {
              $gte: startOfBeginDateAsISOString,
              $lte: endOfEndDateAsISOString,
            },
          }
        : {}),
      ...(type ? { 'metadata.type': type } : {}),
      'metadata.signatures': {
        $elemMatch: { userId, state: 'pending' },
      },
    },
  };
  return client({ request })
    .then(parseDocuments)
    .then(sortDocuments);
};

const parseDocuments = async documents => {
  const types = await fetchTypesMine();
  return documents.map(document => parseDocument({ document, types }));
};

const parseDocument = ({ document, types }) => {
  const { url: urlRaw, previewUrl: previewUrlRaw, metadata: metadataRaw } = document;
  const url = urlRaw && getDocumentUrl({ url: urlRaw });
  const previewUrl = previewUrlRaw && getDocumentUrl({ url: previewUrlRaw });
  const metadata = parseDocumentMetadata({ metadata: metadataRaw, types });
  return { ...document, url, previewUrl, metadata };
};

const parseDocumentMetadata = ({ metadata, types }) => {
  const { createdAt, type, signatures: signaturesRaw, seal: sealRaw } = metadata;
  const createdAtParsed = createdAt && moment(createdAt).format('DD/MM/YYYY HH:mm');
  const typeParsed = type && types?.find(t => t._id === type);
  const signatures = signaturesRaw?.map(parseDocumentSignature);
  const seal = sealRaw && parseDocumentSeal(sealRaw);
  const hasSeal = seal?.state === 'completed';
  return { ...metadata, createdAtParsed, typeParsed, signatures, seal, hasSeal };
};

const parseDocumentSignature = signature => {
  const { signedAt } = signature;
  const signedAtParsed = signedAt && moment(signedAt).format('DD/MM/YYYY HH:mm');
  return { ...signature, signedAtParsed };
};

const parseDocumentSeal = seal => {
  const { signedAt } = seal;
  const signedAtParsed = signedAt && moment(signedAt).format('DD/MM/YYYY HH:mm');
  return { ...seal, signedAtParsed };
};

/**
 * Devuelve los fragmentos identificados del documento y sus avisos.
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const fetchDocumentAnalize = ({ _id }) => {
  const request = {
    url: `/docs/analize`,
    method: 'POST',
    body: { _id },
  };
  return client({ request });
};

const sortTypes = types => {
  return orderBy(types, ['name'], ['asc']);
};

const sortDocuments = documents => {
  return orderBy(documents, ['metadata.createdAt'], ['desc']);
};

const filterDocumentTypesBySearchText = ({ data, searchText }) => {
  const keys = ['name'];
  return filterDataBySearchText({ data, searchText, keys });
};

const filterDocumentsBySearchText = ({ data, searchText }) => {
  const keys = ['metadata.originalname'];
  return filterDataBySearchText({ data, searchText, keys });
};

const filterDocumentsReceiptsBySearchText = ({ data, searchText }) => {
  const keys = ['DNI', 'persona.profile.firstName', 'persona.profile.lastName'];
  return filterDataBySearchText({ data, searchText, keys });
};

const formForDocumentTypes = { url: '/front/forms/documentTypes' };
const formForDocumentsFilter = { url: '/front/forms/documentsFilter' };
const formForDocumentAdminUpload = { url: '/front/forms/documentUploadAdmin' };
const formForDocumentAdminConfig = { url: '/front/forms/documentConfigAdmin' };
const formForDocumentAdminAdvancedConfig = { url: '/front/forms/documentConfigAdminAdvanced' };
const formForDocumentAdminUpdate = { url: '/front/forms/documentUpdateAdmin' };
const formForDocumentAdminAdvancedUpdate = { url: '/front/forms/documentUpdateAdminAdvanced' };
const formForDocumentSign = { url: '/front/forms/documentSign' };
const formForDocumentSeal = { url: '/front/forms/documentSeal' };

/**
 * Devuelve la url de un documeto
 * Añade el origin si está definido (útil por ejemplo cuando se desarrolla en localhost)
 * @param   {Object} args
 * @param   {string} args.url
 * @returns {string}
 */
const getDocumentUrl = ({ url }) => {
  if (!url) return null;

  const isExternalUrl = url?.startsWith('http');
  return isExternalUrl ? url : `${originUrl}${url}`;
};

/**
 * Devuelve el link de un documento
 * @param   {Object} arg
 * @param   {string} arg._id
 * @returns {string}
 */
const getDocumentLink = ({ _id }) => {
  return `/document/${_id}`;
};

const getDocumentUrlForDocview = ({ _id }) => {
  return `${originUrl}/documents/?_id=${_id}`;
};

const getDocumentBlobLink = () => {
  return `/document/blob`;
};

/**
 * Comprueba si un usuario puede firmar un documento
 * @param   {Object}  arg
 * @param   {string}  arg.userId
 * @param   {Object}  arg.data
 * @returns {boolean}
 */
const shouldUserSignDocument = ({ userId, data }) => {
  const { metadata } = data;
  const signatures = metadata?.signatures;
  const matchSignature = signatures?.find(s => s.userId === userId);

  if (!matchSignature) return false;

  return STATES_ALLOWED_FOR_SIGN.includes(matchSignature.state);
};

const shouldUserSealDocument = ({ data }) => {
  const { canSeal, metadata } = data;
  const { hasSeal, seal } = metadata;

  if (!canSeal) return false;

  return !hasSeal || STATES_ALLOWED_FOR_SEAL.includes(seal?.state);
};

/**
 * Crea un tipo de documento
 * @param   {Object} arg
 * @param   {string} arg.name
 * @param   {string} [arg.color]
 * @param   {string} [arg.position] - Posición para la firma
 * @param   {number} [arg.page] - Página para la firma
 * @returns {Promise}
 */
const createDocumentType = ({ name, visibleFor, color, position, page }) => {
  const request = { url: '/docs/events', method: 'POST', event: 'document.type.add' };
  const data = { name, visibleFor, color, position, page };
  return client({ request, data });
};

/**
 * Actualiza un tipo de documento
 * @param   {Object} arg
 * @param   {string} arg._id
 * @param   {string} arg.name
 * @param   {string} [arg.color]
 * @param   {string} [arg.position] - Posición para la firma
 * @param   {number} [arg.page] - Página para la firma
 * @returns {Promise}
 */
const updateDocumentType = ({ _id, name, visibleFor, color, position, page }) => {
  const request = { url: '/docs/events', method: 'POST', event: 'document.type.update' };
  const data = { _id, name, visibleFor, color, position, page };
  return client({ request, data });
};

/**
 * Elimina un tipo de documento
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const removeDocumentType = ({ _id }) => {
  const request = { url: '/docs/events', method: 'POST', event: 'document.type.remove' };
  const data = { _id };
  return client({ request, data });
};

/**
 * Sube un documento para la gestión de documentos
 * @param   {Object}     arg
 * @param   {File}       arg.file
 * @param   {string}     arg.type
 * @param   {...Object}  arg.restData
 * @returns {Promise}
 */
const uploadDocumentAdmin = ({ file, type, ...restData }) => {
  const request = { url: '/docs/upload', method: 'POST' };
  const values = {
    rule: 'document.management.add',
    documentManagement: true,
    file,
    type,
    ...restData,
  };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Sube un documento a grupos para la gestión de documentos
 * @param   {Object}     arg
 * @param   {File}       arg.file
 * @param   {string}     arg.type
 * @param   {...Object}  arg.restData
 * @returns {Promise}
 */
const uploadDocumentAdminAdvanced = ({ file, type, ...restData }) => {
  const request = { url: '/docs/uploadMultiple', method: 'POST' };
  const values = {
    rule: 'document.management.add',
    documentManagement: true,
    file,
    type,
    ...restData,
  };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Asigna una persona al documento en la gestión de documentos
 * @param   {Object}  arg
 * @param   {string}  arg._id - id del documento
 * @param   {string}  arg.personaId
 * @returns {Promise}
 */
const setPersonaForDocumentChunkAdmin = ({ _id, personaId }) => {
  const request = { url: '/docs/events', method: 'POST', event: 'document.persona.set' };
  const data = { _id, personaId };
  return client({ request, data });
};

/**
 * Procesa un documento para la gestión de documentos
 * @returns {Promise}
 * @param   {string}     arg._id - id del documento
 * @param   {array}      arg.receipts
 * @param   {...Object}  arg.restData
 * @param   {Object}     arg
 */
const processDocumentAdmin = ({ _id, receipts, ...restData }) => {
  const request = { url: '/docs/events', method: 'POST', event: 'document.process' };
  const data = { _id, receipts, ...restData };
  return client({ request, data });
};

/**
 * Actualiza un documento de la gestión de documentos
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @param   {Object}  arg.metadata
 * @returns {Promise}
 */
const updateDocumentAdmin = ({ _id, metadata }) => {
  const request = { url: '/docs/update', method: 'POST' };
  const data = {
    rule: 'document.management.update',
    file: _id,
    metadata,
  };
  return client({ request, data });
};

/**
 * Elimina un documento de la gestión de documentos
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const removeDocumentAdmin = ({ _id }) => {
  const request = { url: '/docs/remove', method: 'POST' };
  const data = { file: _id, rule: 'document.management.remove' };
  return client({ request, data });
};

/**
 * Marca un documento como destacado
 * @param   {Object}  args
 * @param   {string}  args._id
 * @param   {Object}  args.metadata
 * @param   {boolean} args.highlight
 * @returns {Promise}
 */
const updateDocumentHighlight = ({ _id, metadata, highlight = true }) => {
  const request = { url: '/docs/update', method: 'POST' };
  const data = {
    file: _id,
    metadata: { ...metadata, highlighted: highlight },
    rule: 'document.management.update',
  };
  return client({ request, data });
};

/**
 * Firma varios documentos a la vez
 * @param   {Object}         arg
 * @param   {Array.<string>} arg.docIds
 * @param   {string}         arg.password
 * @returns {Promise}
 */
const signDocuments = ({ docIds, password }) => {
  const request = { url: `/docs/files/sign`, method: 'POST' };
  const data = { docIds, password };
  return client({ request, data });
};

/**
 * Firma un documento
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @param   {string}  arg.password
 * @returns {Promise}
 */
const signDocument = ({ _id, password }) => {
  const request = { url: `/docs/file/${_id}/sign`, method: 'POST' };
  const data = { password };
  return client({ request, data });
};

/**
 * Firma un documento
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @param   {string}  arg.password
 * @returns {Promise}
 */
const sealDocument = ({ _id, password }) => {
  const request = { url: `/docs/file/${_id}/companySign`, method: 'POST' };
  const data = { password };
  return client({ request, data });
};

/**
 * Sube un documento
 * @param   {Object}     arg
 * @param   {File}       arg.file
 * @param   {...Object}  arg.restData
 * @returns {Promise}
 */
const uploadDocument = ({ file, ...restData }) => {
  const request = { url: '/docs/upload', method: 'POST' };
  const values = {
    file,
    ...restData,
  };
  const format = 'formData';
  const data = parseRequestData({ values, format });
  return client({ request, data, format });
};

/**
 * Elimina un documento
 * @param   {Object}  arg
 * @param   {string}  arg._id
 * @returns {Promise}
 */
const removeDocument = ({ _id }) => {
  const request = { url: '/docs/remove', method: 'POST' };
  const data = { file: _id };
  return client({ request, data });
};

export {
  STATES_ALLOWED_FOR_PROCESS_CHUNKS,
  fetchTypes,
  fetchTypesMine,
  fetchPublicTypes,
  fetchType,
  fetchDocumentsByIds,
  fetchDocument,
  fetchDocumentBlob,
  fetchDocumentAccesses,
  fetchDocumentsAsAdmin,
  fetchDocumentsFilter,
  fetchDocumentsPendingToSign,
  fetchDocumentAnalize,
  getDocumentUrl,
  getDocumentLink,
  getDocumentBlobLink,
  getDocumentUrlForDocview,
  shouldUserSignDocument,
  shouldUserSealDocument,
  filterDocumentTypesBySearchText,
  filterDocumentsBySearchText,
  filterDocumentAccessesBySearchText,
  filterDocumentsReceiptsBySearchText,
  formForDocumentTypes,
  formForDocumentsFilter,
  formForDocumentAdminUpload,
  formForDocumentAdminConfig,
  formForDocumentAdminAdvancedConfig,
  formForDocumentAdminUpdate,
  formForDocumentAdminAdvancedUpdate,
  formForDocumentSign,
  formForDocumentSeal,
  createDocumentType,
  updateDocumentType,
  removeDocumentType,
  uploadDocumentAdmin,
  uploadDocumentAdminAdvanced,
  setPersonaForDocumentChunkAdmin,
  processDocumentAdmin,
  updateDocumentAdmin,
  removeDocumentAdmin,
  updateDocumentHighlight,
  signDocuments,
  signDocument,
  sealDocument,
  uploadDocument,
  removeDocument,
};
