import styled from 'styled-components';

const FormHint = styled.div`
  display: block;
  margin: -2px 0 8px 0;
  font-size: ${props => props.theme.fontSizes.xs};
  line-height: 1.2;
  color: ${props => props.theme.colors.black}90;
`;

export default FormHint;
