import React from 'react';
import PropTypes from 'prop-types';
import ReactSelect from 'react-select';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import Tippy from '@tippyjs/react';
import IconLoader from 'components/IconLoader';
import {
  getBorderColor,
  getBorderColorOnFocus,
  getBoxShadowColorOnFocus,
} from '../utils/fieldsStyle';

// @TODO: Preparar onFocus para cuando existan errores, ver: https://codesandbox.io/s/react-hook-form-controllerautofocus-v6-eeo66?file=/src/index.js:1163-1171

const ReactSelectStyled = styled(ReactSelect)`
  .ReactSelect__control {
    min-height: 40px;
    border-color: ${props => getBorderColor(props)};

    &:hover {
      border-color: ${props => getBorderColor(props)};
    }

    &--is-focused,
    &--is-focused:hover {
      border-color: ${props => getBorderColorOnFocus(props)};
      box-shadow: ${props => getBoxShadowColorOnFocus(props)} 0px 0px 0px 3px;
    }
  }

  .ReactSelect__value-container {
    align-self: stretch;
    padding: 2px 16px;
    font-size: 1.125rem;
    line-height: 1.2;
  }
`;

const InfoText = styled.div`
  display: flex;
  margin-top: 8px;
  margin-bottom: 0;
  color: ${props => props.theme.colors.info};
`;

const InfoIcon = styled.span`
  display: flex;
  margin-right: 4px;
  margin-top: 3px;
  color: ${props => props.theme.colors.info};
`;

const SelectSingle = ({ value, onChange, ...componentProps }) => {
  const { options } = componentProps;

  const optionsByValue = options ? keyBy(options, 'value') : {};
  const valueAsObject = optionsByValue[value];

  return (
    <ReactSelectStyled
      {...componentProps}
      value={valueAsObject}
      onChange={option => {
        const valueAsString = option ? option.value : option;
        onChange(valueAsString);
      }}
    />
  );
};

const SelectMulti = ({ value, onChange, ...componentProps }) => {
  const { options } = componentProps;

  const optionsByValue = options ? keyBy(options, 'value') : {};
  const valueAsCollection = value && value?.map(itemValue => optionsByValue[itemValue]);

  return (
    <ReactSelectStyled
      {...componentProps}
      isMulti
      value={valueAsCollection}
      onChange={options => {
        const valueAsArray = options ? options.map(option => option.value) : options;
        onChange(valueAsArray);
      }}
    />
  );
};

const SelectInfo = ({ children }) => {
  return (
    <InfoText>
      <Tippy content="Descripción">
        <InfoIcon>
          <IconLoader name="interface-information-icon.svg" />
        </InfoIcon>
      </Tippy>
      {children}
    </InfoText>
  );
};

const Select = props => {
  const { control, rules, defaultValue, ...componentProps } = props;
  const { id, name, isMulti, infoField, values } = componentProps;

  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      defaultValue={defaultValue}
      render={({ field: { name, value, onChange, onBlur } }) => {
        const SelectComponent = isMulti ? SelectMulti : SelectSingle;

        const currentValue = values?.find(v => v.value === value);
        const info = infoField && get(currentValue, infoField);
        const hasInfo = Boolean(info);

        return (
          <>
            <SelectComponent
              {...componentProps}
              classNamePrefix="ReactSelect"
              name={name}
              inputId={id}
              value={value}
              onChange={onChange}
              onBlur={onBlur}
            />
            {hasInfo && <SelectInfo>{info}</SelectInfo>}
          </>
        );
      }}
    />
  );
};

Select.propTypes = {
  placeholder: PropTypes.string,
  isClearable: PropTypes.bool,
  noOptionsMessage: PropTypes.func,
};

Select.defaultProps = {
  placeholder: 'Seleccione una opción',
  isClearable: true,
  noOptionsMessage: () => 'No hay opciones',
};

export default Select;

export { Select, SelectSingle, SelectMulti };
