import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Portal from '../Portal';
import useLockScroll from '../../hooks/useLockScroll';

/**
 * 1. Para que se posicione correctamente relativo al container y ocupe todo el alto
 */
const Overlay = styled.div`
  position: sticky;
  display: flex; /* 1 */
  min-height: 100%; /* 1 */
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
`;

const PortalOverlay = props => {
  const { children, container } = props;
  useLockScroll(container);

  return (
    <Portal container={container}>
      <Overlay>{children}</Overlay>
    </Portal>
  );
};

PortalOverlay.propTypes = {
  container: PropTypes.instanceOf(Element).isRequired,
};

export default PortalOverlay;
