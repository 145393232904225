const getBorderColor = props => {
  const { theme, isSuccess, hasError } = props;
  if (isSuccess) return theme.colors.success;
  if (hasError) return theme.colors.error;
  return '#808080';
};

const getBorderColorOnFocus = props => {
  const { theme, hasError } = props;
  if (hasError) return theme.colors.error;
  return theme.colors.secondary;
};

const getBoxShadowColorOnFocus = props => {
  const { theme, hasError } = props;
  if (hasError) return `${theme.colors.error}33`;
  return `${theme.colors.secondary}33`;
};

export { getBorderColor, getBorderColorOnFocus, getBoxShadowColorOnFocus };
