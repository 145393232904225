import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { useFieldArray } from 'react-hook-form';
import styled, { css } from 'styled-components';
import chroma from 'chroma-js';
import { Button } from '@rmwc/button';
import IconLoader from '../IconLoader';
import { ButtonRaw } from '../Button';
import getDefaultValuesFromFields from './utils/getDefaultValuesFromFields';
import FormFieldset from './FormFieldset';
import FormControl from './FormControl';

// @TODO:
// Ahora mismo en los FieldArray no se aplica ninguna regla, porque no existe el campo como tal,
// es decir, no existe un campo que tenga el name del multi a secas ya que es un conjunto de campos,
// existirán fieldsets como name[0], name[1] etc.
// Aplicar validación cuando el campo es requerido...
// Quizás lo más sencillo sea que si el campo es requerido se muestre un fieldset y que además no pueda eliminarse
// ver: https://github.com/react-hook-form/react-hook-form/issues/1617

const Content = styled.div`
  display: flex;
`;

const Actions = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  padding-right: 16px;
  min-width: 40px;
  border-right: 1px solid;
  border-color: ${props => chroma(props.theme.colors.error).alpha(0.3)};
`;

const Fields = styled.div`
  flex: 1;
  display: grid;
  ${props => props.styles}
`;

const RemoveButton = styled(ButtonRaw)`
  display: flex;
  font-size: 24px;
  color: ${props => props.theme.colors.error};

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

const CreateButton = styled(Button)`
  margin-bottom: 8px;
  .mdc-button__label {
    display: flex;
    align-items: center;
  }
`;

const CreateButtonIcon = styled.div`
  display: flex;
  margin-right: 4px;
  font-size: 18px;
`;

const FormFieldArray = props => {
  const { register, control, errors, ...componentProps } = props;
  const {
    name,
    styles,
    fields,
    required,
    isAppendVisible,
    appendText,
    appendIcon,
    isRemoveVisible,
    removeIcon,
  } = componentProps;

  const { fields: fieldsets, append, remove } = useFieldArray({ control, name });

  const addItem = useCallback(() => {
    const defaultValues = getDefaultValuesFromFields({ fields });
    append({ ...defaultValues }, { shouldFocus: false });
  }, [append, fields]);

  const removeItem = useCallback(index => remove(index), [remove]);

  return (
    <>
      {fieldsets.map((current, index) => {
        const isFirstFieldset = index === 0;
        const fieldsetName = `${name}.${index}`;
        const isRemoveDisabled = isFirstFieldset && required;

        return (
          <FormFieldset key={current.id} name={fieldsetName}>
            <Content>
              {isRemoveVisible && (
                <Actions>
                  <RemoveButton disabled={isRemoveDisabled} onClick={() => removeItem(index)}>
                    <IconLoader name={removeIcon} />
                  </RemoveButton>
                </Actions>
              )}
              <Fields styles={styles}>
                {fields.map(field => (
                  <FormControl
                    key={field.name || field.label}
                    field={{ ...field, name: `${fieldsetName}.${field.name}` }}
                    defaultValue={get(current, field.name)}
                    register={register}
                    control={control}
                    errors={errors}
                  />
                ))}
              </Fields>
            </Content>
          </FormFieldset>
        );
      })}

      {isAppendVisible && (
        <CreateButton type="button" unelevated onClick={addItem}>
          <CreateButtonIcon>
            <IconLoader name={appendIcon} />
          </CreateButtonIcon>
          <span>{appendText}</span>
        </CreateButton>
      )}
    </>
  );
};

FormFieldArray.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({ name: PropTypes.string })),
  isAppendVisible: PropTypes.bool,
  appendText: PropTypes.string,
  appendIcon: PropTypes.string,
  isRemoveVisible: PropTypes.bool,
  removeIcon: PropTypes.string,
  register: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object,
};

FormFieldArray.defaultProps = {
  isAppendVisible: true,
  appendText: 'Añadir',
  appendIcon: 'add-2-icon.svg',
  isRemoveVisible: true,
  removeIcon: 'bin-icon.svg',
};

export default FormFieldArray;
