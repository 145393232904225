import React from 'react';
import PropTypes from 'prop-types';
import { originUrl, baseUrl } from 'config';
import MessageBox from 'components/MessageBox';

const ChatMessage = props => {
  const {
    data,
    dateTimeFormat,
    dateTimeFromNow,
    showDateTimeOnSystemMessages,
    isMine,
    onMessageDoubleClick,
    onAnswer,
    onFileClick,
  } = props;

  return (
    <MessageBox
      originUrl={originUrl}
      baseUrl={baseUrl}
      data={data}
      dateTimeFormat={dateTimeFormat}
      dateTimeFromNow={dateTimeFromNow}
      showDateTimeOnSystemMessages={showDateTimeOnSystemMessages}
      isMine={isMine}
      onMessageDoubleClick={onMessageDoubleClick}
      onAnswer={onAnswer}
      onFileClick={onFileClick}
    />
  );
};

ChatMessage.propTypes = {
  data: PropTypes.shape({
    _id: PropTypes.string,
    message: PropTypes.string,
    dateTime: PropTypes.string,
    persona: PropTypes.shape({ id: PropTypes.string }),
  }),
  dateTimeFormat: PropTypes.string,
  dateTimeFromNow: PropTypes.bool,
  showDateTimeOnSystemMessages: PropTypes.bool,
  isMine: PropTypes.bool,
  onMessageDoubleClick: PropTypes.func,
};

ChatMessage.defaultProps = {
  dateTimeFormat: 'DD/MM/YYYY HH:mm',
  dateTimeFromNow: false,
  showDateTimeOnSystemMessages: false,
};

export default ChatMessage;
