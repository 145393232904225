import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClientProvider } from 'react-query';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import queryClient from 'clients/query';
import sanitize from 'utils/sanitize';
import theme from 'utils/theme';
import { UserProvider } from './context/UserContext';

const GlobalStyle = createGlobalStyle`
  /**
   * Use sanitize style
   */

  ${sanitize}

  /**
   * Define CSS Variables using theme properties
   */

  :root {
    --mdc-theme-primary: ${props => props.theme.colors.primary};
    --mdc-theme-secondary: ${props => props.theme.colors.secondary};
    --mdc-theme-error: ${props => props.theme.colors.error}
  }

  /**
   * 1. Fix for 100vh in mobile WebKit: https://css-tricks.com/css-fix-for-100vh-in-mobile-webkit/
   * 2. Improve scroll behavior
   */

  html {
    height: -webkit-fill-available; /* 1 */
    scroll-behavior: smooth; /* 2 */
  }

  body {
    min-height: 100vh; /* 1 */
    min-height: -webkit-fill-available; /* 1 */
    font-family: ${theme.fonts.body}
  }

  a {
    text-decoration: none;
    color: ${theme.colors.info};
  }

  button {
    font-family: ${theme.fonts.body};
  }
`;

const AppProviders = ({ children }) => {
  return (
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <UserProvider>{children}</UserProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </BrowserRouter>
  );
};

export default AppProviders;
