import React, { useRef, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Picker from 'emoji-picker-react';
import Tippy from '@tippyjs/react';
import debounce from 'lodash/debounce';
import IconLoader from 'components/IconLoader';
import { ButtonRaw } from 'components/Button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const AttachButton = styled(ButtonRaw)`
  flex: none;
  display: flex;
  align-self: flex-end;
  margin-right: 8px;
  font-size: 24px;
`;

const EmojiButton = styled(ButtonRaw)`
  flex: none;
  display: flex;
  align-self: flex-end;
  margin-left: 8px;
  font-size: 24px;
`;

const SubmitButton = styled(ButtonRaw)`
  flex: none;
  display: flex;
  align-self: flex-end;
  margin-left: 8px;
  font-size: 24px;

  &:not(:disabled) {
    color: ${props => props.theme.colors.primary};
  }
`;

const Form = styled.form`
  flex: 1;
  display: flex;
`;

const Input = styled.textarea``;

/**
 * Jugando con grid y pasandole data-value al padre del textarea, conseguimos que aumente la altura por linea sin js
 * @see: https://codepen.io/shshaw/pen/bGNJJBE
 */
const InputSizer = styled.div`
  display: inline-grid;
  vertical-align: top;
  align-items: stretch;
  position: relative;
  flex: 1;

  &::after,
  textarea {
    grid-area: 2 / 1;
    max-height: 200px;
  }

  &::after,
  textarea {
    width: auto;
    min-width: 1em;
    font: inherit;
    font-size: 18px;
    padding: 0;
    margin: 0;
    resize: none;
    background: none;
    appearance: none;
    border: none;
    outline: none;
  }

  &::after {
    content: attr(data-value) ' ';
    visibility: hidden;
    white-space: pre-wrap;
  }
`;

const ChatInput = props => {
  const {
    defaultValue = '',
    placeholder,
    autoFocus,
    onAttachFile,
    onSendMessage,
    onStartWriting,
  } = props;
  const [message, setMessage] = useState(defaultValue);
  const fileInputRef = useRef();
  const inputSizerRef = useRef();

  const safeMessage = message?.trim();
  const hasMessage = Boolean(safeMessage);

  const handleStartWriting = useMemo(
    () => onStartWriting && debounce(onStartWriting, 3000, { leading: true, trailing: false }),
    [onStartWriting],
  );

  useEffect(() => {
    if (defaultValue) {
      inputSizerRef.current.dataset.value = defaultValue;
    }
  }, [defaultValue]);

  useEffect(() => {
    return () => {
      if (handleStartWriting) {
        handleStartWriting.cancel();
      }
    };
  }, [handleStartWriting]);

  const handleAttachFile = () => {
    fileInputRef.current.value = '';
    fileInputRef.current.click();
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (!hasMessage) return null;

    inputSizerRef.current.dataset.value = '';
    onSendMessage(safeMessage);
    setMessage('');
  };

  const handleKeyPress = event => {
    if (event.which === 13 && !event.shiftKey) {
      handleSubmit(event);
    }
  };

  const handleInput = event => {
    const value = event.currentTarget.value;

    inputSizerRef.current.dataset.value = value;
    setMessage(value);

    if (value && handleStartWriting) {
      handleStartWriting();
    }
  };

  const handleFocus = event => {
    const value = event.target.value;
    event.target.value = '';
    event.target.value = value;
  };

  //const handleFocus = event => event.currentTarget.select();

  const onEmojiClick = (event, emojiObject) => {
    setMessage(prev => prev + emojiObject.emoji);
  };

  return (
    <Wrapper>
      {onAttachFile && (
        <AttachButton onClick={handleAttachFile}>
          <IconLoader name="paperclip-icon.svg" />
        </AttachButton>
      )}
      <input ref={fileInputRef} type="file" hidden onChange={onAttachFile} />

      <Form onSubmit={handleSubmit}>
        <InputSizer ref={inputSizerRef}>
          <Input
            name="message"
            value={message}
            placeholder={placeholder}
            autoFocus={autoFocus}
            autoCorrect="off"
            autoComplete="off"
            onKeyPress={handleKeyPress}
            onInput={handleInput}
            onFocus={handleFocus}
            rows={1}
          />
        </InputSizer>

        <Tippy
          theme="light"
          trigger="click"
          interactive
          arrow={false}
          content={<Picker onEmojiClick={onEmojiClick} />}
        >
          <EmojiButton>
            <IconLoader name="insert-emoticon-icon.svg" />
          </EmojiButton>
        </Tippy>

        <SubmitButton type="submit" disabled={!hasMessage}>
          <IconLoader name="paperplane-icon.svg" />
        </SubmitButton>
      </Form>
    </Wrapper>
  );
};

ChatInput.propTypes = {
  autoFocus: PropTypes.bool,
  onAttachFile: PropTypes.func,
  onSendMessage: PropTypes.func,
  onStartWriting: PropTypes.func,
};

ChatInput.defaultProps = {
  placeholder: 'Escribe un mensaje...',
  autoFocus: true,
};

export default ChatInput;
