import qs from 'qs';
import {
  isBooleanLikeString,
  isMongoId,
  isNumberLikeString,
  parseFromStringToBoolean,
  parseFromStringToNumber,
} from 'utils/strings';

const goBack = () => window.history.back();

/**
 * Transforma un objeto a querystring
 * @see https://github.com/ljharb/qs
 * @see https://github.com/ljharb/qs#stringifying
 * @param {Object} arg
 * @param {Object} arg.value
 * @param {Object} arg.options
 * @returns {string}
 */
const parseFromObjectToQueryString = ({ value, options = {} }) => {
  const defaultOptions = { strictNullHandling: true };
  const mergedOptions = { ...defaultOptions, ...options };
  return qs.stringify(value, mergedOptions);
};

/**
 * Decodifica una querystring interpretando los valores como boolean o number
 * @see https://github.com/ljharb/qs
 * @see https://github.com/ljharb/qs#parsing-primitivescalar-values-numbers-booleans-null-etc
 * @param {string} str
 * @param {Function} defaultDecoder
 * @param {string} charset
 * @param {string} type
 */
const customQueryStringDecoder = (str, defaultDecoder, charset, type) => {
  const isTypeValue = type === 'value';

  const isBooleanValue = isTypeValue && isBooleanLikeString(str);
  if (isBooleanValue) return parseFromStringToBoolean(str);

  const isMongoIdValue = isTypeValue && isMongoId(str);

  const isNumberValue = isTypeValue && !isMongoIdValue && isNumberLikeString(str);
  if (isNumberValue) return parseFromStringToNumber(str);

  return defaultDecoder(str, defaultDecoder, charset, type);
};

/**
 * Transforma una querystring a objeto
 * @see https://github.com/ljharb/qs
 * @see https://github.com/ljharb/qs#parsing-objects
 * @param {Object} arg
 * @param {string} arg.value
 * @param {Object} arg.options
 * @return {Object}
 */
const parseFromQueryStringToObject = ({ value, options = {} }) => {
  const defaultOptions = {
    strictNullHandling: true,
    ignoreQueryPrefix: true,
    decoder: customQueryStringDecoder,
  };
  const mergedOptions = { ...defaultOptions, ...options };
  return qs.parse(value, mergedOptions);
};

export { goBack, parseFromObjectToQueryString, parseFromQueryStringToObject };
