import { useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import orderBy from 'lodash/orderBy';
import { useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { client } from 'clients/api';
import { parseRequestWithParams } from 'utils/fetch';

const parseRequestBody = body => (typeof body === 'string' ? JSON.parse(body) : body);

const sortData = ({ data, order }) => {
  return data && order ? orderBy(data, order.props, order.orders) : data;
};

const pluckData = ({ data, pluck }) => {
  return data.map(d => {
    let value = {};
    Object.keys(pluck).forEach(key => {
      value[key] = get(d, pluck[key]);
    });
    return value;
  });
};

const getDataProp = ({ data, path }) => get(data, path);

const fetchWatchData = ({ request, value }) => {
  const { body, pluck, order } = request;
  const requestParsed = { ...request, body: parseRequestBody(body) };
  const requestParsedWithParams = parseRequestWithParams({
    request: requestParsed,
    scope: { value },
  });
  return client({ request: requestParsedWithParams })
    .then(data => (order ? sortData({ data, order }) : data))
    .then(data => (pluck ? pluckData({ data, pluck }) : data))
    .then(data => (request.get ? getDataProp({ data, path: request.get }) : data));
};

const useQueryWatchData = ({ request, value }) => {
  const queryKey = ['watchFetch', { request, value }];
  const queryInfo = useQuery({
    queryKey,
    queryFn: () => fetchWatchData({ request, value }),
    enabled: Boolean(value),
  });
  return queryInfo;
};

const removeItemProp = ({ fields, name, key }) => {
  return fields.map(field => {
    if (field.name !== name) return field;
    const { [key]: propToRemove, ...restField } = field;
    return { ...restField };
  });
};

const updateItemProp = ({ fields, name, key, value }) => {
  return fields.map(field => {
    if (field.name !== name) return field;
    return { ...field, [key]: value };
  });
};

const FormFieldWatcher = props => {
  const { field, control, setValue, setFormFields } = props;
  const { name, watchField, watchFetch, watchTarget } = field;

  const watchFieldValue = useWatch({ name: watchField, control });
  const { isSuccess, data } = useQueryWatchData({ request: watchFetch, value: watchFieldValue });

  // Limpia el campo... Tanto la propiedad `watchTarget` como el `value` del campo
  const clearField = useCallback(() => {
    setFormFields(fields => removeItemProp({ fields, name, key: watchTarget }));
    setValue(name, '');
  }, [name, watchTarget, setFormFields, setValue]);

  // Actualiza el campo con el valor indicado para el `watchTarget`
  const updateField = useCallback(
    ({ value }) => {
      setFormFields(fields => updateItemProp({ fields, name, key: watchTarget, value }));
    },
    [name, watchTarget, setFormFields],
  );

  // Si se limpia el valor al que se hace watch -> limpiamos el campo
  useEffect(() => {
    if (!watchFieldValue) return clearField();
  }, [watchFieldValue, clearField]);

  // Si cambian los datos de la petición del watch...
  // Si no hay datos -> limpiamos el campo
  // En caso contrario -> actualizamos el campo
  useEffect(() => {
    if (!isSuccess) return;
    if (!data || data?.length === 0) return clearField();
    return updateField({ value: data });
  }, [isSuccess, data, clearField, updateField]);

  return null;
};

FormFieldWatcher.propTypes = {
  field: PropTypes.shape({
    name: PropTypes.string,
    watchField: PropTypes.string,
    watchFetch: PropTypes.shape({ url: PropTypes.string, params: PropTypes.object }),
    watchTarget: PropTypes.string,
  }),
  watch: PropTypes.func,
  setValue: PropTypes.func,
  setFormFields: PropTypes.func,
};

export default FormFieldWatcher;
