import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { REQUEST_STATE_LABELS, REQUEST_STATE_COLORS } from 'services/complaints';
import Card from 'components/Card';
import Badge from 'components/Badge';
import DocumentsList from 'components/DocumentsList';

const Wrapper = styled(Card)`
  padding: 16px;
  max-height: 100%;
  overflow: auto;
`;

const Header = styled.div`
  flex: none;
  display: flex;
  align-items: center;
  margin: 0 0 8px -16px;
`;

const Content = styled.div`
  margin: 0;
`;

const Title = styled.div`
  margin-bottom: 8px;
  font-size: 1.125rem;
  font-weight: ${props => props.theme.fontWeights.bold};
`;

const Description = styled.p`
  margin: 0;
  font-size: 1rem;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.light};
`;

const Section = styled.section`
  ${Content} + & {
    margin-top: 24px;
  }
  &:not(:last-child) {
    margin-bottom: 40px;
  }
`;

const CreatedAt = styled.div`
  margin-right: auto;
  margin-left: 8px;
  font-size: 14px;
`;

const IncidenceDetailCard = props => {
  const { data, onFileClick, onRemoveFile } = props;
  const { description, status, step, docs, createdAtParsed } = data;
  const state = status?.[0]?.state;
  const hasDocs = docs?.length > 0;

  return (
    <Wrapper>
      <Header>
        <Badge color={REQUEST_STATE_COLORS[state]}>
          {REQUEST_STATE_LABELS[state]} {step}
        </Badge>
        <CreatedAt>{createdAtParsed}</CreatedAt>
      </Header>
      <Content>
        <Title>Descripción</Title>
        <Description>{description}</Description>
      </Content>
      {hasDocs && (
        <Section>
          <Title>Documentos</Title>
          <DocumentsList
            data={docs}
            itemSize="80px"
            showBytes={false}
            onItemClick={onFileClick}
            onItemRemove={onRemoveFile}
          />
        </Section>
      )}
    </Wrapper>
  );
};

IncidenceDetailCard.propTypes = {
  data: PropTypes.shape({
    nameHumanized: PropTypes.string,
    description: PropTypes.string,
    state: PropTypes.string,
    docs: PropTypes.arrayOf(PropTypes.shape({ _id: PropTypes.string })),
    createdAtParsed: PropTypes.string,
  }),
  onFileClick: PropTypes.func,
  onRemoveFile: PropTypes.func,
};

export default IncidenceDetailCard;
