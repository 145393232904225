import PropTypes from 'prop-types';
import styled from 'styled-components';

const ButtonRaw = styled.button.attrs(props => ({
  type: props.type,
}))`
  display: flex;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
`;

ButtonRaw.propTypes = {
  type: PropTypes.string,
};

ButtonRaw.defaultProps = {
  type: 'button',
};

export default ButtonRaw;
