import React from 'react';
import PropTypes from 'prop-types';
import IllustrationLoader from '../IllustrationLoader';
import Dialog from './Dialog';

const DialogContainer = props => {
  const {
    theme = 'dark',
    icon,
    color,
    style,
    contentStyle,
    buttonsStyle,
    fullScreen,
    onClickOutside,
    children,
    buttons,
    accept,
    cancel,
    type,
  } = props;

  const styles = {
    wrapper: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: theme === 'light' ? 'rgba(255, 255, 255, .7)' : 'rgba(0, 0, 0, .2)',
      zIndex: 1000,
      padding: 10,
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      ...style,
    },
    content: {
      background: '#fff',
      boxShadow: '0px 0px 15px 5px rgba(0, 0, 0, 0.05)',
      borderRadius: '16px',
      boxSizing: 'border-box',
      minHeight: 95,
      minWidth: '50%',
      width: fullScreen && '100%',
      height: fullScreen && '100%',
      display: 'flex',
      flexDirection: 'column',
      ...contentStyle,
    },
    buttons: {
      margin: 16,
      display: 'flex',
      justifyContent: 'center',
      color: 'black',
      height: 36,
      ...buttonsStyle,
    },
    button: {
      margin: '0px 16px',
      background: '#FFF',
      padding: '0 8px',
      cursor: 'pointer',
      textAlign: 'center',
      minWidth: 64,
      height: 36,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textTransform: 'uppercase',
      fontSize: '1.25rem',
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '48px 0',
      height: 256,
    },
  };

  const getColorByType = type => {
    const types = {
      error: '#f44336',
      warning: '#ffc24d',
      success: '#1ef59f',
      default: '',
    };

    return types[type] || types['default'];
  };

  const getIconByType = type => {
    const types = {
      error: 'undraw_cancel.svg',
      warning: 'undraw_warning.svg',
      success: 'undraw_confirmation.svg',
      default: null,
    };

    return types[type] || types['default'];
  };

  const handleOutsideClick = () => {
    if (onClickOutside) {
      onClickOutside();
    }
  };

  const handleContentClick = e => {
    e.stopPropagation();
  };

  const renderContent = () => {
    const headerColor = type ? getColorByType(type) : color;

    return children ? children({ ...props }) : <Dialog headerColor={headerColor} {...props} />;
  };

  const renderButtons = () => {
    if (buttons) {
      return (
        <div style={styles.buttons}>
          {buttons.map(button => (
            <div
              key={button.text}
              style={Object.assign({ color: button.color }, styles.button)}
              onClick={button.onClick}
            >
              {button.text}
            </div>
          ))}
        </div>
      );
    }

    if (accept || cancel) {
      return (
        <div style={styles.buttons}>
          {cancel ? (
            <div
              style={Object.assign({ color: cancel.color }, styles.button)}
              onClick={cancel.onClick}
            >
              {cancel.text}
            </div>
          ) : null}
          {accept ? (
            <div
              style={Object.assign({ color: accept.color }, styles.button)}
              onClick={accept.onClick}
            >
              {accept.text}
            </div>
          ) : null}
        </div>
      );
    }

    return null;
  };

  const renderHeader = () => {
    const headerIcon = type ? getIconByType(type) : icon;

    if (!headerIcon) return null;

    return (
      <div style={styles.header}>
        <IllustrationLoader name={headerIcon} />
      </div>
    );
  };

  return (
    <div style={styles.wrapper} onClick={handleOutsideClick}>
      <div style={styles.content} onClick={handleContentClick}>
        {renderHeader()}
        {renderContent()}
        {renderButtons()}
      </div>
    </div>
  );
};

DialogContainer.propTypes = {
  type: PropTypes.oneOf(['error', 'warning', 'success', 'info']),
  theme: PropTypes.oneOf(['light', 'dark']),
  fullScreen: PropTypes.bool,
  children: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.func,
  accept: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  }),
  cancel: PropTypes.shape({
    text: PropTypes.string,
    color: PropTypes.string,
    onClick: PropTypes.func,
  }),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      color: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
  iconColor: PropTypes.string,
  onClickOutside: PropTypes.func,
};

export default DialogContainer;
