import React from 'react';
import PropTypes from 'prop-types';

// @see https://stackoverflow.com/questions/61339259/how-to-dynamically-import-svg-and-render-it-inline
// @see https://github.com/facebook/create-react-app/issues/5276

const IllustrationLoader = ({ family, name, ...rest }) => {
  const ImportedIconRef = React.useRef(null);
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    setLoading(true);
    const importIcon = async () => {
      try {
        // ImportedIconRef.current = (
        //   await import(`../../assets/illustrations/${family}/${name}`)
        // ).ReactComponent;
        ImportedIconRef.current = (
          await import(
            `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/illustrations/${family}/${name}`
          )
        ).default;
      } catch (err) {
        // Your own error handling logic, throwing error for the sake of
        // simplicity
        throw err;
      } finally {
        setLoading(false);
      }
    };
    importIcon();
  }, [family, name]);

  if (!loading && ImportedIconRef.current) {
    const { current: ImportedIcon } = ImportedIconRef;
    return <ImportedIcon {...rest} />;
  }

  return null;
};

IllustrationLoader.propTypes = {
  name: PropTypes.string.isRequired,
  family: PropTypes.string,
};

IllustrationLoader.defaultProps = {
  family: 'undraw',
  width: '100%',
  height: '100%',
};

export default IllustrationLoader;
