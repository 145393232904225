import { useState, useEffect } from 'react';

const useScrollPosition = ({ elementSelector = '#content' } = {}) => {
  const [scrollY, setScrollY] = useState(0);

  const handleScroll = () => setScrollY(document.querySelector(elementSelector).scrollTop);

  useEffect(() => {
    const watchScroll = () => {
      document.querySelector(elementSelector).addEventListener('scroll', handleScroll);
    };
    watchScroll();
    return () => {
      document.querySelector(elementSelector)?.removeEventListener('scroll', handleScroll);
    };
  });

  return { y: scrollY };
};

export default useScrollPosition;
