import { useMutation, useQueryClient } from 'react-query';
import { uploadComplaintFile } from 'services/complaints';

const useMutationUploadComplaintFile = () => {
  const queryClient = useQueryClient();
  const mutationInfo = useMutation(uploadComplaintFile, {
    onSuccess: () => {
      queryClient.invalidateQueries('complaint');
    },
  });
  return mutationInfo;
};

export default useMutationUploadComplaintFile;
