import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  border-left: 2px solid;
  color: currentColor;
`;

const DocumentWrapper = styled.div`
  flex: none;
  width: 30px;
  height: 30px;
  margin-left: 10px;
  border-radius: 4px;
  overflow: hidden;
`;

const Image = styled.div`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 10px;
`;

const User = styled.div`
  font-size: 12px;
  flex: 1;
  font-weight: 500;
  display: flex;
  align-items: center;
`;

const Message = styled.div`
  font-size: 10px;
  flex: 1;
  display: flex;
  align-items: center;
`;

const PersonaMessageReplyBox = props => {
  const { data } = props;

  const renderImage = ({ url, originalname }) => {
    return (
      <DocumentWrapper>
        <Image src={url} alt={originalname} />
      </DocumentWrapper>
    );
  };

  const renderDoc = doc => {
    const { contentType } = doc;

    if (contentType.includes('image')) {
      return renderImage(doc);
    }

    return null;
  };

  const renderMessage = () => {
    if (data.message) {
      return <Message>{data.message}</Message>;
    }

    return <Message>1 documento</Message>;
  };

  return (
    <Wrapper>
      {data.doc && renderDoc(data.doc)}
      <Content>
        <User>{data.sender}</User>
        <Message>{renderMessage()}</Message>
      </Content>
    </Wrapper>
  );
};

PersonaMessageReplyBox.propTypes = {};

PersonaMessageReplyBox.defaultProps = {};

export default PersonaMessageReplyBox;
