import PropTypes from 'prop-types';
import Tippy from '@tippyjs/react';
import moment from 'moment';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: ${props => props.theme.colors.cadetBlue};
  padding: 5px;
  font-size: ${props => props.theme.fontSizes.sm};
`;

const DateTimeValue = styled.span`
  cursor: default;
`;

const DateTime = props => {
  const { dateTime, dateTimeFormat, dateTimeFromNow } = props;
  const dateTimeFormatted = moment(dateTime).format(dateTimeFormat);

  if (!dateTimeFromNow) return <DateTimeValue>{dateTimeFormatted}</DateTimeValue>;

  return (
    <Tippy content={dateTimeFormatted}>
      <DateTimeValue>{moment(dateTime).fromNow()}</DateTimeValue>
    </Tippy>
  );
};

const SystemMessageBox = props => {
  const { data, showDateTime, dateTimeFormat, dateTimeFromNow } = props;
  const { message, dateTime } = data;

  return (
    <Wrapper>
      <span>
        {message}{' '}
        {showDateTime && (
          <DateTime
            dateTime={dateTime}
            dateTimeFormat={dateTimeFormat}
            dateTimeFromNow={dateTimeFromNow}
          />
        )}
      </span>
    </Wrapper>
  );
};

SystemMessageBox.propTypes = {
  data: PropTypes.shape({
    message: PropTypes.string,
    dateTime: PropTypes.string,
  }),
  showDateTime: PropTypes.bool,
};

export default SystemMessageBox;
