import React from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import IconLoader from 'components/IconLoader';

const Wrapper = styled.div`
  padding: 12px;
  border-radius: 4px;
  background: ${props => props.theme.colors.lavender}40;
  min-width: 200px;
`;

const Nav = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Title = styled.h3`
  margin: 0 0 8px 0;
  font-size: 14px;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.trout};
  text-transform: uppercase;
  padding-left: 8px;
`;

const LinkIcon = styled.div`
  display: flex;
  position: relative;
  margin-right: 8px;
  font-size: 16px;
  color: ${props => props.theme.colors.trout};

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }
`;

const MenuItem = styled.div`
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 1.2;
  font-weight: ${props => props.theme.fontWeights.medium};
  color: ${props => props.theme.colors.cadetBlue};
  text-transform: uppercase;
  border-radius: 4px;

  .isActive & {
    background: ${props => props.theme.colors.lavender};
    color: ${props => props.theme.colors.trout};
  }

  &:hover {
    color: ${props => props.theme.colors.secondary};
  }

  &:hover ${LinkIcon} {
    color: ${props => props.theme.colors.secondary};
  }
`;

const BubbleCount = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  min-width: 17px;
  padding: 2px 4px;
  font-size: 11px;
  line-height: 1;
  color: ${props => props.theme.colors.white};
  background: ${props => props.theme.colors.error};
  border: 1px solid ${props => props.theme.colors.white};
  border-radius: 999px;
  transform: translate(50%, -50%);
`;

const Menu = props => {
  const { title, links } = props;

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Nav>
        {links.map(link => (
          <NavLink
            key={link.to}
            to={link.to}
            end
            className={({ isActive }) => (isActive ? 'isActive' : '')}
          >
            <MenuItem>
              <LinkIcon>
                <IconLoader name={link.icon} />
                {link.count ? <BubbleCount>{link.count}</BubbleCount> : null}
              </LinkIcon>
              <span>{link.name}</span>
            </MenuItem>
          </NavLink>
        ))}
      </Nav>
    </Wrapper>
  );
};

Menu.propTypes = {
  title: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      to: PropTypes.string,
      name: PropTypes.string,
      icon: PropTypes.string,
    }),
  ),
};

Menu.defaultProps = {
  title: 'Menú',
};

export default Menu;
