import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

/**
 * @IMPORTANT Evitar usar este componente en la medida de lo posible
 * @see https://reactrouter.com/en/main/upgrading/reach#redirect-redirectto-isredirect
 */
const Redirect = props => {
  const { to, options } = props;
  const navigate = useNavigate();

  useEffect(() => {
    navigate(to, options);
  });

  return null;
};

Redirect.propTypes = {
  to: PropTypes.string,
  options: PropTypes.object,
};

Redirect.defaultProps = {
  options: { replace: true },
};

export default Redirect;
